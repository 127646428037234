import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, CartItemService, SharedService, ValidatorService} from '../../shared-module/services';
import {Router} from '@angular/router';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    verified = true;
    private redirectUrl: string;
    errMessage: string;
    passwordShow = false;

    constructor(private fb: FormBuilder,
                private authService: AuthService,
                private router: Router,
                private cartItemService: CartItemService,
                private validatorService: ValidatorService,
                private sharedService: SharedService,
                private title: Title) {
        this.createFormGroup();
    }

    ngOnInit() {
        this.title.setTitle('Login')
        this.redirectUrl = this.sharedService.getRedirectUrl();
    }

    login(): void {
        if (!this.loginForm.valid) {
            return;
        }

        const data: any = this.loginForm.getRawValue();

        this.errMessage = '';

        this.authService
            .login(data)
            .subscribe(({token}) => {
                this.sharedService.setToken(token);
                this.cartItemService.saveLocalCartItems().then(() => {
                    this.sharedService.removeRedirectUrl();
                    this.router.navigate([this.redirectUrl || '/home'])
                        .then(() => window.location.reload());
                });
            }, (err) => {
                this.errMessage = err.error.message;
                if (err.error.reason === 'verification_error') {
                    this.verified = true;
                }
                console.log(err);
            });
    }

    private createFormGroup(): void {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, this.validatorService.email]],
            password: ['', [Validators.required]],
            rememberMe: [false]
        });
    }

    resendVerification() {
        if (!this.loginForm.get('email').value) {
            return;
        }
        this.authService
            .resendVerifyEmail(this.loginForm.get('email').value)
            .subscribe(() => {
            });
    }

}
