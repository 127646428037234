import { Component, OnInit } from '@angular/core';
import {IGiftCard} from '../../../shared-module/interfaces';
import {GiftCardService} from '../../../shared-module/services/gift-card.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-used-gift-cards',
  templateUrl: './used-gift-cards.component.html',
  styleUrls: ['./used-gift-cards.component.scss']
})
export class UsedGiftCardsComponent implements OnInit {
    giftCards: IGiftCard[] = [];
    count = 0;
    params: any = {};

    constructor(private giftCardService: GiftCardService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        this.route.queryParams.pipe(
            tap(params => this.params = {...params}),
            switchMap(() => this.giftCardService.getUsedGiftCards(this.params))
        ).subscribe(({results, count}) => {
            this.giftCards = results;
            this.count = count;
        });
    }

    redeemGiftCard(code: string) {
        this.giftCardService
            .redeemGiftCard(code)
            .subscribe(() => {
                this.navigate();
            });
    }

    navigate() {
        this.params.updated = new Date().getTime();
        this.router.navigate([this.router.url.split('?')[0]], {queryParams: this.params}).then(() => {
        });
    }
}
