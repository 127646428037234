import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IStyle} from '../interfaces';
import {StyleService} from '../services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StylesResolverService implements Resolve<IStyle[]> {

    constructor(private styleService: StyleService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IStyle[]> {
        const categoryId = route.queryParams.categoryId;
        const params: any = {};

        if (categoryId) {
            params.categoryId = categoryId;
        }
        return this.styleService.getStyles(params).pipe(map(data => data.results));
    }
}
