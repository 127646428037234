import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {IArtist, IArtwork} from '../interfaces';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

    constructor(private apiService: ApiService) {
    }

    search(params: any): Observable<{ artists: IArtist[], artworks: IArtwork[] }> {
        return this.apiService.get<{ artists: IArtist[], artworks: IArtwork[] }>('search/receive/all', {params}, true).pipe(
            map((data) => {
                data.artists = data.artists.map((artist) => {
                    artist.specializations = artist.artistSpecializations.map(item => item.specialization);
                    delete artist.artistSpecializations;
                    return artist;
                });

                return data;
            })
        );
    }
}
