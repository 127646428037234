import {Component, OnInit} from '@angular/core';
import {IPaymentMethod} from '../../shared-module/interfaces';
import {PaymentMethodService} from '../../shared-module/services/payment-method.service';

@Component({
    selector: 'app-payment-options',
    templateUrl: './payment-options.component.html',
    styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit {
    paymentMethods: IPaymentMethod[] = [];
    addNewPaymentMethod = false;

    constructor(private paymentMethodService: PaymentMethodService) {
    }

    ngOnInit() {
        this.getPaymentMethods();
    }

    private getPaymentMethods() {
        this.paymentMethodService
            .getPaymentMethods()
            .subscribe(({results}) => {
                this.paymentMethods = results;
                if (!this.paymentMethods.length) {
                    this.addNewPaymentMethod = true;
                }
            });
    }

    addPaymentMethod(): void {
        this.addNewPaymentMethod = true;
        document.getElementById('save-payment-form').scrollIntoView();
    }

    paymentMethodChanged() {
        this.getPaymentMethods();
        this.addNewPaymentMethod = false;
    }

    updatePaymentMethod(paymentMethodId: string) {
        this.paymentMethodService
            .updatePaymentMethod(paymentMethodId, true)
            .subscribe(() => {
                this.getPaymentMethods();
            });
    }

    deletePaymentMethod(paymentMethodId: string): void {
        this.paymentMethodService
            .deletePaymentMethod(paymentMethodId)
            .subscribe(() => {
                this.getPaymentMethods();
            });
    }

}
