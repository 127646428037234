import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared-module/shared.module';
import {ArtCenterComponent} from './art-center.component';
import {ArtCenterRoutingModule} from  './art-center-routing.module';
import { ArtCenterItemComponent } from './art-center-item/art-center-item.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';

@NgModule({
    declarations: [
        ArtCenterComponent,
        ArtCenterItemComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ArtCenterRoutingModule,
        IvyCarouselModule,
    ]
})
export class ArtCenterModule {
}
