import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import {SharedModule} from '../shared-module/shared.module';
import {LoaderComponent} from './loader-component/loader.component';
import {LoaderService} from './loader.service';


@NgModule({
    declarations: [
        LoaderComponent,
    ],
    imports: [
        CommonModule,
        OverlayModule,
        SharedModule
    ],
    exports: [],
    entryComponents: [
        LoaderComponent,
    ]
})
export class LoaderModule {
    static forRoot(): ModuleWithProviders<LoaderModule> {
        return {
            ngModule: LoaderModule,
            providers: [
                LoaderService,
            ]
        };
    }

    static forChild(): ModuleWithProviders<LoaderModule> {
        return {
            ngModule: LoaderModule
        };
    }
}
