import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-footer-second',
    templateUrl: './footer-second.component.html',
    styleUrls: ['./footer-second.component.scss']
})
export class FooterSecondComponent implements OnInit {
    @Input() showLines: boolean;
    @Input() showBg: boolean;
    @Input() showButton: boolean;

    constructor() {
    }

    get currentYear() {
        return new Date().getFullYear();
    }

    ngOnInit() {
    }
}
