import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilteredLinkService} from '../../services/filtered-link-banner.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-filtered-link-banner',
    templateUrl: './filtered-link-banner.component.html',
    styleUrls: ['./filtered-link-banner.component.scss']
})
export class FilteredLinkBannerComponent implements OnInit, OnDestroy {
    filteredLinks: any = [];

    constructor(private readonly filteredLinkService: FilteredLinkService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.getFilters();
    }

    ngOnDestroy(): void {

    }

    getFilters() {
        this.filteredLinks = [];
        this.filteredLinkService
            .getFilters()
            .subscribe((results) => {
                this.filteredLinks = results.filters;
        }); 
    }

    filterData(event){
        const params = { filterParam: event, offset: 0, limit: 20 }
        this.router.navigate(['/filtered-link'], {queryParams: params});
    }
}
