import {Component, OnInit} from '@angular/core';

import {TeamMemberService} from '../../shared-module/services';
import {tap} from 'rxjs/operators';
import {FounderModel} from '../../shared-module/interfaces';

@Component({
    selector: 'app-founder-component',
    templateUrl: './founder-component.component.html',
    styleUrls: ['./founder-component.component.scss']
})
export class FounderComponentComponent implements OnInit{

    founderInfo: FounderModel;

    constructor(private teamMemberService: TeamMemberService) {
    }

    ngOnInit(): void {
        this.teamMemberService.getFounder()
            .pipe(
                tap((data) => {
                    this.founderInfo = data;
                })
            )
            .subscribe();
    }
}
