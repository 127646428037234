import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GiftCardComponent} from './gift-card.component';
import {BuyGiftCardComponent} from './buy-gift-card/buy-gift-card.component';
import {GiftCardCheckoutComponent} from './gift-card-checkout/gift-card-checkout.component';
import {BuyGiftCardCompleteComponent} from './buy-gift-card-complete/buy-gift-card-complete.component';
import {BuyGiftCardReviewComponent} from './buy-gift-card-review/buy-gift-card-review.component';
import {GiftCardUnauthorizedComponent} from './gift-card-unauthorized/gift-card-unauthorized.component';
import {AuthGuard} from '../shared-module/guards';
import {AnonymousGuard} from '../shared-module/guards/anonymous.guard';
import {GiftCardResolverService} from '../shared-module/resolvers/gift-card-resolver.service';

const routes: Routes = [
    {
        path: 'gift-card',
        component: GiftCardComponent,
        children: [
            {
                path: 'buy',
                component: BuyGiftCardComponent,
            },
            {
                path: 'unauthorized',
                component: GiftCardUnauthorizedComponent,
                canActivate: [AnonymousGuard]
            },
            {
                path: 'checkout',
                component: GiftCardCheckoutComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'checkout/:giftCardId',
                component: GiftCardCheckoutComponent,
                canActivate: [AuthGuard],
                resolve: {
                    giftCard: GiftCardResolverService
                }
            },
            {
                path: 'review/:giftCardId',
                component: BuyGiftCardReviewComponent,
                canActivate: [AuthGuard],
                resolve: {
                    giftCard: GiftCardResolverService
                }
            },
            {
                path: 'complete/:giftCardId',
                component: BuyGiftCardCompleteComponent,
                canActivate: [AuthGuard],
                resolve: {
                    giftCard: GiftCardResolverService
                }
            },
            { path: '', redirectTo: '/gift-card/buy', pathMatch: 'full'},
        ]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GiftCardRoutingModule { }
