import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {IApiResponse} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class ArtistReviewService {

    constructor(private apiService: ApiService) {
    }

    sendArtistReview(): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('artist-review/send', {}, {}, true, true);
    }
}
