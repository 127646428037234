import { Component, OnInit } from '@angular/core';
import {IOrder} from '../../shared-module/interfaces';
import {ActivatedRoute} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';
import {OrderService} from '../../shared-module/services/order.service';

@Component({
  selector: 'app-orders-history',
  templateUrl: './orders-history.component.html',
  styleUrls: ['./orders-history.component.scss']
})
export class OrdersHistoryComponent implements OnInit {
    orders: IOrder[] = [];
    count = 0;
    params: any = {};

    constructor(private orderService: OrderService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams.pipe(
            tap(params => this.params = params),
            switchMap(() =>  this.orderService.getOrders(this.params))
        ).subscribe(({results, count}) => {
            this.orders = results;
            this.count = count;
        });
    }
}
