import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IAddressBookItem, IPaymentMethod} from '../../interfaces';
import {AddressBookService, SharedService, ValidatorService} from '../../services';
import faker from 'faker';
import {PaymentMethodService} from '../../services/payment-method.service';
import {MatDialog} from '@angular/material/dialog';
import {SelectAddressBookItemComponent} from '../../dialogs/select-address-book-item/select-address-book-item.component';
import {environment} from '../../../../environments/environment';

// @ts-ignore
const stripe = window.Stripe(environment.stripePublicKey);


@Component({
    selector: 'app-payment-method-form',
    templateUrl: './payment-method-form.component.html',
    styleUrls: ['./payment-method-form.component.scss']
})
export class PaymentMethodFormComponent implements OnInit, AfterViewInit {
    @Output() changed = new EventEmitter<string>();
    addresses: IAddressBookItem[] = [];
    selectedBillingAddress: IAddressBookItem;
    paymentMethodForm: FormGroup;
    addNewBillingAddress = false;
    cardElement: any;

    constructor(private fb: FormBuilder,
                private validatorService: ValidatorService,
                private addressBookService: AddressBookService,
                private dialog: MatDialog,
                private sharedService: SharedService,
                private paymentMethodService: PaymentMethodService) {
        this.createFormGroup();
    }

    ngOnInit() {
        this.getAddresses();
        if(!environment.production) {
            this.paymentMethodForm.patchValue({
                cardHolderName: `${faker.name.firstName().toUpperCase()} ${faker.name.lastName().toUpperCase()}`,
                firstName: faker.name.firstName(),
                lastName: faker.name.lastName(),
                phone: faker.phone.phoneNumber()
                    .replace(/-/g, '')
                    .replace(/ /g, '')
                    .replace(/\(/g, '')
                    .replace(/\)/g, '')
                    .replace(/x/g, ''),
                address: faker.address.streetAddress(),
                city: faker.address.city(),
                state: faker.address.state(),
                country: faker.address.country(),
                postalCode: faker.address.zipCode()
            });
        }
    }

    ngAfterViewInit(): void {
        const elements = stripe.elements();
        this.cardElement = elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    backgroundColor: '#fff',
                    color: '#000',
                    fontFamily: 'Lato, san-serif',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textTransform: 'uppercase'
                }
            }
        });
        this.cardElement.mount('#card-element');
        this.cardElement.on('change', (event) => {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else if(!event.complete) {
                displayError.textContent = 'Card information is required';
            } else {
                displayError.textContent = '';
            }
        });
    }

    private getAddresses(): void {
        this.addressBookService
            .getAddresses()
            .subscribe(({results}) => {
                this.addresses = results;
                if (this.addresses.length) {
                    const defaultAddress = this.addresses.find(item => item.defaultAddress);

                    if (defaultAddress) {
                        this.selectedBillingAddress = defaultAddress;
                    } else {
                        this.addNewBillingAddress = true;
                    }
                } else {
                    this.addNewBillingAddress = true;
                }
            });
    }

    addPaymentMethod() {
        if (this.paymentMethodForm.invalid || !this.cardElement._complete) {
            return;
        }

        const paymentMethod: IPaymentMethod = {
            cardHolderName: this.paymentMethodForm.get('cardHolderName').value,
            defaultPaymentMethod: this.paymentMethodForm.get('defaultPaymentMethod').value,
            visiblePaymentMethod: this.paymentMethodForm.get('visiblePaymentMethod').value
        };
        const addressBookItem: IAddressBookItem = {
            firstName: this.paymentMethodForm.get('firstName').value,
            lastName: this.paymentMethodForm.get('lastName').value,
            phone: this.paymentMethodForm.get('phone').value,
            secondPhone: this.paymentMethodForm.get('secondPhone').value,
            address: this.paymentMethodForm.get('address').value,
            secondAddress: this.paymentMethodForm.get('secondAddress').value,
            city: this.paymentMethodForm.get('city').value,
            state: this.paymentMethodForm.get('state').value,
            country: this.paymentMethodForm.get('country').value,
            postalCode: this.paymentMethodForm.get('postalCode').value,
            defaultAddress: this.paymentMethodForm.get('defaultAddress').value,
            visibleAddress: this.paymentMethodForm.get('visibleAddress').value
        };


        if (this.addNewBillingAddress) {
            this.addressBookService
                .addAddressBookItem(addressBookItem)
                .subscribe(({id}) => {
                    paymentMethod.addressBookItemId = id;
                    this.addPaymentToken(paymentMethod, addressBookItem);
                });

        } else if (this.selectedBillingAddress) {
            paymentMethod.addressBookItemId = this.selectedBillingAddress.addressBookItemId;
            this.addPaymentToken(paymentMethod, this.selectedBillingAddress);
        }
    }

    addPaymentToken(paymentMethod: IPaymentMethod, addressBookItem: IAddressBookItem) {
        this.sharedService.emitLoaderChange(true);
        stripe.createToken(this.cardElement, {
            name: paymentMethod.cardHolderName,
            address_line1: addressBookItem.address,
            address_line2: addressBookItem.secondAddress || '',
            address_city: addressBookItem.city,
            address_state: addressBookItem.state,
            address_zip: addressBookItem.postalCode,
            address_country: addressBookItem.country
        }).then(({token}) => {
            this.sharedService.emitLoaderChange(false);
            paymentMethod.paymentToken = token.id;
            paymentMethod.cardId = token.card.id;
            paymentMethod.cardBrand = token.card.brand;
            paymentMethod.cardLast4 = token.card.last4;
            paymentMethod.cardExpireMonth = token.card.exp_month;
            paymentMethod.cardExpireYear = token.card.exp_year;
            paymentMethod.cardFunding = token.card.funding;
            this.savePaymentMethod(paymentMethod);
        }).catch(() => {
            this.sharedService.emitLoaderChange(true);
        })
    }

    savePaymentMethod(paymentMethod: IPaymentMethod) {
        this.paymentMethodService
            .addPaymentMethod(paymentMethod)
            .subscribe(({id}) => {
                this.addNewBillingAddress = false;
                this.changed.emit(id);
            });
    }

    selectBillingAddress() {
        this.dialog.open(SelectAddressBookItemComponent, {
            width: '718px',
            maxHeight: '448px',
            data: {type: 'billing'}
        }).afterClosed().subscribe((addressBookItem) => {
            if (addressBookItem) {
                this.addNewBillingAddress = false;
                this.selectedBillingAddress = addressBookItem;
            }
        });
    }

    private createFormGroup(): void {
        this.paymentMethodForm = this.fb.group({
            cardHolderName: ['', Validators.required],
            firstName: ['', []],
            lastName: ['', []],
            phone: ['', [
                this.validatorService.phone
            ]],
            secondPhone: ['', [this.validatorService.phone]],
            address: ['', []],
            secondAddress: [''],
            city: ['', []],
            state: ['', []],
            country: ['', []],
            postalCode: ['', []],
            defaultPaymentMethod: [true],
            visiblePaymentMethod: [true],
            defaultAddress: [false],
            visibleAddress: [true]
        });
    }
}
