import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AddressBookService} from '../../services';
import {IAddressBookItem} from '../../interfaces';

@Component({
    selector: 'app-select-address-book-item',
    templateUrl: './select-address-book-item.component.html',
    styleUrls: ['./select-address-book-item.component.scss']
})
export class SelectAddressBookItemComponent implements OnInit {
    addresses: IAddressBookItem[] = [];

    constructor(private dialogRef: MatDialogRef<SelectAddressBookItemComponent>,
                private addressBookService: AddressBookService,
                @Inject(MAT_DIALOG_DATA) public data: { type: string }) {
    }

    ngOnInit() {
        this.addressBookService
            .getAddresses()
            .subscribe(({ results }) => {
                this.addresses = results;
            });
    }

    selectAddressBookItem(addressBookItem) {
        this.dialogRef.close(addressBookItem);
    }

    close() {
        this.dialogRef.close();
    }

}
