import {Component, OnInit} from '@angular/core';
import {CartItemService, SharedService, SocialService} from '../../shared-module/services';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-social-redirect',
    templateUrl: './social-redirect.component.html',
    styleUrls: ['./social-redirect.component.scss']
})
export class SocialRedirectComponent implements OnInit {
    private redirectUrl: string;

    constructor(private sharedService: SharedService,
                private socialService: SocialService,
                private router: Router,
                private cartItemService: CartItemService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.redirectUrl = this.sharedService.getRedirectUrl();
        const type = this.route.snapshot.params.type;
        if (type === 'facebook') {
            const code = this.sharedService.getParameterByName('code');
            if (!code) {
                this.router.navigate(['/home']);
                return;
            }
            this.authorizeFacebook(code, type);
        }

        if (type === 'google') {
            const code = this.sharedService.getParameterByName('code');
            if (!code) {
                this.router.navigate(['/home']);
                return;
            }
            this.authorizeGoogle(code, type);
        }

        if (type === 'linkedin') {
            const code = this.sharedService.getParameterByName('code');
            const state = this.sharedService.getParameterByName('state');
            if (!code) {
                this.router.navigate(['/home']);
                return;
            }
            this.authorizeLinkedin(code, state, type);
        }

        if (type === 'twitter') {
            const oauthToken = this.sharedService.getParameterByName('oauth_token');
            const oauthVerifier = this.sharedService.getParameterByName('oauth_verifier');
            if (!oauthToken || !oauthVerifier) {
                this.router.navigate(['/home']);
                return;
            }
            this.authorizeTwitter(oauthToken, oauthVerifier, type);
        }
    }

    private authorizeFacebook(code: string, type: string) {
        const redirectUrl = `${environment.clientUrl}/auth/social-redirect/${type}`;
        this.socialService
            .authorizeFacebook(code, redirectUrl)
            .subscribe(({token}) => {
                this.sharedService.setToken(token);
                this.cartItemService.saveLocalCartItems().then(() => {
                    this.sharedService.removeRedirectUrl();
                    this.router.navigate([this.redirectUrl || '/home']).then();
                });
            });
    }

    private authorizeGoogle(code: string, type: string) {
        const redirectUrl = `${environment.clientUrl}/auth/social-redirect/${type}`;
        this.socialService
            .authorizeGoogle(code, redirectUrl)
            .subscribe(({token}) => {
                this.sharedService.setToken(token);
                this.cartItemService.saveLocalCartItems().then(() => {
                    this.sharedService.removeRedirectUrl();
                    this.router.navigate([this.redirectUrl || '/home']).then();
                });
            });
    }

    private authorizeLinkedin(code: string, state: string, type: string) {
        const redirectUrl = `${environment.clientUrl}/auth/social-redirect/${type}`;
        this.socialService
            .authorizeLinkedin(code, state, redirectUrl)
            .subscribe(({token}) => {
                this.sharedService.setToken(token);
                this.cartItemService.saveLocalCartItems().then(() => {
                    this.sharedService.removeRedirectUrl();
                    this.router.navigate([this.redirectUrl || '/home']).then();
                });
            });
    }

    private authorizeTwitter(oauthToken: string, oauthVerifier: string, type: string) {
        const redirectUrl = `${environment.clientUrl}/auth/social-redirect/${type}`;
        this.socialService
            .authorizeTwitter(oauthToken, oauthVerifier, redirectUrl)
            .subscribe(({token}) => {
                this.sharedService.setToken(token);
                this.cartItemService.saveLocalCartItems().then(() => {
                    this.sharedService.removeRedirectUrl();
                    this.router.navigate([this.redirectUrl || '/home']).then();
                });
            });
    }

}
