import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, ValidatorService} from '../../shared-module/services';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    forgotPasswordForm: FormGroup;
    errMessage: string;
    step = 1;

    constructor(private fb: FormBuilder,
                private authService: AuthService,
                private validatorService: ValidatorService) {
        this.createFormGroup();
    }

    forgotPassword() {
        if (!this.forgotPasswordForm.valid) {
            return;
        }

        this.errMessage = '';

        this.authService
            .forgotPassword(this.forgotPasswordForm.get('email').value)
            .subscribe(() => {
                this.step = 2;
            }, (err) => {
                this.errMessage = err.error.message;
            });
    }

    private createFormGroup() {
        this.forgotPasswordForm = this.fb.group({
            email: ['', [
                Validators.required,
                this.validatorService.email
            ]]
        });
    }

}
