import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared-module/shared.module';
import {DonationMadeComponent} from '../donation-made/donation-made.component';
import {DonationMadeRoutingModule} from  './donation-made-routing.module';

@NgModule({
    declarations: [
        DonationMadeComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        DonationMadeRoutingModule,
    ]
})
export class DonationMadeModule {
}
