import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dimensionUnit'
})
export class DimensionUnitPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (args === 'cm') {
            return value;
        }

        if (args === 'inch') {
            return value * 0.393701;
        }
        return null;
    }

}
