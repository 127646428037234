import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class PictureService {

    constructor(private apiService: ApiService) {
    }

    uploadPicture(files: any): Observable<any[]> {
        const form: FormData = new FormData();
        files.forEach((file) => {
            form.append('files', file, file.name);
        });
        return this.apiService.post<any[]>('pictures/upload', form, {}, true);
    }
}
