import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IArtist, IEducation} from '../../interfaces';
import {ArtistService} from '../../services';

@Component({
    selector: 'app-update-artist-educations',
    templateUrl: './update-artist-educations.component.html',
    styleUrls: ['./update-artist-educations.component.scss']
})
export class UpdateArtistEducationsComponent implements OnInit {
    @Input() artist: IArtist;
    @Output() artistChanged = new EventEmitter<void>();
    educations: IEducation[] = [];
    parentFormSubmitted = false;

    constructor(private artistService: ArtistService) {
    }

    ngOnInit() {
        if (this.artist.educations && this.artist.educations.length) {
            this.artist.educations.forEach((education) => {
                education.valid = true;
                this.addEducation(education);
            });
        } else {
            this.addEducation();
        }
    }

    save() {
        this.parentFormSubmitted = true;
        if (!this.educations.every(education => education.valid)) {
            return;
        }

        const data: IArtist = {
            educations: this.educations,
        };

        this.artistService
            .updateArtistProfile(data)
            .subscribe(() => {
                this.artistChanged.emit();
                this.parentFormSubmitted = false;
            });
    }

    addEducation(education?: IEducation): void {
        this.educations.push(education || {});
    }

    removeEducation(index: number) {
        this.educations.splice(index, 1);

    }
}
