import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IStyle} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class StyleService {

    constructor(private apiService: ApiService) {
    }

    getStyles(params: any): Observable<IApiReceiveResponse<IStyle>> {
        return this.apiService.get<IApiReceiveResponse<IStyle>>('styles/receive/all', {params}, true);
    }
}
