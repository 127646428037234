import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, finalize, map} from 'rxjs/operators';
import {SharedService} from './shared.service';
import {Router} from '@angular/router';
import messages from '../../../assets/messages';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private httpClient: HttpClient,
                private sharedService: SharedService,
                private snackBar: MatSnackBar,
                private router: Router) {
    }

    get<T>(path: string, options: { [param: string]: any } = {}, loading: boolean = false): Observable<any> {
        if (loading) {
            this.sharedService.emitLoaderChange(true);
        }
        return this.httpClient.get<any>(`${environment.apiUrl}/api/${path}`, options)
            .pipe(
                catchError((err) => {
                    this.snackBar.open(messages[err.error.message] || err.error.message, 'x', {
                        duration: 10000,
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                        panelClass: 'error'
                    });
                    if (err.status === 401) {
                        this.sharedService.removeToken();
                    }
                    return throwError(err);
                }),
                finalize(() => {
                    if (loading) {
                        this.sharedService.emitLoaderChange(false);
                    }
                })
            );
    }

    // tslint:disable-next-line:max-line-length
    post<T>(path: string, body: any, options: { [param: string]: any } = {}, loading: boolean = false, showSuccess: boolean = false): Observable<any> {
        if (loading) {
            this.sharedService.emitLoaderChange(true);
        }
        return this.httpClient.post<any>(`${environment.apiUrl}/api/${path}`, body, options)
            .pipe(
                map((data) => {
                    if (showSuccess) {
                        this.snackBar.open(messages[data.message] || data.message, 'x', {
                            duration: 5000,
                            verticalPosition: 'top',
                            horizontalPosition: 'right',
                            panelClass: 'success'
                        });
                    }
                    return data;
                }),
                catchError((err) => {
                    this.snackBar.open(messages[err.error.message] || err.error.message, 'x', {
                        duration: 10000,
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                        panelClass: 'error'
                    });
                    if (err.status === 401) {
                        this.sharedService.removeToken();
                        this.router.navigate(['/auth/login']);
                    }
                    return throwError(err);
                }),
                finalize(() => {
                    if (loading) {
                        this.sharedService.emitLoaderChange(false);
                    }
                })
            );
    }

    // tslint:disable-next-line:max-line-length
    put<T>(path: string, body: any, options: { [param: string]: any } = {}, loading: boolean = false, showSuccess: boolean = false): Observable<any> {
        if (loading) {
            this.sharedService.emitLoaderChange(true);
        }
        return this.httpClient.put<any>(`${environment.apiUrl}/api/${path}`, body)
            .pipe(
                map((data) => {
                    if (showSuccess) {
                        this.snackBar.open(messages[data.message] || data.message, 'x', {
                            duration: 5000,
                            verticalPosition: 'top',
                            horizontalPosition: 'right',
                            panelClass: 'success'
                        });
                    }
                    return data;
                }),
                catchError((err) => {
                    this.snackBar.open(messages[err.error.message] || err.error.message, 'x', {
                        duration: 10000,
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                        panelClass: 'error'
                    });
                    if (err.status === 401) {
                        this.sharedService.removeToken();
                        this.router.navigate(['/auth/login']);
                    }
                    return throwError(err);
                }),
                finalize(() => {
                    if (loading) {
                        this.sharedService.emitLoaderChange(false);
                    }
                })
            );
    }

    // tslint:disable-next-line:max-line-length
    delete<T>(path: string, options: { [param: string]: any } = {}, loading: boolean = false, showSuccess: boolean = false): Observable<any> {
        if (loading) {
            this.sharedService.emitLoaderChange(true);
        }
        return this.httpClient.delete<any>(`${environment.apiUrl}/api/${path}`, options)
            .pipe(
                map((data) => {
                    if (showSuccess) {
                        this.snackBar.open(messages[data.message] || data.message, 'x', {
                            duration: 5000,
                            verticalPosition: 'top',
                            horizontalPosition: 'right',
                            panelClass: 'success'
                        });
                    }
                    return data;
                }),
                catchError((err) => {
                    this.snackBar.open(messages[err.error.message] || err.error.message, 'x', {
                        duration: 10000,
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                        panelClass: 'error'
                    });
                    if (err.status === 401) {
                        this.sharedService.removeToken();
                        this.router.navigate(['/auth/login']);
                    }
                    return throwError(err);
                }),
                finalize(() => {
                    if (loading) {
                        this.sharedService.emitLoaderChange(false);
                    }
                })
            );
    }
}
