import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IArtwork, IUser} from '../../interfaces';

@Component({
    selector: 'app-masonry-list',
    templateUrl: './masonry-list.component.html',
    styleUrls: ['./masonry-list.component.scss']
})
export class MasonryListComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() user: IUser;
    @Input() artworks: IArtwork[];
    @Input() maxColumns: number;
    @Input() gutter: number;
    @Input() marginBottom: number;
    columnCount = 4;
    checkInterval: any;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.checkInterval = setInterval(() => {
            const container: HTMLElement = document.querySelector('.artwork-list-container');
            this.columnCount = Math.floor(container.clientWidth / 350);
            this.columnCount = this.columnCount || 1;
            if (this.columnCount > this.maxColumns) {
                this.columnCount = this.maxColumns;
            }
        }, 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this.checkInterval);
    }
}
