import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService, ArtistService, SharedService} from '../services';

@Injectable({
    providedIn: 'root'
})
export class ArtistReviewAuthorizedGuard implements CanActivate {

    constructor(private userService: UserService,
                private artistService: ArtistService,
                private sharedService: SharedService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (!this.sharedService.getToken()) {
                this.navigate('unauthorized');
                return resolve(false);
            }

            const url = state.url;
            Promise
                .all([
                    this.userService.getProfile().toPromise(),
                    this.artistService.getArtist().toPromise()
                ])
                .then(([, artist]) => {
                    if (artist.status === 'approved') {
                        this.router.navigate(['/home']);
                        resolve(false);
                        return;
                    }

                    if (url !== '/artist-review/details' && !artist.artistId) {
                        this.navigate('details');
                        resolve(false);
                        return;
                    }

                    if (artist.artistId && artist.status !== 'pending_artist_review' && url !== '/artist-review/complete') {
                        this.navigate('complete');
                        resolve(false);
                    }

                    if (url === '/artist-review/complete' && artist.artistId && artist.status === 'pending_artist_review') {
                        this.navigate('details');
                        return;
                    }

                    resolve(true);
                })
                .catch(() => {
                    this.navigate('unauthorized');
                    resolve(false);
                });
        });
    }

    private navigate(path: string): void {
        setTimeout(() => {
            this.router.navigate([`/artist-review/${path}`]).then();
        }, 0);
    }
}
