import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FilteredLinkComponent} from './filtered-link.component';
import {ProfileResolverService} from '../shared-module/resolvers';

const routes: Routes = [
    {
        path: 'filtered-link',
        component: FilteredLinkComponent,
        resolve: {
            user: ProfileResolverService
        }
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FilteredLinkRoutingModule { }
