import {Component, OnInit} from '@angular/core';
import {ICompany} from '../../shared-module/interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../../shared-module/services';
import { Title } from "@angular/platform-browser";
@Component({
    selector: 'app-artist-review-unauthorized',
    templateUrl: './artist-review-unauthorized.component.html',
    styleUrls: ['./artist-review-unauthorized.component.scss']
})
export class ArtistReviewUnauthorizedComponent implements OnInit {
    company: ICompany;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private sharedService: SharedService,
                private title: Title) {
    }

    ngOnInit() {
        this.title.setTitle('Sell Your Art');
        this.company = this.route.snapshot.data.company;
    }

    openRegisterPage() {
        this.sharedService.setRedirectUrl('/artist-review/details');
        this.router.navigate(['/auth/register']).then();
    }

    openLoginPage() {
        this.sharedService.setRedirectUrl('/artist-review/details');
        this.router.navigate(['/auth/login']).then();
    }
}
