import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {GiftCardService} from '../../services/gift-card.service';

@Component({
    selector: 'app-redeem-gift-card',
    templateUrl: './redeem-gift-card.component.html',
    styleUrls: ['./redeem-gift-card.component.scss']
})
export class RedeemGiftCardComponent implements OnInit {
    code: FormControl;

    constructor(private dialogRef: MatDialogRef<RedeemGiftCardComponent>,
                private giftCardService: GiftCardService) {
        this.code = new FormControl('', [Validators.required]);
    }

    ngOnInit(): void {
    }

    redeemGiftCard(): void {
        if (this.code.invalid) {
            return;
        }
        this.giftCardService
            .redeemGiftCard(this.code.value)
            .subscribe(() => {
                this.dialogRef.close(true);
            });
    }

    close() {
        this.dialogRef.close();
    }

}
