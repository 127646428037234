import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IPaymentMethod} from '../interfaces';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodService {
    constructor(private apiService: ApiService) {
    }

    getPaymentMethods(params: any = {}): Observable<IApiReceiveResponse<IPaymentMethod>> {
        return this.apiService.get<IApiReceiveResponse<IPaymentMethod>>('payment-methods/receive/all', {params}, true);
    }

    getPaymentMethod(paymentMethodId: string): Observable<IPaymentMethod> {
        return this.apiService.get<IPaymentMethod>(`payment-methods/receive/${paymentMethodId}`, {}, true);
    }

    addPaymentMethod(paymentMethod: IPaymentMethod): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('payment-methods/add', paymentMethod, {}, true, true);
    }

    updatePaymentMethod(paymentMethodId: string, defaultPaymentMethod): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`payment-methods/update/${paymentMethodId}`, {defaultPaymentMethod}, {}, true, true);
    }

    deletePaymentMethod(paymentMethodId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`payment-methods/delete/${paymentMethodId}`, {}, true, true);
    }
}
