export default [
    {
        name: 'Yerevan',
        cities: [
            'Yerevan'
        ]
    },
    {
        name: 'Aragatsotn',
        cities: [
            'Agarak',
            'Aghdzq',
            'Akunq',
            'Alagyaz',
            'Antarut',
            'Aparan',
            'Apna',
            'Aragats',
            'Aragatsavan',
            'Aragatsotn',
            'Arayi',
            'Armine-Taza/Saduntc/',
            'Artashavan',
            'Arteni',
            'Arutch',
            'Ashnak',
            'Ashtarak',
            'Avan',
            'Avshen',
            'Barozh/Arevut/',
            'Bayoz/Odevan/',
            'Bazmaghbyur',
            'Berqarat',
            'Byurakan',
            'Chqnagh',
            'Dashtadem',
            'Davtashen',
            'Ddmasar',
            'Derek',
            'Dian',
            'Dprevanq',
            'Dzoragyugh',
            'Garnahovit',
            'Geghadir H/K',
            'Geghadzor',
            'Gegharot',
            'Getap',
            'Ghazaravan',
            'Hakko',
            'Hartavan',
            'Hatcashen',
            'Irind',
            'Jamshlu',
            'Jrambar',
            'Kanch',
            'Kanyashir',
            'Kaqavadzor',
            'Karbi',
            'Karin',
            'Katnaghbyur',
            'Kayq',
            'Kosh',
            'Lernapar H/K',
            'Lernarot',
            'Lusagyugh',
            'Lusakn',
            'Mastara',
            'Meliq gyugh',
            'Metsadzor',
            'Mijnatun',
            'Miraq',
            'Nerqin Bazmaberd',
            'Nerqin Sasnashen',
            'Nigavan',
            'Nor Amanos',
            'Nor Artik',
            'Nor Yedesia',
            'Norashen',
            'Ohanavan',
            'Orgov',
            'Oshakan',
            'Parpi',
            'Partizak',
            'Quchak',
            'Rya-Taza',
            'Saghmosavan',
            'Sasunik',
            'Shamiram',
            'Shenakan',
            'Shenavan',
            'Shgharshik',
            'Shoghakn',
            'Sipan',
            'Sorik',
            'Suser',
            'Talin',
            'Tatul',
            'Tcamaqsar',
            'Tegher',
            'Tliq',
            'Tsaghkahovit',
            'Tsaghkasar',
            'Tsaghkashen',
            'Tsilqar',
            'Ttujur',
            'Ujan',
            'Ushi',
            'Vardablur',
            'Vardavank',
            'Vardenis',
            'Vardenut',
            'Verin Bazmaberd',
            'Verin Sasnashen',
            'Verin Sasunik',
            'Voskehat',
            'Vosketas',
            'Voskevaz',
            'Yeghipatrush',
            'Yeghnik',
            'Yernjatap',
            'Zarinja',
            'Zovasar'
        ]
    },
    {
        name: 'Ararat',
        cities: [
            'Masis',
            'Zorak',
            'Abovyan',
            'Aralez',
            'Araqsavan',
            'Ararat',
            'Arbat',
            'Arevabuyr',
            'Arevshat',
            'Argavand',
            'Armash',
            'Artashat',
            'Avshar',
            'Aygavan',
            'Aygepat',
            'Aygestan',
            'Aygezard',
            'Ayntap',
            'Azatashen',
            'Azatavan',
            'Baghramyan',
            'Bardzrashen',
            'Berdik',
            'Berqanush',
            'Burastan',
            'Byuravan',
            'Dalar',
            'Darakert',
            'Darbnik',
            'Dashtaqar',
            'Dashtavan',
            'Deghdzut',
            'Dimitrov',
            'Ditak',
            'Geghanist',
            'Getapnya',
            'Getazat',
            'Ghukasavan',
            'Ginevet',
            'Goravan',
            'Hayanist',
            'Hnaberd',
            'Hovtashat',
            'Hovtashen',
            'Jrahovit',
            'Jrashen',
            'Kanachut',
            'Khachpar',
            'Lanjanist',
            'Lanjar',
            'Lanjazat',
            'Lusarat',
            'Lusashogh',
            'Marmarashen',
            'Masis',
            'Mkhchyan',
            'Mrganush',
            'Mrgavan',
            'Mrgavet',
            'N.Dvin',
            'Narek',
            'Nizami',
            'Nor Kharberd',
            'Nor Kyanq',
            'Nor Kyuri',
            'Nor Ughi',
            'Norabatc',
            'Noramarg',
            'Norashen',
            'Noyakert',
            'Nshavan',
            'Paruyr Sevak',
            'Poqr Vedi',
            'Qaghtcrashen',
            'Ranchpar',
            'Sayat Nova',
            'Shaghap',
            'Shahumyan',
            'Sipanik',
            'Sis',
            'Sisavan',
            'Surenavan',
            'Taperakan',
            'Tigranashen',
            'Urtcadzor',
            'Urtcalanj',
            'v. Ararat',
            'V.Artashat',
            'V.Dvin',
            'Vanashen',
            'Vardashat',
            'Vardashen',
            'Vedi',
            'Vosketap',
            'Vostan',
            'Yeghegnavan',
            'Yeraskh',
            'Zangakatun'
        ]
    },
    {
        name: 'Armavir',
        cities: [
            'Lukashin',
            'Aghavnatun',
            'Aknalitch',
            'Aknashen',
            'Amberd',
            'Apaga',
            'Aragats',
            'Araqs',
            'Aratashen',
            'Arazap',
            'Arevadasht',
            'Arevashat',
            'Argina',
            'Armavir',
            'Armavir',
            'Arshaluys',
            'Artamet',
            'Artashar',
            'Artimet',
            'Aygek',
            'Aygeshat',
            'Bagaran',
            'Bambakashat',
            'Berqashat',
            'Dalarik',
            'Dasht',
            'Doghs',
            'Dzerzhinski',
            'Ferik',
            'Gay',
            'Geghakert',
            'Getashen',
            'Griboyedov',
            'Haykashen',
            'Haytagh',
            'Hoktember',
            'Hovtamej',
            'Hushakert',
            'Janfida',
            'Khanjyan',
            'Koghbavan',
            'Lenughi',
            'Lenughi (Khorunk)',
            'Lernagog',
            'Lernamerdz',
            'Lusagyugh',
            'Margara',
            'Merdzavan',
            'Metsamor',
            'Metsamor',
            'Mrgashat',
            'Mrgastan',
            'Musaler',
            'Myasnikyan',
            'Nalbandyan',
            'Nor-Armavir',
            'Nor-Artages',
            'Nor-Kesaria',
            'Norakert',
            'Norapat',
            'Noravan',
            'Paraqar',
            'Pshatavan',
            'Ptghunq',
            'Qarakert',
            'Shahumyani trch. Fabrika',
            'Shenavan',
            'Shenik',
            'Sovetakan',
            'Tairov',
            'Talvorik',
            'Tandzut',
            'Taronik',
            'Tsaghkalanj',
            'Tsiatsan',
            'Vagharshapat',
            'Vanand',
            'Vardanashen',
            'Voskehat',
            'Yeraskhahun',
            'Yervandashat',
            'Zartonq'
        ]
    },
    {
        name: 'Gegharkunik',
        cities: [
            'Aghberq',
            'Akhpradzor',
            'Antaramej',
            'Areguni',
            'Arpunq',
            'Artanish',
            'Artsvanist',
            'Astghadzor',
            'Avazan',
            'Aygut',
            'Ayrq',
            'Azat',
            'Barepat',
            'Berkunq',
            'Chkalovka',
            'Daranak',
            'Ddmashen',
            'Dprabak',
            'Drakhtik',
            'Dzoravanq',
            'Gagarin',
            'Gandzak',
            'Gavar',
            'Geghamabak',
            'Geghamasar',
            'Geghamavan',
            'Geghaqar',
            'Gegharquniq',
            'Geghhovit',
            'Getik',
            'Hayravanq',
            'Jaghatcadzor',
            'Jil',
            'Jivkhlu',
            'Kakhakn',
            'Kalavan',
            'Karmir gyugh',
            'Kartchaghbyur',
            'Khachaghbyur',
            'Kut',
            'Kutakan',
            'Lanjaghbyur',
            'Lernahovit',
            'Litchq',
            'Lor',
            'Ltchap',
            'Ltchashen',
            'Ltchavan',
            'Lusakunq',
            'Madina',
            'Maqenis',
            'Martuni',
            'Martuni',
            'Mets Masrik',
            'N.Getashen',
            'N.Shorzha',
            'Norabak',
            'Norakert',
            'Noratus',
            'Poqr Masrik',
            'Sarukhan',
            'Semyonovka',
            'Sevan',
            'Shatavan',
            'Shatjreq',
            'Shorzha',
            'Sotq',
            'Tchambarak',
            'Torfavan',
            'Tretuq',
            'Tsaghkunq',
            'Tsakqar',
            'Tsapatagh',
            'Tsovagyugh',
            'Tsovak',
            'Tsovasar',
            'Tsovazard',
            'Tsovinar',
            'Ttujur',
            'V.Getashen',
            'V.Shorzha',
            'Vaghashen',
            'Vahan',
            'Vanevvan',
            'Vardadzor',
            'Vardenik',
            'Vardenis',
            'Varser',
            'Yand',
            'Yeranos',
            'Zolaqar',
            'Zovaber'
        ]
    },
    {
        name: 'Lori',
        cities: [
            'Hartagyugh',
            'Hobardz',
            'Agarak',
            'Ahnidzor',
            'Akhtala',
            'Akner',
            'Alaverdi',
            'Amoj',
            'Amrakitc',
            'Antaramut',
            'Antarashen',
            'Apaven',
            'Aqori',
            'Ardvi',
            'Arevashogh',
            'Arevatsag',
            'Arjut',
            'Armanis',
            'Artsni',
            'Atan',
            'Aygehat',
            'Ayrum',
            'Aznvadzor',
            'Bazum',
            'Bendik',
            'Blagodarnoye',
            'Bovadzor',
            'Chkalov',
            'Darpas',
            'Dashtadem',
            'Debet',
            'Dsegh',
            'Dzoraget',
            'Dzoragyugh',
            'Dzoramut',
            'Dzyunashogh',
            'Fioletovo',
            'Gar-Gar',
            'Geghasar',
            'Getavan',
            'Ghursal',
            'Gogaran',
            'Gogavan',
            'Gugarq',
            'Gyulagarak',
            'Haghpat',
            'Hagvi',
            'Hallavar',
            'Hovnanadzor',
            'Jiliza',
            'K.Aghegi',
            'Katchatchkut',
            'Katnaghbyur',
            'Katnajur',
            'Katnarat',
            'Khnkoyan',
            'Koghes',
            'Kruglaya Shishka',
            'Kurtan',
            'Lejan',
            'Lermontov',
            'Lernahovit',
            'Lernantcq',
            'Lernapat',
            'Lernavan',
            'Lori berd',
            'Lorut',
            'Lusaghbyur',
            'Margahovit',
            'Martc',
            'Medovka',
            'Meghrahovit',
            'Mets Ayrum',
            'Mets Parni',
            'Metsavan',
            'Mghart',
            'Mikhaylovka',
            'Neghotc',
            'Nor Khachakap',
            'Noramut',
            'Novoseltcovo',
            'Odzun',
            'P.Ayrum',
            'Paghaghbyur',
            'Pambak',
            'Petrovka',
            'Privolnoye',
            'Pushkino',
            'Qaraberd',
            'Qaradzor',
            'Qarinj',
            'Qarkop',
            'Qober',
            'Sarahart',
            'Saralanj',
            'Saramej',
            'Saratovka',
            'Sarchapet',
            'Shamlugh',
            'Shamut',
            'Shenavan',
            'Shirakamut',
            'Shnogh',
            'Spitak',
            'Stepanavan',
            'Sverdlov',
            'Tashir',
            'Tchotchkan',
            'Teghut',
            'Tsaghkaber',
            'Tsaghkashat',
            'Tsater',
            'Tumanyan',
            'Urasar',
            'Urut',
            'Vahagnadzor',
            'Vahagni',
            'Vanadzor',
            'Vardablur',
            'Yaghdan',
            'Yeghegnut'
        ]
    },
    {
        name: 'Kotayk',
        cities: [
            'Kotayq',
            'Marmarik',
            'Mayakovski',
            'Meghradzor',
            'Mrgashen',
            'Abovyan',
            'Aghavnadzor',
            'Akunq',
            'Alapars',
            'Aragyugh',
            'Aramus',
            'Argel',
            'Arinj',
            'Artavaz',
            'Arzakan',
            'Arzni',
            'Balahovit',
            'Bjni',
            'Buzhakan',
            'Byureghavan',
            'Charentcavan',
            'Dzoraghbyur',
            'Fantan',
            'Garni',
            'Geghadir',
            'Geghard',
            'Geghashen',
            'Getamej',
            'Getarqel',
            'Goght',
            'Hanqavan',
            'Hatcavan',
            'Hatis',
            'Hrazdan',
            'Jraber',
            'Jraratavan',
            'Jrvezh',
            'Kamaris',
            'Kaputan',
            'Karenis',
            'Katnaghbyur',
            'Lernanist',
            'Nor Artamet',
            'Nor Geghi',
            'Nor gyւogh',
            'Nor Hatchn,',
            'Nor Yerznka',
            'Nor-Hatchn',
            'Nurnus',
            'Poqr Dzorak',
            'Proshyan',
            'Ptghni',
            'Pyunik',
            'Qaghsi',
            'Qanaqeravan',
            'Qarashamb',
            'Qasakh',
            'Saralanj',
            'Sevaberd',
            'Solak',
            'Tegheniq',
            'Tsaghkadzor',
            'V.Ptghni',
            'Voghjaberd',
            'Yeghvard',
            'Zar',
            'Zoravan',
            'Zovashen',
            'Zovq',
            'Zovuni',
            'Zovuni'
        ]
    },
    {
        name: 'Shirak',
        cities: [
            'Jrarat',
            'Adenis',
            'Aghin',
            'Aghvorik',
            'Akhurik',
            'Akhuryan',
            'Akhuryan',
            'Alvar',
            'Amasia',
            'Ani-Avan',
            'Ani-Pemza',
            'Anushavan',
            'Apeni',
            'Arapi',
            'Aregnadem',
            'Arevik',
            'Ashotcq',
            'Aygebatc',
            'Azatan',
            'Bagrvan',
            'Bandivan',
            'Bardzrashen',
            'Bashgyugh',
            'Bavra',
            'Bayandur',
            'Beniamin',
            'Berdashen',
            'Byurakn',
            'Darik',
            'Dzithanqov',
            'Dzorakert',
            'Dzorashen',
            'Garnaritch',
            'Getq',
            'Gharibjanyan',
            'Ghazanchi',
            'Gogohovit',
            'Gtashen',
            'Gusanagyugh',
            'Gyumri',
            'Haritch',
            'Hartashen',
            'Hatcik',
            'Hatcikavan',
            'Haykadzor',
            'Haykasar',
            'Haykavan',
            'Hayrenyatc',
            'Hoghmik',
            'Horom',
            'Hovit',
            'Hovtan',
            'Hovuni',
            'Isahakyan henaket',
            'Jajur',
            'Jorakap',
            'Jradzor',
            'Jrapi',
            'Kamkhut',
            'Kamo',
            'Kaps',
            'Kaqavasar',
            'Karmraqar',
            'Karmravan',
            'Karnut',
            'Krasar',
            'Krashen',
            'Lanjik',
            'Lernagog',
            'Lernakert',
            'Lernut',
            'Lusakert',
            'Marmashen',
            'Mayisyan',
            'Meghrashat',
            'Meghrashen',
            'Mets Mantash',
            'Mets Sepasar',
            'Musaelyan',
            'Nahapetavan',
            'Nor Kyanq',
            'P.Sepasar',
            'Paghakn',
            'Panik',
            'Pemzashen',
            'Poqr Mantash',
            'Poqrashen',
            'Qaraberd',
            'Qeti',
            'Salut',
            'Saragyugh',
            'Sarakap',
            'Sarapat',
            'Saratak',
            'Sariar',
            'Sariar',
            'Sarnaghbyur1',
            'Shaghik',
            'Shirak',
            'Shirakavan',
            'Sizavet',
            'Spandaryan',
            'Tavshut',
            'Tcoghamarg',
            'Toros',
            'Tsaghkut',
            'Tufashen',
            'Vahramaberd',
            'Vardaghbyur',
            'Vardaqar',
            'Voghji',
            'Voskehask',
            'Yeghnajur',
            'Yerazgavors',
            'Zarishat',
            'Zuygaghbyur'
        ]
    },
    {
        name: 'Syunik',
        cities: [
            'Agarak',
            'Aghitu',
            'Aghvani',
            'Ajibaj',
            'Akhlatyan',
            'Akner',
            'Alvank',
            'Andokavan',
            'Angeghakot',
            'Antarashen',
            'Arajadzor',
            'Aravus',
            'Arevis',
            'Artsvanik',
            'Ashotavan',
            'Aygedzor',
            'Balaq',
            'Bardzravan',
            'Bnunis',
            'Brnakot',
            'Chakaten',
            'Chapni',
            'Darbas',
            'Dastakert',
            'Davit Bek',
            'Ditsmayr',
            'Dzorastan',
            'Egheg',
            'Eghvard',
            'Geghanush',
            'Geghavank',
            'Geghi',
            'Geghvartots',
            'Getatagh',
            'Ghovshut',
            'Gorayk',
            'Goris',
            'Gudemis',
            'Halidzor',
            'Harjis',
            'Hatsavan',
            'Ishkhanasar',
            'Kaghnut',
            'Kapan',
            'Karchevan',
            'Kard',
            'Kavchut',
            'Khalaj',
            'Khdrants',
            'Khnatsakh',
            'Khndzoresk',
            'Khordzor',
            'Khot',
            'Khoznavar',
            'Kornidzor',
            'Kuris',
            'Lehvaz',
            'Lernadzor',
            'Litchq',
            'Ltsen',
            'Meghri',
            'Mijnavan',
            'Musallam',
            'Mutsk',
            'N.Hand',
            'N.Khndzoresk',
            'N.Khotanan',
            'Njdeh',
            'Nor Arajadzor',
            'Nor Astghaberd',
            'Norashenik',
            'Noravan',
            'Nrnadzor',
            'Okhtar',
            'Pirlu',
            'Qajaran',
            'Qajaran',
            'Qarahunj',
            'Qarashen',
            'Qashuni',
            'Qyurut',
            'Salvard',
            'Sarnakunq',
            'Sevqar',
            'Shaghat',
            'Shamb',
            'Shaqi',
            'Shentagh',
            'Shikahogh',
            'Shinuhayr',
            'Shishkert',
            'Shrvanants',
            'Shurnugh',
            'Shvanidzor',
            'Sisian',
            'Spandaryan',
            'Srashen',
            'Svarants',
            'Tanahar',
            'Tandzatap',
            'Tandzaver',
            'Tashtun',
            'Tasik',
            'Tatev',
            'Tavrus',
            'Tegh',
            'Tkhkut',
            'Tolors',
            'Torunik',
            'Tsav',
            'Tsghuk',
            'Tsghuni',
            'Ujanis',
            'Uyts',
            'V.Khotanan',
            'Vaghatni',
            'Vaghatur',
            'Vahravar',
            'Vaneq',
            'Vardanadzor',
            'Verishen',
            'Vocheti',
            'Vorotan'
        ]
    },
    {
        name: 'Vayots Dzor',
        cities: [
            'Agarakadzor',
            'Aghnajur',
            'Areni',
            'Areni',
            'Arpi',
            'Artabuynq',
            'Artavan',
            'Azatek',
            'Bardzruni',
            'Chiva',
            'Getap',
            'Gladzor',
            'Gndevaz',
            'Gnishik',
            'Goghtanik',
            'Gomq',
            'Herher',
            'Hermon',
            'Horbategh',
            'Hors',
            'Jermuk',
            'Karmrashen',
            'Kechut',
            'Khachik',
            'Khndzorut',
            'Malishka',
            'Martiros',
            'Nor Aznaberd',
            'Por',
            'Qaraglukh',
            'Rind',
            'Salli',
            'Saravan',
            'Sers',
            'Shatin',
            'Taratamb',
            'Ughedzor',
            'Vardahovit',
            'Vayq',
            'Vernashen',
            'Yeghegis',
            'Yeghegnadzor',
            'Yelpin',
            'Zaritap',
            'Zedea'
        ]
    },
    {
        name: 'Tavush',
        cities: [
            'Achajur',
            'Aghavnavanq',
            'Aknaghbyur',
            'Artchis',
            'Artsvaberd',
            'Atcharakut',
            'Aygedzor',
            'Aygehovit',
            'Aygepar',
            'Azatamut',
            'Baghanis,',
            'Bagratashen',
            'Barekamavan',
            'Berdavan',
            'Berqaber',
            'Chin-Chin',
            'Chinari',
            'Choratan',
            'Debedavan',
            'Deghdzavan',
            'Dilijan',
            'Ditavan',
            'Dovegh',
            'Gandzaqar',
            'Getahovit',
            'Gosh',
            'Haghartsin',
            'Haghtanak',
            'Hovq',
            'Ijevan',
            'Itsaqar',
            'Jujevan',
            'Kayanavan',
            'Khachardzan',
            'Khashtarak',
            'Kirantc',
            'Koghb',
            'Koti',
            'Ltchkadzor',
            'Lusadzor',
            'Lusahovit',
            'Movses',
            'N.Karmiraghbyur',
            'Navur',
            'Noyemberan',
            'Paravaqar',
            'Ptghavan',
            'Sarigyugh',
            'Sevqar',
            'Tavush',
            'Tsaghkavan',
            'V.Karmiraghbyur',
            'Varagavan',
            'Vazashen',
            'Voskepar',
            'Voskevan',
            'Yenoqavan',
            'Zorakan'
        ]
    }
];
