import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit {
    headings: string[] = [
        'do i need an account to ask a question about a specific work of art?',
        'how can i see an up close view of an artwork?',
        'how can i understand how an item might look in my home?',
        'i’m having trouble signing in.',
        'what happens if i forget my password?',
        'how can i easily find the artwork i need?',
        'how can i request a return for an item i have received?',
        'what if i purchased an artwork and it was damaged during shipment?',
        'my item has not yet arrived. when will it arrive?',
        'how do i submit an offer?',
        'why do i need to provide my credit card details when i submit an offer?',
        'is the artwork on hold while i wait for an answer to my offer?',
        'what happens if the offer is not accepted?',
        'how many offers can i submit to different artists?',
        'if my offer is accepted, do i have to go forward with the purchase?',
        'if my offer is accepted, can i apply additional promotional discounts to it?',
        'can i combine multiple accepted offers on one order?'
    ];
    searchText: FormControl;

    constructor(private title: Title) {
        this.searchText = new FormControl('');
    }

    ngOnInit() {
        this.title.setTitle("Faq");
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }
}
