import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-donation-left-side',
    templateUrl: './donation-left-side.component.html',
    styleUrls: ['./donation-left-side.component.scss']
})
export class DonationLeftSideComponent implements OnInit {
    @Input() bgImage: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
