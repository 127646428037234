import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared-module/services';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gift-card-unauthorized',
  templateUrl: './gift-card-unauthorized.component.html',
  styleUrls: ['./gift-card-unauthorized.component.scss']
})
export class GiftCardUnauthorizedComponent implements OnInit, AfterViewInit {

    constructor(private sharedService: SharedService, private router: Router) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    openRegisterPage() {
        this.sharedService.setRedirectUrl('/gift-card/checkout');
        this.router.navigate(['/auth/register']).then();
    }

    openLoginPage() {
        this.sharedService.setRedirectUrl('/gift-card/checkout');
        this.router.navigate(['/auth/login']).then();
    }
}
