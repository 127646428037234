import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CartItemService, UserService, SharedService, ArtworkService} from '../../shared-module/services';
import {IArtwork, IUser} from '../../shared-module/interfaces';
import {FormControl, Validators} from '@angular/forms';
import {PromoCodeService} from '../../shared-module/services/promo-code.service';

@Component({
    selector: 'app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, AfterViewInit {
    user: IUser;
    artworks: IArtwork[] = [];
    promoCode: FormControl;
    promoCodeId: string;
    discount: number;
    addNewPromoCode = false;
    count = 0;
    subtotal = 0;
    total = 0;

    constructor(private cartItemService: CartItemService,
                private sharedService: SharedService,
                private artworkService: ArtworkService,
                private promoCodeService: PromoCodeService,
                private userService: UserService) {
        this.promoCode = new FormControl('', [Validators.required]);
    }

    ngOnInit() {
        this.getProfile();
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    private getProfile() {
        if (!this.sharedService.getToken()) {
            this.user = null;
            this.getCartItems();
            return;
        }
        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
                this.getCartItems();
            }, () => {
                this.user = null;
                this.getCartItems();
            });
    }

    checkPromoCode(): void {
        if (!this.promoCode.value) {
            return;
        }
        const params: any = {code: this.promoCode.value};
        if (this.user) {
            params.customerId = this.user.customer.customerId;
        }
        this.promoCodeService
            .checkPromoCode(params)
            .subscribe(({valid, id, discount}) => {
                if (valid) {
                    this.promoCodeId = id;
                    this.addNewPromoCode = false;
                    this.discount = discount;
                    this.promoCode.setErrors(null);
                    this.getCartItems();
                } else {
                    this.discount = 0;
                    this.promoCodeId = null;
                    this.promoCode.setErrors({invalid: true});
                    this.getCartItems();
                }
            });
    }

    private getCartItems(): void {
        if (!this.user) {
            const localCartItems = this.cartItemService.getLocalCartItems();
            if (localCartItems.length) {
                Promise
                    .all(localCartItems.map(artworkId => this.getArtwork(artworkId)))
                    .then((artworks) => {
                        this.artworks = artworks;
                        this.count = artworks.length;
                        this.subtotal = this.artworks.reduce((sum, artwork) => sum + artwork.completedPrice, 0);
                        this.total = this.subtotal - ((this.subtotal / 100) * this.discount);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.artworks = [];
            }

            return;
        }

        const params: any = {};

        if (this.promoCodeId) {
            params.promoCodeId = this.promoCodeId;
        }

        this.cartItemService
            .getCartItems(params)
            .subscribe(({results, count, total, subtotal}) => {
                this.artworks = results;
                this.count = count;
                this.total = total;
                this.subtotal = subtotal;
            });
    }

    private getArtwork(artworkId: string): Promise<IArtwork> {
        return new Promise<IArtwork>((resolve, reject) => {
            this.artworkService
                .getArtwork(artworkId)
                .subscribe((artwork) => {
                    resolve(artwork);
                }, (err) => {
                    reject(err);
                });
        });
    }

    removeCartItem(artwork: IArtwork) {
        if (!this.user) {
            this.cartItemService.deleteLocalCartItem(artwork.artworkId);
            this.getCartItems();
            return;
        }
        this.cartItemService
            .deleteCartItem(artwork.cartItem.cartItemId)
            .subscribe(() => {
                this.getCartItems();
            });
    }
}
