import {Component, OnInit} from '@angular/core';
import {AuthService, CartItemService, SharedService} from '../../shared-module/services';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-verify-user',
    templateUrl: './verify-user.component.html',
    styleUrls: ['./verify-user.component.scss']
})
export class VerifyUserComponent implements OnInit {
    private redirectUrl: string;

    constructor(private sharedService: SharedService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private cartItemService: CartItemService,
                private router: Router) {
    }

    ngOnInit() {
        this.redirectUrl = this.sharedService.getRedirectUrl();
        const email = this.route.snapshot.params.email;
        const verificationCode = this.route.snapshot.params.verificationCode;

        if (!email || !verificationCode) {
            return this.router.navigate(['/home']);
        }
        this.verifyUser(email, verificationCode);
    }

    verifyUser(email: string, verificationCode: string) {
        this.authService
            .verifyUser(email, verificationCode)
            .subscribe((res) => {
                const token = res.token;
                this.sharedService.setToken(token);
                this.cartItemService.saveLocalCartItems().then(() => {
                    this.sharedService.removeRedirectUrl();
                    this.router.navigate([this.redirectUrl || '/home'])
                        .then(() => window.location.reload());
                });
            }, (err) => {
                this.router.navigate(['/home']);
            });
    }

}
