import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ArtworkService, UserService, SharedService, CartItemService, FavoriteService} from '../shared-module/services';
import {MatDialog} from '@angular/material/dialog';
import {ArtworkAddedComponent, ArtworkBuyNowComponent, ArtworkZoomComponent} from '../shared-module/modals';
import {IArtwork, IColor, IFavoriteList, IUser} from '../shared-module/interfaces';
import {switchMap, tap} from 'rxjs/operators';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {WishListUnauthorizedDialogComponent} from './dialogs/wish-list-unauthorized-dialog/wish-list-unauthorized-dialog.component';
import {OfferUnauthorizedDialogComponent} from './dialogs/offer-unauthorized-dialog/offer-unauthorized-dialog.component';
import {BuyNowUnauthorizedDialogComponent} from './dialogs/buy-now-unauthorized-dialog/buy-now-unauthorized-dialog.component'
import {AddWishListDialogComponent} from './dialogs/add-wish-list-dialog/add-wish-list-dialog.component';

@Component({
    selector: 'app-artwork',
    templateUrl: './artwork.component.html',
    styleUrls: ['./artwork.component.scss']
})
export class ArtworkComponent implements OnInit, AfterViewInit, OnDestroy {
    user: IUser;
    artwork: IArtwork;
    artistArtworks: IArtwork[] = [];
    similarArtworks: IArtwork[] = [];
    element: HTMLElement;
    listener: any;
    stream$ = new Subject();
    artworkId: string;
    colors: IColor[] = [];
    favoriteLists: IFavoriteList[] = [];
    isFavorite = false;
    showSingleBannerPromoCode: boolean;
    showMultipleBannerPromoCodes: boolean;

    constructor(private route: ActivatedRoute,
                private artworkService: ArtworkService,
                private router: Router,
                private dialog: MatDialog,
                private userService: UserService,
                private favoriteService: FavoriteService,
                private cartItemService: CartItemService,
                private sharedService: SharedService) {
    }

    onArtworkChange(): Observable<any> {
        return this.stream$.asObservable();
    }

    emitArtworkChange(params: any): void {
        this.stream$.next(params);
    }

    ngOnInit(): void {
        this.colors = this.route.snapshot.data.colors;
        this.onArtworkChange().pipe(
            tap(params => this.artworkId = params.artworkId),
            switchMap(() => {
                if (!this.sharedService.getToken()) {
                    return of(null);
                }
                return this.userService.getProfile();
            }),
            tap(user => this.user = user),
            switchMap(() => {
                const params: any = {};
                if (this.user) {
                    params.customerId = this.user.customer.customerId;
                }
                return this.artworkService.getArtwork(this.artworkId, params);
            }),
            tap(artwork => this.artwork = artwork),
            tap(() => {
                if (this.user) {
                    this.getFavoriteLists();
                }
            }),
            switchMap(() => forkJoin([
                this.artworkService.getArtistArtworks(this.artwork.artist.artistId, {offset: 0, limit: 3}),
                this.artworkService.searchArtworks({
                    offset: 0,
                    limit: 3,
                    styles: this.artwork.styles.map(style => style.styleId).join(','),
                    material: this.artwork.materials.map(material => material.materialId).join(','),
                })
            ]))
        ).subscribe(([{results: artistArtworks}, {results: similarArtworks}]) => {
            this.artistArtworks = artistArtworks;
            this.similarArtworks = similarArtworks;
            document.querySelector('mat-drawer-content').scrollTo(0, 0);
        });
        this.route.params.subscribe(params => this.emitArtworkChange(params));
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
        this.element = document.querySelector('mat-drawer-content');
        this.listener = () => {
            const container: HTMLElement = document.querySelector('mat-drawer-content');
            const filters: HTMLElement = document.querySelector('.artwork-photo');
            if (filters) {
                filters.style.top = `${container.scrollTop}px`;
            }
        };
        this.element.addEventListener('scroll', this.listener);
    }

    getFavoriteLists(): void {
        this.favoriteService
            .getFavoriteListsForArtwork(this.artwork.artworkId)
            .subscribe((results) => {
                this.favoriteLists = results;
                const favoriteList = this.favoriteLists.find(item => item.artworkId);
                this.isFavorite = Boolean(favoriteList);
            });
    }

    buyNow() {
        if (this.user) {
            this.dialog.open(ArtworkBuyNowComponent, {
                data: {artwork: this.artwork, customer: this.user ? this.user.customer : null},
                panelClass: 'add-to-cart-modal'
            }).afterClosed().subscribe(() => {
                this.emitArtworkChange({artworkId: this.artwork.artworkId});
            });
        } else {
            this.dialog.open(BuyNowUnauthorizedDialogComponent, {
                width: '600px',
                data: {artworkId: this.artwork.artworkId}
            });
        }
    }

    addToCart(): void {
        this.dialog.open(ArtworkAddedComponent, {
            data: {artwork: this.artwork, customer: this.user ? this.user.customer : null},
            panelClass: 'add-to-cart-modal'
        }).afterClosed().subscribe(() => {
            this.emitArtworkChange({artworkId: this.artwork.artworkId});
        });
    }

    deleteCartItem(): void {
        if (!this.user) {
            this.cartItemService.deleteLocalCartItem(this.artwork.artworkId);
            this.emitArtworkChange({artworkId: this.artwork.artworkId});
            this.cartItemService.updateLocalCartItem(this.artwork.artworkId);
        } else {
            this.cartItemService
                .deleteCartItem(this.artwork.cartItem.cartItemId)
                .subscribe(() => {
                    this.emitArtworkChange({artworkId: this.artwork.artworkId});
                    this.cartItemService.updateCartItemsCount(this.artwork.artworkId);
                });
        }
    }

    zoom(pictureName: any): void {
        this.dialog.open(ArtworkZoomComponent, {
            width: '1600px',
            maxWidth: '1600px',
            height: '750px',
            data: {pictureUrl: pictureName},
            panelClass: 'artwork-zoom-modal'
        });
    }

    moreSimilarArtworks() {
        this.router.navigate(['/artwork-list'], {
            queryParams: {
                offset: 0,
                limit: 20,
                styles: this.artwork.styles.map(style => style.styleId).join(','),
                materials: this.artwork.materials.map(material => material.materialId).join(',')
            }
        });
    }

    redirectToOffer() {
        if (this.user) {
            this.router.navigate(['/artwork/offer', this.artwork.artworkId]);
        } else {
            this.dialog.open(OfferUnauthorizedDialogComponent, {
                width: '600px',
                data: {artworkId: this.artwork.artworkId}
            });
        }
    }

    openWishListDialog() {
        if (!this.user) {
            this.dialog.open(WishListUnauthorizedDialogComponent, {
                width: '600px',
                data: {artworkId: this.artwork.artworkId}
            });
            return;
        }

        if (this.user && !this.favoriteLists.length) {
            this.openAddWishListDialog();
            return;
        }
    }

    openAddWishListDialog(): void {
        this.dialog.open(AddWishListDialogComponent, {
            width: '600px',
            data: {
                artworkId: this.artwork.artworkId,
                addToWishList: !this.favoriteLists.length
            }
        }).afterClosed().subscribe((refresh) => {
            if (refresh) {
                this.getFavoriteLists();
            }
        });
    }

    favoriteArtworkChanged(favoriteList: IFavoriteList) {
        if (favoriteList.favoriteArtworkId) {
            this.deleteFavoriteArtwork(favoriteList.favoriteArtworkId);
            return;
        }
        this.addFavoriteArtwork(favoriteList.favoriteListId);
    }

    private addFavoriteArtwork(favoriteListId: string): void {
        this.favoriteService
            .addFavoriteArtwork(favoriteListId, this.artwork.artworkId)
            .subscribe(() => this.getFavoriteLists());
    }

    private deleteFavoriteArtwork(favoriteArtworkId: string): void {
        this.favoriteService
            .deleteFavoriteArtwork(favoriteArtworkId)
            .subscribe(() => this.getFavoriteLists());
    }

    setBannersMode(data: {
        showSingleBannerPromoCode?: boolean,
        showMultipleBannerPromoCodes?: boolean
    }) {
        this.showSingleBannerPromoCode = data.showSingleBannerPromoCode;
        this.showMultipleBannerPromoCodes = data.showMultipleBannerPromoCodes;
    }

    ngOnDestroy(): void {
        this.element.removeEventListener('scroll', this.listener);
    }
}
