import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {MailService} from '../../services/mail.service';
import {CategoryService, UserService, SharedService, ValidatorService} from '../../services';
import {IUser, ICategory} from '../../interfaces';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    user: IUser;
    categories: ICategory[];
    subscribeForm: FormGroup;

    constructor(private categoryService: CategoryService,
                private userService: UserService,
                private sharedService: SharedService,
                private mailService: MailService,
                private validatorService: ValidatorService,
                private fb: FormBuilder) {
        this.subscribeForm = this.fb.group({
            email: ['', [Validators.required, this.validatorService.email]]
        });
    }

    get currentYear() {
        return new Date().getFullYear();
    }

    ngOnInit() {
        this.getNestedCategories();
        this.getProfile();
    }

    subscribe(): void {
        if (this.subscribeForm.invalid) {
            return;
        }

        this.mailService
            .subscribe(this.subscribeForm.getRawValue().email)
            .subscribe();
    }

    getNestedCategories() {
        this.categoryService
            .getNestedCategories()
            .subscribe((categories) => {
                this.categories = categories;
            });

    }

    getProfile() {
        if (!this.sharedService.getToken()) {
            return;
        }
        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
            });
    }
}
