import {Component, Inject, OnInit} from '@angular/core';
import {DonationService} from '../../services/donation.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IDonation} from '../../interfaces';

@Component({
    selector: 'app-edit-donation',
    templateUrl: './edit-donation.component.html',
    styleUrls: ['./edit-donation.component.scss']
})
export class EditDonationComponent implements OnInit {
    donationForm: FormGroup;
    donation: IDonation;

    get amount(): AbstractControl {
        return this.donationForm.get('amount');
    }

    constructor(private dialogRef: MatDialogRef<EditDonationComponent>,
                private donationService: DonationService,
                private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) private data: { donationId: string }) {
        this.createFormGroup();
    }

    ngOnInit(): void {
        this.getDonation();
    }

    updateDonation(): void {
        if (this.donationForm.invalid) {
            return;
        }

        const data: any = this.donationForm.getRawValue();

        this.donationService
            .updateDonation(this.donation.donationId, data)
            .subscribe(() => this.dialogRef.close(true));
    }

    private getDonation(): void {
        this.donationService
            .getDonation(this.data.donationId)
            .subscribe((donation) => {
                this.donation = donation;
                this.donationForm.patchValue(this.donation);
            });
    }

    close(): void {
        this.dialogRef.close();
    }

    private createFormGroup(): void {
        this.donationForm = this.fb.group({
            frequency: ['', [Validators.required]],
            amount: [null, [Validators.required, Validators.min(5), Validators.max(10000)]]
        });
    }

}
