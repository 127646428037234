import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ArtworkRoutingModule} from './artwork-routing.module';
import {ArtworkComponent} from './artwork.component';
import {SharedModule} from '../shared-module/shared.module';
import {ArtworkOfferComponent} from './components/artwork-offer/artwork-offer.component';
import {WishListUnauthorizedDialogComponent} from './dialogs/wish-list-unauthorized-dialog/wish-list-unauthorized-dialog.component';
import {OfferUnauthorizedDialogComponent} from './dialogs/offer-unauthorized-dialog/offer-unauthorized-dialog.component';
import {AddWishListDialogComponent} from './dialogs/add-wish-list-dialog/add-wish-list-dialog.component';
import {BuyNowUnauthorizedDialogComponent} from './dialogs/buy-now-unauthorized-dialog/buy-now-unauthorized-dialog.component'

@NgModule({
    declarations: [
        ArtworkComponent,
        ArtworkOfferComponent,
        WishListUnauthorizedDialogComponent,
        OfferUnauthorizedDialogComponent,
        BuyNowUnauthorizedDialogComponent,
        AddWishListDialogComponent
    ],
    imports: [
        CommonModule,
        ArtworkRoutingModule,
        SharedModule
    ],
    entryComponents: [
    ]
})
export class ArtworkModule {
}
