import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IArtwork} from '../../interfaces';

@Component({
    selector: 'app-home-slide',
    templateUrl: './home-slide.component.html',
    styleUrls: ['./home-slide.component.scss']
})
export class HomeSlideComponent implements OnInit, OnDestroy {
    @Input() sliderArtworks: IArtwork[];
    activeIndex = 0;
    sliderInterval: any;
    showSingleBannerPromoCode: boolean;
    showMultipleBannerPromoCodes: boolean;

    constructor() {
    }

    ngOnInit(): void {
        this.sliderInterval = setInterval(() => {
            this.changePage(++this.activeIndex);
        }, 4500);
    }

    setBannersMode(data: {
        showSingleBannerPromoCode?: boolean,
        showMultipleBannerPromoCodes?: boolean
    }) {
        this.showSingleBannerPromoCode = data.showSingleBannerPromoCode;
        this.showMultipleBannerPromoCodes = data.showMultipleBannerPromoCodes;
    }

    changePage(index: number) {
        if (index < 0) {
            this.activeIndex = this.sliderArtworks.length - 1;
            return;
        }

        if (index > this.sliderArtworks.length - 1) {
            this.activeIndex = 0;
            return;
        }

        this.activeIndex = index;
    }

    scroll(): void {
        const element: HTMLElement = document.getElementById('featured-section');
        element.scrollIntoView();
    }

    ngOnDestroy(): void {
        clearInterval(this.sliderInterval);
    }
}
