import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IArtwork} from '../interfaces';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';
import {SharedService} from './shared.service';
import {CartItemService} from './cart-item.service';

@Injectable({
    providedIn: 'root'
})
export class ArtworkService {

    constructor(private apiService: ApiService,
                private sharedService: SharedService,
                private cartItemService: CartItemService) {
    }

    searchArtworks(params: any): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artworks/search/receive/all', {params}, true);
    }

    getArtistArtworks(artistId: string, params?: any): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>(`artworks/artist/receive/${artistId}`, {params}, true);
    }

    getSliderArtworks(): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artworks/slider/receive/all', {});
    }

    getFeaturedArtworks(): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artworks/featured/receive/all', {});
    }

    getNewArtworks(): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artworks/new/receive/all', {});
    }

    getNewArrivals(): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artworks/newArrivals/receive/all', {});
    }

    getSoldArtworks(params = {}): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artworks/sold/receive/all', {params});
    }

    getArtistSoldArtworks(params: any): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artworks/artist/sold/receive/all', {params}, true);
    }

    getArtwork(artworkId: string, params: any = {}): Observable<IArtwork> {
        return this.apiService.get<IArtwork>(`artworks/receive/${artworkId}`, {params}, true)
            .pipe(
                map(artwork => this.sharedService.formatArtwork(artwork)),
                map((artwork) => {
                    artwork.localCart = this.cartItemService.checkLocalCart(artwork.artworkId);
                    if (artwork.cartItems && artwork.cartItems.length) {
                        artwork.cartItem = artwork.cartItems[0];
                    }
                    return artwork;
                })
            );
    }

    addArtwork(data: IArtwork): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('artworks/add', data, {}, true, true);
    }

    updateArtwork(artworkId: string, data: IArtwork): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artworks/update/${artworkId}`, data, {}, true, true);
    }

    deleteArtwork(artworkId: string): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artworks/delete/${artworkId}`, {}, {}, true, true);
    }
}
