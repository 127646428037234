import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IArtist, IEducation, IExhibition, IUser, ICompany} from '../../shared-module/interfaces';
import regions from '../../../assets/regions';
import {ArtistService, PictureService, UserService, ValidatorService} from '../../shared-module/services';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment-timezone';

import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-artist-review-details',
    templateUrl: './artist-review-details.component.html',
    styleUrls: ['./artist-review-details.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ]
})
export class ArtistReviewDetailsComponent implements OnInit, AfterViewInit {
    user: IUser;
    artist: IArtist;
    company: ICompany;
    exhibitions: IExhibition[] = [];
    educations: IEducation[] = [];
    email: FormControl;
    firstName: FormControl;
    lastName: FormControl;
    gender: FormControl;
    phone: FormControl;
    address: FormControl;
    city: FormControl;
    state: FormControl;
    country: FormControl;
    biographyForm: FormGroup;
    pseudonym: FormControl;
    dateOfBirth: FormControl;
    about: FormControl;
    fromTheArtist: FormControl;
    parentFormSubmitted = false;
    profilePicture: any = {};
    specializations: any[] = [];
    states: any[] = regions;
    selectedState: any;

    constructor(private validatorService: ValidatorService,
                private userService: UserService,
                private artistService: ArtistService,
                private pictureService: PictureService,
                private route: ActivatedRoute,
                private router: Router,
                private fb: FormBuilder) {
    }

    ngOnInit() {
        this.selectedState = this.states[0];
        this.user = this.route.snapshot.data.user;
        this.artist = this.route.snapshot.data.artist;
        this.specializations = this.route.snapshot.data.specializations;
        this.company = this.route.snapshot.data.company;
        this.createFormControls();
        if (this.user.pictureMedium) {
            this.profilePicture.medium = this.user.pictureMedium;
        }
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    chipAutocompleteChanged(event: any[]): void {
        this.artist.specializations = event;
    }

    addEducation(education?: IEducation): void {
        this.educations.push(education || {});
    }

    removeEducation(index: number) {
        this.educations.splice(index, 1);

    }

    addExhibition(exhibition?: IExhibition): void {
        this.exhibitions.push(exhibition || {});
    }

    removeExhibition(index: number) {
        this.exhibitions.splice(index, 1);

    }

    saveArtist(): void {
        this.parentFormSubmitted = true;
        if (!this.biographyForm.valid ||
            (this.educations.length && !this.educations.every(education => education.valid)) ||
            (this.exhibitions.length && !this.exhibitions.every(exhibition => exhibition.valid)) ||
            (!this.profilePicture.file && !this.profilePicture.medium)) {
            return;
        }

        const user: IUser = {
            phone: this.phone.value,
            address: this.address.value,
            city: this.city.value,
            state: this.state.value,
            country: this.country.value,
            gender: this.gender.value
        };

        const artist: IArtist = {
            dateOfBirth: this.dateOfBirth.value.valueOf(),
            specializations: this.artist.specializations.map(specialization => specialization.specializationId)
        };

        if (this.pseudonym.value) {
            artist.pseudonym = this.pseudonym.value;
        }

        if (this.about.value) {
            artist.about = this.about.value;
        }

        if (this.fromTheArtist.value) {
            artist.fromTheArtist = this.fromTheArtist.value;
        }

        if (this.educations.length) {
            artist.educations = this.educations;
        }

        if (this.exhibitions.length) {
            artist.exhibitions = this.exhibitions;
        }

        this.uploadProfilePicture()
            .then((picture) => {
                if (picture) {
                    user.pictureThumbnail = picture.thumbnail;
                    user.pictureSmall = picture.small;
                    user.pictureMedium = picture.medium;
                    user.pictureLarge = picture.large;
                }

                return this.updateProfile(user);
            })
            .then(() => {
                if (this.artist.artistId) {
                    return this.updateArtistProfile(artist);
                }

                return this.addArtistProfile(artist);
            })
            .then(() => {
                this.parentFormSubmitted = false;
                setTimeout(() => {
                    this.router.navigate(['/artist-review/artwork-list']).then();
                }, 0);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private updateProfile(user: IUser): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.userService
                .updateProfile(user)
                .subscribe(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
        });
    }

    private addArtistProfile(artist: IArtist): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.artistService
                .addArtistProfile(artist)
                .subscribe(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
        });
    }

    private updateArtistProfile(artist: IArtist): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.artistService
                .updateArtistProfile(artist)
                .subscribe(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
        });
    }

    private uploadProfilePicture(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.profilePicture.file) {
                return this.pictureService
                    .uploadPicture([this.profilePicture.file])
                    .subscribe((res) => {
                        resolve(res[0]);
                    }, (err) => {
                        reject(err);
                    });
            }

            resolve({
                thumbnail: this.user.pictureThumbnail,
                small: this.user.pictureSmall,
                medium: this.user.pictureMedium,
                large: this.user.pictureLarge
            });
        });
    }

    stateChanged(state: any): void {
        this.selectedState = state;
        this.city.setValue(this.selectedState.cities[0]);
    }

    private createFormControls() {
        this.email = new FormControl(this.user.email);
        this.firstName = new FormControl(this.user.firstName);
        this.lastName = new FormControl(this.user.lastName);
        this.gender = new FormControl(this.user.gender || '');
        this.phone = new FormControl(this.user.phone || '', [
            Validators.required,
            this.validatorService.phone
        ]);
        this.address = new FormControl(this.user.address || '', [
            Validators.required
        ]);
        this.city = new FormControl(this.user.city || this.selectedState.cities[0], [
            Validators.required
        ]);
        this.state = new FormControl(this.user.state || this.selectedState.name, [
            Validators.required
        ]);
        this.country = new FormControl('Armenia', [
            Validators.required
        ]);
        this.pseudonym = new FormControl(this.artist.pseudonym || '', [
            Validators.minLength(2),
            Validators.maxLength(40),
        ]);
        this.dateOfBirth = new FormControl(this.artist.dateOfBirth ? moment(Number(this.artist.dateOfBirth)) : null, [
            Validators.required,
            this.validatorService.minArtistAge
        ]);
        this.about = new FormControl(this.artist.about || '', [
            Validators.minLength(20)
        ]);
        this.fromTheArtist = new FormControl(this.artist.fromTheArtist || '', [
            Validators.minLength(20)
        ]);
        this.createFormGroups();
    }

    private createFormGroups(): void {
        this.biographyForm = this.fb.group({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            gender: this.gender,
            phone: this.phone,
            address: this.address,
            city: this.city,
            state: this.state,
            country: this.country,
            pseudonym: this.pseudonym,
            dateOfBirth: this.dateOfBirth,
            about: this.about,
            fromTheArtist: this.fromTheArtist,
        });

        if (this.artist.educations && this.artist.educations.length) {
            this.artist.educations.forEach((education) => {
                education.valid = true;
                this.addEducation(education);
            });
        }

        if (this.artist.exhibitions && this.artist.exhibitions.length) {
            this.artist.exhibitions.forEach((exhibition) => {
                exhibition.valid = true;
                this.addExhibition(exhibition);
            });
        }
    }

}
