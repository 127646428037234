import {Component, OnInit} from '@angular/core';
import {ArtistService, UserService} from '../../shared-module/services';
import {IArtist, IUser} from '../../shared-module/interfaces';
import {Router} from '@angular/router';

@Component({
    selector: 'app-artist-profile',
    templateUrl: './artist-profile.component.html',
    styleUrls: ['./artist-profile.component.scss']
})
export class ArtistProfileComponent implements OnInit {
    user: IUser;
    artist: IArtist;

    routerLinkIsActive(path: string) {
        return this.router.isActive(path, true);
    }

    constructor(private router: Router,
                private userService: UserService,
                private artistService: ArtistService) {
    }

    ngOnInit() {
        this.getProfile();
        this.getArtist();
    }

    private getProfile() {
        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
            });
    }

    private getArtist(): void {
        this.artistService
            .getArtist()
            .subscribe((artist) => {
                this.artist = artist;
            });
    }

    userChanged(): void {
        this.getProfile();
    }

    artistChanged(): void {
        this.getArtist();
    }
}
