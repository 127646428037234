import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IMaterial, IMedium, IStyle, ISubject} from '../../../shared-module/interfaces';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDrawer} from '@angular/material/sidenav';

@Component({
    selector: 'app-artist-list-filters',
    templateUrl: './artist-list-filters.component.html',
    styleUrls: ['./artist-list-filters.component.scss']
})
export class ArtistListFiltersComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() drawer: MatDrawer;
    @Input() tabletMediaMatcher: MediaQueryList;
    @Input() sidenavActive: boolean;
    @Input() styles: IStyle[] = [];
    @Input() mediums: IMedium[] = [];
    @Input() subjects: ISubject[] = [];
    @Input() materials: IMaterial[] = [];
    selectedStyles: IStyle[] = [];
    selectedMediums: IMedium[] = [];
    selectedSubjects: ISubject[] = [];
    selectedMaterials: IMaterial[] = [];
    sortBy: FormControl;
    firstName: FormControl;
    lastName: FormControl;
    params: any = {};
    element: HTMLElement;
    listener: any;
    containerHeight: number;

    constructor(private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.containerHeight = window.innerHeight;
        this.createFormControls();
        this.route.queryParams
            .subscribe((params) => {
                this.params = {...params};
                if (params.styles) {
                    this.selectedStyles = this.styles.filter(style => params.styles.indexOf(style.styleId) > -1);
                } else {
                    this.selectedStyles = [];
                }

                if (params.subjects) {
                    this.selectedSubjects = this.subjects.filter(subject => params.subjects.indexOf(subject.subjectId) > -1);
                } else {
                    this.selectedSubjects = [];
                }

                if (params.mediums) {
                    this.selectedMediums = this.mediums.filter(medium => params.mediums.indexOf(medium.mediumId) > -1);
                } else {
                    this.selectedMediums = [];
                }

                if (params.materials) {
                    this.selectedMaterials = this.materials.filter(material => params.materials.indexOf(material.materialId) > -1);
                } else {
                    this.selectedMaterials = [];
                }
                this.sortBy.setValue(params.sortBy || '');
                this.firstName.setValue(params.firstName || '');
                this.lastName.setValue(params.lastName || '');
            });
    }

    ngAfterViewInit(): void {
        this.element = document.querySelector('mat-drawer-content');
        this.listener = () => {
            const container = document.querySelector('mat-drawer-content');
            const filters = document.querySelector('.artist-filter');
            filters.setAttribute('style', `margin-top: ${container.scrollTop > 440 ? container.scrollTop - 440 : 0}px; height: ${this.containerHeight}px`);
        };
        this.element.addEventListener('scroll', this.listener);

        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    clearFilters(): void {
        this.router.navigate(['/artist-list'], {queryParams: {offset: this.params.offset, limit: this.params.limit}}).then();
    }

    chipAutocompleteChanged(event: any[], type: string) {
        if (type === 'style') {
            this.selectedStyles = event;
            if (event.length) {
                this.params.styles = event.map(style => style.styleId).join(',');
            } else {
                delete this.params.styles;
            }
        }

        if (type === 'subject') {
            this.selectedSubjects = event;
            if (event.length) {
                this.params.subjects = event.map(subject => subject.subjectId).join(',');
            } else {
                delete this.params.subjects;
            }
        }

        if (type === 'medium') {
            this.selectedMediums = event;
            if (event.length) {
                this.params.mediums = event.map(medium => medium.mediumId).join(',');
            } else {
                delete this.params.mediums;
            }
        }

        if (type === 'material') {
            this.selectedMaterials = event;
            if (event.length) {
                this.params.materials = event.map(material => material.materialId).join(',');
            } else {
                delete this.params.materials;
            }
        }

        this.router.navigate(['/artist-list'], {queryParams: this.params}).then();
    }


    paramsChanged(key: string) {
        const value = this[key].value;
        if (value || value === 0) {
            this.params[key] = value;
        } else {
            delete this.params[key];
        }

        if (key === 'firstName') {
            setTimeout(() => {
                if (value === this.firstName.value) {
                    this.router.navigate(['/artist-list'], {queryParams: this.params}).then();
                }
            }, 2000);
            return;
        }

        if (key === 'lastName') {
            setTimeout(() => {
                if (value === this.lastName.value) {
                    this.router.navigate(['/artist-list'], {queryParams: this.params}).then();
                }
            }, 2000);
            return;
        }
        this.router.navigate(['/artist-list'], {queryParams: this.params}).then();
    }

    private createFormControls() {
        this.sortBy = new FormControl('');
        this.firstName = new FormControl('');
        this.lastName = new FormControl('');
    }

    ngOnDestroy(): void {
        this.element.removeEventListener('scroll', this.listener);
    }
}
