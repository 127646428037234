import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-donation-failed',
  templateUrl: './donation-failed.component.html',
  styleUrls: ['./donation-failed.component.scss']
})
export class DonationFailedComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

}
