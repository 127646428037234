import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared-module/services';

@Component({
    selector: 'app-income-tax-deductible-art',
    templateUrl: './income-tax-deductible-art.component.html',
    styleUrls: ['./income-tax-deductible-art.component.scss']
})
export class IncomeTaxDeductibleArtComponent implements OnInit, AfterViewInit {

    constructor(private sharedService: SharedService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

}
