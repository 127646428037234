import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artist-review-complete',
  templateUrl: './artist-review-complete.component.html',
  styleUrls: ['./artist-review-complete.component.scss']
})
export class ArtistReviewCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
