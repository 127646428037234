import {Component, Input, OnInit} from '@angular/core';
import {IArtist} from '../../interfaces';
import {Router} from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-artist-profile-preview',
    templateUrl: './artist-profile-preview.component.html',
    styleUrls: ['./artist-profile-preview.component.scss']
})
export class ArtistProfilePreviewComponent implements OnInit {
    @Input() artist: IArtist;
    exhibitionTypes: any = {
        group_exhibition: 'Group Exhibition',
        solo_exhibition: 'Solo Exhibition',
        air_fairs: 'Air Fairs'
    };
    editorContent: string;
    fromTheArtistContent: string;
    safeHtml: SafeHtml;
    fromTheArtistHtml: SafeHtml;

    routerLinkIsActive(path: string) {
        return this.router.isActive(path, true);
    }

    constructor(
        private router: Router, 
        private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.editorContent = this.artist.about;
        this.fromTheArtistContent = this.artist.fromTheArtist;
        this.safeHtml = this.updateSafeHtml(this.editorContent);
        this.fromTheArtistHtml = this.updateSafeHtml(this.fromTheArtistContent);
    }

    updateSafeHtml(content: string) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

}
