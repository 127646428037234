import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared-module/shared.module';
import {DonationsRoutingModule} from './donations-routing.module';
import {MakeDonationComponent} from './make-donation/make-donation.component';
import {DonationMadeItemComponent} from './donation-made-item/donation-made-item.component';
import {DonationConfirmedComponent} from './donation-confirmed/donation-confirmed.component';
import {DonationFailedComponent} from './donation-failed/donation-failed.component';
import {DonationAngelsComponent} from './donation-angels/donation-angels.component';
import {DonationsComponent} from './donations.component';
import {DonationLeftSideComponent} from './componenets/donation-left-side/donation-left-side.component';
import { DonationPaypalRedirectComponent } from './donation-paypal-redirect/donation-paypal-redirect.component';

@NgModule({
    declarations: [
        DonationsComponent,
        MakeDonationComponent,
        DonationMadeItemComponent,
        DonationConfirmedComponent,
        DonationFailedComponent,
        DonationAngelsComponent,
        DonationLeftSideComponent,
        DonationPaypalRedirectComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DonationsRoutingModule
    ]
})
export class DonationsModule {
}
