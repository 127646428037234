import {Component, OnInit} from '@angular/core';
import {ArtworkReviewService} from '../../shared-module/services';
import {IArtwork} from '../../shared-module/interfaces';
import {ActivatedRoute} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-artwork-review-list',
    templateUrl: './artwork-review-list.component.html',
    styleUrls: ['./artwork-review-list.component.scss']
})
export class ArtworkReviewListComponent implements OnInit {
    artworks: IArtwork[] = [];
    params: any = {};
    count = 0;

    constructor(private artworkReviewService: ArtworkReviewService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams.pipe(
            tap(params => this.params = params),
            switchMap(() =>  this.artworkReviewService.getArtistArtworks(this.params))
        ).subscribe(({results, count}) => {
            this.artworks = results;
            this.count = count;
        });
    }
}
