import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FilteredLinkRoutingModule} from './filtered-link-routing.module';
import {FilteredLinkComponent} from './filtered-link.component';
import {SharedModule} from '../shared-module/shared.module';

@NgModule({
    declarations: [FilteredLinkComponent],
    imports: [
        CommonModule,
        FilteredLinkRoutingModule,
        SharedModule
    ]
})
export class FilteredLinkModule {
}
