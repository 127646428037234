import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {
    AddressBookService,
    OfferService,
    SharedService,
    ValidatorService
} from '../../../shared-module/services';
import {IAddressBookItem, IArtwork, IOffer, IPaymentMethod, IUser} from '../../../shared-module/interfaces';
import {PaymentMethodService} from '../../../shared-module/services/payment-method.service';
import {MatDialog} from '@angular/material/dialog';
import {SelectAddressBookItemComponent} from '../../../shared-module/dialogs/select-address-book-item/select-address-book-item.component';
import {SelectPaymentMethodComponent} from '../../../shared-module/dialogs/select-payment-method/select-payment-method.component';
import {environment} from '../../../../environments/environment';
import faker from 'faker';

// @ts-ignore
const stripe = window.Stripe(environment.stripePublicKey);

@Component({
    selector: 'app-artwork-offer',
    templateUrl: './artwork-offer.component.html',
    styleUrls: ['./artwork-offer.component.scss']
})
export class ArtworkOfferComponent implements OnInit {
    @Input() user: IUser;
    @Input() artwork: IArtwork;
    paymentMethods: IPaymentMethod[] = [];
    addresses: IAddressBookItem[] = [];
    paymentMethodForm: FormGroup;
    shippingAddressForm: FormGroup;
    selectedPaymentMethod: IPaymentMethod;
    selectedShippingAddress: IAddressBookItem;
    selectedBillingAddress: IAddressBookItem;
    addNewShippingAddress = false;
    addNewBillingAddress = false;
    addNewPaymentMethod = false;
    cardElement: any;
    amount: FormControl;
    sameAsShippingAddress: FormControl;
    step = 1;

    constructor(private fb: FormBuilder,
                private offerService: OfferService,
                private router: Router,
                private paymentMethodService: PaymentMethodService,
                private addressBookService: AddressBookService,
                private dialog: MatDialog,
                private validatorService: ValidatorService,
                private sharedService: SharedService) {
        this.sameAsShippingAddress = new FormControl(false);
        this.amount = new FormControl('', [Validators.required]);
        this.createFormGroup();
    }

    ngOnInit() {
        if (this.user) {
            this.getPaymentMethods();
            this.getAddresses();
        }
    }

    initNewPaymentMethod() {
        const elements = stripe.elements();
        this.addNewPaymentMethod = true;
        this.cardElement = elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    backgroundColor: '#fff',
                    color: '#000',
                    fontFamily: 'Lato, san-serif',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textTransform: 'uppercase'
                }
            }
        });
        this.cardElement.mount('#card-element');
        this.cardElement.on('change', (event) => {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else if(!event.complete) {
                displayError.textContent = 'Card information is required';
            } else {
                displayError.textContent = '';
            }
        });
    }

    private getPaymentMethods(): void {
        this.paymentMethodService
            .getPaymentMethods()
            .subscribe(({results}) => {
                this.paymentMethods = results;
                if (this.paymentMethods.length) {
                    const defaultPaymentMethod = this.paymentMethods.find(item => item.defaultPaymentMethod);
                    if (defaultPaymentMethod) {
                        this.selectedPaymentMethod = defaultPaymentMethod;
                    }
                } else {
                    this.initNewPaymentMethod();
                }
            });
    }

    private getAddresses(): void {
        this.addressBookService
            .getAddresses()
            .subscribe(({results}) => {
                this.addresses = results;
                if (this.addresses.length) {
                    const defaultAddress = this.addresses.find(item => item.defaultAddress);
                    if (defaultAddress) {
                        this.selectedShippingAddress = defaultAddress;
                        this.selectedBillingAddress = defaultAddress;
                    }
                } else {
                    this.addNewShippingAddress = true;
                    this.addNewBillingAddress = true;
                    this.sameAsShippingAddress.setValue(true);
                }
            });
    }

    selectAddress(type: string) {
        this.dialog.open(SelectAddressBookItemComponent, {
            width: '718px',
            // maxHeight: '448px',
            data: {type}
        }).afterClosed().subscribe((addressBookItem) => {
            if (addressBookItem) {
                if (type === 'shipping') {
                    this.addNewShippingAddress = false;
                    this.selectedShippingAddress = addressBookItem;
                }

                if (type === 'billing') {
                    this.addNewBillingAddress = false;
                    this.selectedBillingAddress = addressBookItem;
                }
            }
        });
    }

    selectPaymentMethod() {
        this.dialog.open(SelectPaymentMethodComponent, {
            width: '718px',
            // maxHeight: '448px'
        }).afterClosed().subscribe((paymentMethod) => {
            if (paymentMethod) {
                this.selectedPaymentMethod = paymentMethod;
                this.addNewPaymentMethod = false;
            }
        });
    }

    sendOffer() {
        let isInvalid = false;

        if (this.amount.invalid) {
            this.amount.markAllAsTouched();
            isInvalid = true;
        }
        if (this.addNewShippingAddress && this.shippingAddressForm.invalid) {
            this.shippingAddressForm.markAllAsTouched();
            isInvalid = true;
        }

        if (!this.addNewShippingAddress && !this.selectedShippingAddress) {
            this.shippingAddressForm.markAllAsTouched();
            isInvalid = true;
        }

        if (this.addNewPaymentMethod && (this.paymentMethodForm.invalid || !this.cardElement._complete)) {
            this.paymentMethodForm.markAllAsTouched();
            isInvalid = true;
        }

        if (!this.addNewPaymentMethod && !this.selectedPaymentMethod) {
            this.shippingAddressForm.markAllAsTouched();
            isInvalid = true;
        }

        if (isInvalid) {
            return;
        }

        const offer: IOffer = {
            artworkId: this.artwork.artworkId,
            amount: this.amount.value
        };

        const paymentMethod: IPaymentMethod = {
            cardHolderName: this.paymentMethodForm.get('cardHolderName').value,
            defaultPaymentMethod: this.paymentMethodForm.get('defaultPaymentMethod').value,
            visiblePaymentMethod: this.paymentMethodForm.get('visiblePaymentMethod').value
        };
        if (!paymentMethod.visiblePaymentMethod) {
            paymentMethod.defaultPaymentMethod = false;
        }
        const billingAddress: IAddressBookItem = {
            firstName: this.paymentMethodForm.get('firstName').value,
            lastName: this.paymentMethodForm.get('lastName').value,
            phone: this.paymentMethodForm.get('phone').value,
            secondPhone: this.paymentMethodForm.get('secondPhone').value,
            address: this.paymentMethodForm.get('address').value,
            secondAddress: this.paymentMethodForm.get('secondAddress').value,
            city: this.paymentMethodForm.get('city').value,
            state: this.paymentMethodForm.get('state').value,
            country: this.paymentMethodForm.get('country').value,
            postalCode: this.paymentMethodForm.get('postalCode').value,
            defaultAddress: this.paymentMethodForm.get('defaultAddress').value,
            visibleAddress: this.paymentMethodForm.get('visibleAddress').value
        };
        const shippingAddress: IAddressBookItem = this.shippingAddressForm.getRawValue();
        if (!shippingAddress.visibleAddress) {
            shippingAddress.defaultAddress = false;
        }

        const promises: any[] = [];

        if (this.addNewShippingAddress) {
            promises.push(this.saveAddressBookItem(shippingAddress));
        } else {
            promises.push(Promise.resolve(this.selectedShippingAddress.addressBookItemId));
        }

        if (this.addNewPaymentMethod) {
            if (this.addNewBillingAddress) {
                if (this.sameAsShippingAddress.value) {
                    promises.push(Promise.resolve(null));
                } else {
                    promises.push(this.saveAddressBookItem(billingAddress));
                }
            } else {
                promises.push(Promise.resolve(this.selectedBillingAddress.addressBookItemId));
            }
        } else {
            promises.push(Promise.resolve(this.selectedPaymentMethod.addressBookItem.addressBookItemId));
        }

        Promise.all(promises)
            .then(([shippingAddressId, billingAddressId]) => {
                offer.addressBookItemId = shippingAddressId;

                if (billingAddressId) {
                    paymentMethod.addressBookItemId = billingAddressId;
                } else {
                    paymentMethod.addressBookItemId = shippingAddressId;
                }

                if (this.addNewPaymentMethod) {
                    return this.addPaymentToken(paymentMethod, billingAddress);
                } else {
                    return Promise.resolve(this.selectedPaymentMethod.paymentMethodId);
                }
            })
            .then((paymentMethodId) => {
                offer.paymentMethodId = paymentMethodId;
                return this.saveOffer(offer);
            })
            .then(() => {
                document.querySelector('mat-drawer-content').scrollTo(0, 600);
                this.step = 2;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private saveOffer(offer: IOffer): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.offerService
                .sendOffer(this.artwork.artworkId, offer)
                .subscribe(({id}) => {
                    resolve(id);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private addPaymentToken(paymentMethod: IPaymentMethod, billingAddress: IAddressBookItem): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.sharedService.emitLoaderChange(true);
            const addressBookItem = this.addresses.find(address => address.addressBookItemId === paymentMethod.addressBookItemId) || billingAddress;
            stripe.createToken(this.cardElement, {
                name: paymentMethod.cardHolderName,
                address_line1: addressBookItem.address,
                address_line2: addressBookItem.secondAddress,
                address_city: addressBookItem.city,
                address_state: addressBookItem.state,
                address_zip: addressBookItem.postalCode,
                address_country: addressBookItem.country
            }).then(({token}) => {
                this.sharedService.emitLoaderChange(false);
                paymentMethod.paymentToken = token.id;
                paymentMethod.cardId = token.card.id;
                paymentMethod.cardBrand = token.card.brand;
                paymentMethod.cardLast4 = token.card.last4;
                paymentMethod.cardExpireMonth = token.card.exp_month;
                paymentMethod.cardExpireYear = token.card.exp_year;
                paymentMethod.cardFunding = token.card.funding;
                this.savePaymentMethod(paymentMethod)
                    .then((id => resolve(id)))
                    .catch(err => reject(err));
            }).catch((err) => {
                this.sharedService.emitLoaderChange(false);
                reject(err);
            });
        });
    }

    private savePaymentMethod(paymentMethod: IPaymentMethod): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.paymentMethodService
                .addPaymentMethod(paymentMethod)
                .subscribe(({id}) => {
                    resolve(id);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private saveAddressBookItem(addressBookItem: IAddressBookItem): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.addressBookService
                .addAddressBookItem(addressBookItem)
                .subscribe(({id}) => {
                    resolve(id);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private createFormGroup(): void {
        this.shippingAddressForm = this.fb.group({
            firstName: ['', []],
            lastName: ['', []],
            phone: ['', [
                this.validatorService.phone
            ]],
            secondPhone: ['', [this.validatorService.phone]],
            address: ['', []],
            secondAddress: [''],
            city: ['', []],
            state: ['', []],
            country: ['', []],
            postalCode: ['', []],
            defaultAddress: [true],
            visibleAddress: [true]
        });
        this.paymentMethodForm = this.fb.group({
            cardHolderName: ['', Validators.required],
            firstName: ['', []],
            lastName: ['', []],
            phone: ['', [
                this.validatorService.phone
            ]],
            secondPhone: ['', [this.validatorService.phone]],
            address: ['', []],
            secondAddress: [''],
            city: ['', []],
            state: ['', []],
            country: ['', []],
            postalCode: ['', []],
            defaultPaymentMethod: [true],
            visiblePaymentMethod: [true],
            defaultAddress: [false],
            visibleAddress: [true]
        });

        if (false) {
            this.paymentMethodForm.patchValue({
                cardHolderName: `${faker.name.firstName().toUpperCase()} ${faker.name.lastName().toUpperCase()}`,
                firstName: faker.name.firstName(),
                lastName: faker.name.lastName(),
                phone: faker.phone.phoneNumber()
                    .replace(/-/g, '')
                    .replace(/ /g, '')
                    .replace(/\(/g, '')
                    .replace(/\)/g, '')
                    .replace(/x/g, ''),
                address: faker.address.streetAddress(),
                city: faker.address.city(),
                state: faker.address.state(),
                country: faker.address.country(),
                postalCode: faker.address.zipCode()
            });

            this.shippingAddressForm.patchValue({
                firstName: faker.name.firstName(),
                lastName: faker.name.lastName(),
                phone: faker.phone.phoneNumber()
                    .replace(/-/g, '')
                    .replace(/ /g, '')
                    .replace(/\(/g, '')
                    .replace(/\)/g, '')
                    .replace(/x/g, ''),
                address: faker.address.streetAddress(),
                city: faker.address.city(),
                state: faker.address.state(),
                country: faker.address.country(),
                postalCode: faker.address.zipCode()
            });
        }
    }

    openRegisterPage() {
        this.sharedService.setRedirectUrl(`/artwork/offer/${this.artwork.artworkId}`);
        this.router.navigate(['/auth/register']).then();
    }

    openLoginPage() {
        this.sharedService.setRedirectUrl(`/artwork/offer/${this.artwork.artworkId}`);
        this.router.navigate(['/auth/login']).then();
    }
}
