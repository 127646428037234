import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IGiftCard} from '../interfaces';
import {Observable} from 'rxjs';
import {GiftCardService} from '../services/gift-card.service';

@Injectable({
    providedIn: 'root'
})
export class GiftCardResolverService implements Resolve<IGiftCard> {

    constructor(private giftCardService: GiftCardService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IGiftCard> {
        const giftCardId = route.params.giftCardId;
        return this.giftCardService.getGiftCard(giftCardId);
    }
}
