import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {SharedService, UserService} from '../../shared-module/services';

@Injectable({
    providedIn: 'root'
})
export class CartGuard implements CanActivate {

    constructor(private userService: UserService,
                private sharedService: SharedService,
                private router: Router) {
    }

    canActivate(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (!this.sharedService.getToken()) {
                resolve(false);
                this.router.navigate(['/cart/checkout-unauthorized']).then();
                return;
            }

            this.userService
                .getProfile()
                .subscribe(() => {
                    resolve(true);
                }, () => {
                    resolve(false);
                    this.router.navigate(['/cart/checkout-unauthorized']).then();
                });
        });
    }
}
