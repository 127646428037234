import {AfterViewInit, Component, OnInit} from '@angular/core';
import {tap} from 'rxjs/operators';

import {TeamMemberService} from '../../shared-module/services';
import { Title, Meta } from "@angular/platform-browser";

@Component({
    selector: 'app-our-team',
    templateUrl: './our-team.component.html',
    styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit, AfterViewInit {
    teamMembers: object = {};
    sections = [];

    constructor(private teamMemberService: TeamMemberService,
                private title: Title,
                private meta: Meta) {
    }

    ngOnInit(): void {
        this.title.setTitle("Our Team");
        this.meta.updateTag({ 
            name: 'description',
            content: 'People are what they do, not what they say, not what they think, not what they pretend to be.'
        }); 
        this.teamMemberService.getTeamMembers({})
            .pipe(
                tap((data) => {
                    this.teamMembers = data.results;
                    this.sections = this.teamMembers ? Object.keys(this.teamMembers) : [];
                    this.sortSections();
                })
            )
            .subscribe();
    }

    sortSections() {
        if (this.sections && this.sections.length) {
            this.sections.sort((s1, s2) =>
                Number(this.teamMembers[s1][this.teamMembers[s1].length - 1].order)
                - Number(this.teamMembers[s2][this.teamMembers[s2].length - 1].order)
            );
        }
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }
}
