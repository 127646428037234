import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {UserComponent} from './user.component';
import {AuthGuard} from '../shared-module/guards';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {AddressBookComponent} from './address-book/address-book.component';
import {OrdersHistoryComponent} from './orders-history/orders-history.component';
import {OrderDetailsComponent} from './order-details/order-details.component';
import {OffersHistoryComponent} from './offers-history/offers-history.component';
import {BillingsHistoryComponent} from './billings-history/billings-history.component';
import {BidHistoryComponent} from './bid-history/bid-history.component';
import {FavoriteArtistsComponent} from './favorite-artists/favorite-artists.component';
import {WishListComponent} from './wish-list/wish-list.component';
import {WishListInviteComponent} from './wish-list/wish-list-invite/wish-list-invite.component';
import {PaymentOptionsComponent} from './payment-options/payment-options.component';
import {GiftCardsComponent} from './gift-cards/gift-cards.component';
import {SaveWishListComponent} from './wish-list/save-wish-list/save-wish-list.component';
import {WishListItemComponent} from './wish-list/wish-list-item/wish-list-item.component';
import {ProfileResolverService} from '../shared-module/resolvers';
import {GiftCardOrdersComponent} from './gift-cards/gift-card-orders/gift-card-orders.component';
import {ReceivedGiftCardsComponent} from './gift-cards/received-gift-cards/received-gift-cards.component';
import {UsedGiftCardsComponent} from './gift-cards/used-gift-cards/used-gift-cards.component';
import {DonationsHistoryComponent} from './donations-history/donations-history.component';
import {DonationDetailsComponent} from "./donation-details/donation-details.component";

const routes: Routes = [
    {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: UserProfileComponent
            },
            {
                path: 'address-book',
                component: AddressBookComponent,
            },
            {
                path: 'payment-options',
                component: PaymentOptionsComponent,
            },
            {
                path: 'gift-cards',
                component: GiftCardsComponent,
                children: [
                    {
                        path: 'orders',
                        component: GiftCardOrdersComponent
                    },
                    {
                        path: 'received',
                        component: ReceivedGiftCardsComponent
                    },
                    {
                        path: 'used',
                        component: UsedGiftCardsComponent
                    },
                    { path: '', redirectTo: '/user/gift-cards/orders', pathMatch: 'full'},
                ],
                resolve: {
                    user: ProfileResolverService
                }
            },
            {
                path: 'orders-history',
                component: OrdersHistoryComponent
            },
            {
                path: 'order-details/:orderId',
                component: OrderDetailsComponent,
                resolve: {
                    user: ProfileResolverService
                }
            },
            {
                path: 'offers-history',
                component: OffersHistoryComponent
            },
            {
                path: 'billings-history',
                component: BillingsHistoryComponent
            },
            {
                path: 'bid-history',
                component: BidHistoryComponent
            },
            {
                path: 'favorite-artists',
                component: FavoriteArtistsComponent
            },
            {
                path: 'wish-list',
                component: WishListComponent,
            },
            {
                path: 'wish-list/:favoriteListId',
                component: WishListItemComponent
            },
            {
                path: 'add-wish-list',
                component: SaveWishListComponent
            },
            {
                path: 'update-wish-list/:favoriteListId',
                component: SaveWishListComponent
            },
            {
                path: 'wish-list-invite/:favoriteListId',
                component: WishListInviteComponent
            },
            {
                path: 'donations',
                component: DonationsHistoryComponent
            },
            {
                path: 'donation/:donationId',
                component: DonationDetailsComponent
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserRoutingModule {
}
