import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IFavoriteList} from '../../../shared-module/interfaces';
import {FavoriteService} from '../../../shared-module/services';

@Component({
    selector: 'app-save-wish-list',
    templateUrl: './save-wish-list.component.html',
    styleUrls: ['./save-wish-list.component.scss']
})
export class SaveWishListComponent implements OnInit {
    favoriteList: IFavoriteList;

    constructor(private favoriteService: FavoriteService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        const favoriteListId = this.route.snapshot.params.favoriteListId;

        if (favoriteListId) {
            this.favoriteService
                .getFavoriteList(favoriteListId)
                .subscribe(favoriteList => this.favoriteList = favoriteList)
        } else {
            this.favoriteList = {};
        }
    }

    navigate(): void {
        if(this.favoriteList.favoriteListId) {
            this.router.navigate(['/user/wish-list', this.favoriteList.favoriteListId]);
            return;
        }

        this.router.navigate(['/user/wish-list']);
    }

}
