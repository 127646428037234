import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IColor} from '../interfaces';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';
import ntc from '../../../assets/ntc';

@Injectable({
    providedIn: 'root'
})
export class ColorService {

    constructor(private apiService: ApiService) {
    }

    getColors(params: any): Observable<IApiReceiveResponse<IColor>> {
        return this.apiService.get<IApiReceiveResponse<IColor>>('colors/receive/all', {params}, true)
            .pipe(map((data) => {
                data.results = data.results.map((color) => {
                    color.name = ntc.name(color.code)[1];
                    return color;
                });
                return data;
            }));
    }
}
