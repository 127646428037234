import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AddressBookService, SharedService, UserService, ValidatorService} from '../../shared-module/services';
import {PaymentMethodService} from '../../shared-module/services/payment-method.service';
import {IAddressBookItem, IApiResponse, IDonation, IPaymentMethod, IUser} from '../../shared-module/interfaces';
import faker from 'faker';
import {SelectAddressBookItemComponent} from '../../shared-module/dialogs/select-address-book-item/select-address-book-item.component';
import {SelectPaymentMethodComponent} from '../../shared-module/dialogs/select-payment-method/select-payment-method.component';
import {DonationService} from '../../shared-module/services/donation.service';
import { Title } from "@angular/platform-browser";
// @ts-ignore
const stripe = window.Stripe(environment.stripePublicKey);

@Component({
    selector: 'app-make-donation',
    templateUrl: './make-donation.component.html',
    styleUrls: ['./make-donation.component.scss']
})
export class MakeDonationComponent implements OnInit, AfterViewInit {
    user: IUser;
    donationForm: FormGroup;
    paymentMethods: IPaymentMethod[] = [];
    addresses: IAddressBookItem[] = [];
    paymentMethodForm: FormGroup;
    selectedPaymentMethod: IPaymentMethod;
    selectedBillingAddress: IAddressBookItem;
    addNewBillingAddress = false;
    addNewPaymentMethod = false;
    paymentMethodType: FormControl;
    cardElement: any;
    showOtherAmount = false;
    values: number[] = [5, 10, 25, 50, 100, 200, 500];
    billingAddressControl = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        phone: new FormControl('', [Validators.required, this.validatorService.phone]),
        secondPhone: new FormControl('', this.validatorService.phone),
        address: new FormControl('', Validators.required),
        secondAddress: new FormControl(''),
        city: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required),
        postalCode: new FormControl('', Validators.required),
    });
    isDonationAmount: boolean;

    get amount(): AbstractControl {
        return this.donationForm.get('amount');
    }

    get email(): AbstractControl {
        return this.donationForm.get('email');
    }

    get donationType(): AbstractControl {
        return this.donationForm.get('donationType');
    }

    get isCompany(): AbstractControl {
        return this.donationForm.get('isCompany');
    }

    get companyName(): AbstractControl {
        return this.donationForm.get('companyName');
    }

    get matchingGifts(): AbstractControl {
        return this.donationForm.get('matchingGifts');
    }

    get isTribute(): AbstractControl {
        return this.donationForm.get('isTribute');
    }

    get tributeType(): AbstractControl {
        return this.donationForm.get('tributeType');
    }

    get tributeFirstName(): AbstractControl {
        return this.donationForm.get('tributeFirstName');
    }

    get tributeLastName(): AbstractControl {
        return this.donationForm.get('tributeLastName');
    }

    get isAnonymous(): AbstractControl {
        return this.donationForm.get('isAnonymous');
    }

    get firstName(): AbstractControl {
        return this.donationForm.get('firstName');
    }

    get lastName(): AbstractControl {
        return this.donationForm.get('lastName');
    }

    constructor(private router: Router,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private validatorService: ValidatorService,
                private addressBookService: AddressBookService,
                private paymentMethodService: PaymentMethodService,
                private donationService: DonationService,
                private userService: UserService,
                private sharedService: SharedService,
                private title: Title) {
        this.createFormGroup();
        this.paymentMethodType = new FormControl('credit_card', [Validators.required]);
    }

    ngOnInit(): void {
        this.title.setTitle('Donate')
        this.getProfile();
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    private getProfile(): void {
        if (!this.sharedService.getToken()) {
            this.user = null;
            setTimeout(() => {
                this.initNewPaymentMethod();
            }, 1000);
            this.addNewBillingAddress = true;
            return;
        }

        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
                if (this.user) {
                    this.email.setValue(this.user.email);
                    this.firstName.setValue(this.user.firstName);
                    this.lastName.setValue(this.user.lastName);
                    this.getAddresses();
                    this.getPaymentMethods();
                } else {
                    setTimeout(() => {
                        this.initNewPaymentMethod();
                    }, 1000);
                    this.addNewBillingAddress = true;
                }
            }, () => {
                this.user = null;
                setTimeout(() => {
                    this.initNewPaymentMethod();
                }, 1000);
                this.addNewBillingAddress = true;
            })
    }

    changeAmount(value: number) {
        this.showOtherAmount = false;
        this.amount.setValue(value);
    }

    initNewPaymentMethod() {
        const elements = stripe.elements();
        this.addNewPaymentMethod = true;
        this.cardElement = elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    backgroundColor: '#fff',
                    color: '#000',
                    fontFamily: 'Lato, san-serif',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textTransform: 'uppercase'
                }
            }
        });
        this.cardElement.mount('#card-element');
        this.cardElement.on('change', (event) => {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else if (!event.complete) {
                displayError.textContent = 'Card information is required';
            } else {
                displayError.textContent = '';
            }
        });
    }

    private getPaymentMethods(): void {
        this.paymentMethodService
            .getPaymentMethods()
            .subscribe(({results}) => {
                this.paymentMethods = results;
                if (this.paymentMethods.length) {
                    const defaultPaymentMethod = this.paymentMethods.find(item => item.defaultPaymentMethod);
                    if (defaultPaymentMethod) {
                        this.selectedPaymentMethod = defaultPaymentMethod;
                    }
                } else {
                    setTimeout(() => {
                        this.initNewPaymentMethod();
                    }, 1000);
                }
            });
    }

    private getAddresses(): void {
        this.addressBookService
            .getAddresses()
            .subscribe(({results}) => {
                this.addresses = results;
                if (this.addresses.length) {
                    const defaultAddress = this.addresses.find(item => item.defaultAddress);
                    if (defaultAddress) {
                        this.selectedBillingAddress = defaultAddress;
                    }
                } else {
                    this.addNewBillingAddress = true;
                }
            });
    }

    private addPaymentToken(paymentMethod: IPaymentMethod, billingAddress: IAddressBookItem): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const addressBookItem = this.addresses.find(address => address.addressBookItemId === paymentMethod.addressBookItemId) || billingAddress;
            this.sharedService.emitLoaderChange(true);
            stripe.createToken(this.cardElement, {
                name: paymentMethod.cardHolderName,
                address_line1: addressBookItem.address,
                address_line2: addressBookItem.secondAddress,
                address_city: addressBookItem.city,
                address_state: addressBookItem.state,
                address_zip: addressBookItem.postalCode,
                address_country: addressBookItem.country
            }).then(({token}) => {
                this.sharedService.emitLoaderChange(false);
                paymentMethod.paymentToken = token.id;
                paymentMethod.cardId = token.card.id;
                paymentMethod.cardBrand = token.card.brand;
                paymentMethod.cardLast4 = token.card.last4;
                paymentMethod.cardExpireMonth = token.card.exp_month;
                paymentMethod.cardExpireYear = token.card.exp_year;
                paymentMethod.cardFunding = token.card.funding;
                if (this.user) {
                    this.savePaymentMethod(paymentMethod)
                        .then((id => resolve(id)))
                        .catch(err => reject(err));
                } else {
                    console.log(paymentMethod);
                    resolve(paymentMethod);
                }
            }).catch((err) => {
                this.sharedService.emitLoaderChange(false);
                reject(err);
            });
        });
    }

    private savePaymentMethod(paymentMethod: IPaymentMethod): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.paymentMethodService
                .addPaymentMethod(paymentMethod)
                .subscribe(({id}) => {
                    resolve(id);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private saveAddressBookItem(addressBookItem: IAddressBookItem): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.user) {
                this.addressBookService
                    .addAddressBookItem(addressBookItem)
                    .subscribe(({id}) => {
                        resolve(id);
                    }, (err) => {
                        reject(err);
                    });
            } else {
                console.log(addressBookItem);
                resolve(addressBookItem);
            }
        });
    }

    selectAddress(): void {
        this.dialog.open(SelectAddressBookItemComponent, {
            width: '718px',
            maxHeight: '448px',
            data: {type: 'billing'}
        }).afterClosed().subscribe((addressBookItem) => {
            if (addressBookItem) {
                this.addNewBillingAddress = false;
                this.selectedBillingAddress = addressBookItem;
            }
        });
    }

    selectPaymentMethod(): void {
        this.dialog.open(SelectPaymentMethodComponent, {
            width: '718px',
            maxHeight: '448px'
        }).afterClosed().subscribe((paymentMethod) => {
            if (paymentMethod) {
                this.selectedPaymentMethod = paymentMethod;
                this.addNewPaymentMethod = false;
            }
        });
    }

    donationTypeChanged(): void {
        if (this.donationType.value === 'recurring') {
            this.paymentMethodType.setValue('credit_card');
        }
    }

    isAnonymousChanged(): void {
        if (this.isAnonymous.value) {
            this.isCompany.setValue(false);
        }
    }

    private getDonation(donationId: string): Promise<IDonation> {
        return new Promise<IDonation>((resolve) => {
            if (this.user) {
                return this.donationService
                    .getDonation(donationId)
                    .subscribe(donation => resolve(donation));
            }

            this.donationService
                .getGuestDonation(donationId)
                .subscribe(donation => resolve(donation));
        });
    }

    async onSubmitStripe() {
        const {error} = await stripe.createToken(this.cardElement);

        if (error) {
            console.log('Something is wrong:');
            return;
        }
    }

    donate(): void {
        if(this.donationForm.get('amount').invalid) {
            this.isDonationAmount = true;
        }
        if (this.donationForm.invalid) {
            if(this.addNewBillingAddress && this.billingAddressControl.invalid) {
                this.billingAddressControl.markAllAsTouched();
                this.onSubmitStripe().then();
            }
            this.donationForm.markAllAsTouched();
            this.onSubmitStripe().then();
        }

        if (this.addNewBillingAddress && this.billingAddressControl.invalid && this.paymentMethodType.value !== 'paypal') {
            this.billingAddressControl.markAllAsTouched();
            this.onSubmitStripe().then();
            return;
        }
        this.isDonationAmount = false;

        const donation: IDonation = this.donationForm.getRawValue();
        donation.paymentMethodType = this.paymentMethodType.value;

        if (donation.donationType === 'one_time') {
            delete donation.frequency;
        }

        if (!donation.matchingGifts && !donation.isCompany) {
            delete donation.companyName;
        }

        if (!donation.isTribute) {
            delete donation.tributeFirstName;
            delete donation.tributeLastName;
            delete donation.tributeType;
        }

        if (donation.isAnonymous) {
            delete donation.firstName;
            delete donation.lastName;
        }

        if (donation.isAnonymous && !donation.matchingGifts) {
            delete donation.companyName;
        }

        console.log(donation);

        if (this.paymentMethodType.value === 'paypal') {
            this.saveDonation(donation)
                .then(({message, url, id}) => {
                    if (message === 'donation_added' || message === 'donation_confirmed') {
                        window.location.href = url;
                    }

                    if (message === 'donation_failed') {
                        return this.router.navigate(['/donations/donation-failed', id])
                    }
                })
                .catch(err => console.log(err));
        }

        if (this.paymentMethodType.value === 'credit_card') {
            this.paymentMethodForm.markAsTouched();
            if (this.addNewPaymentMethod && (this.paymentMethodForm.invalid || !this.cardElement._complete)) {
                return;
            }

            if (!this.addNewPaymentMethod && !this.selectedPaymentMethod) {
                return;
            }

            const paymentMethod: IPaymentMethod = {
                cardHolderName: this.paymentMethodForm.get('cardHolderName').value,
                defaultPaymentMethod: this.paymentMethodForm.get('defaultPaymentMethod').value,
                visiblePaymentMethod: this.paymentMethodForm.get('visiblePaymentMethod').value
            };
            if (!paymentMethod.visiblePaymentMethod) {
                paymentMethod.defaultPaymentMethod = false;
            }
            const billingAddress: IAddressBookItem = {
                ...this.billingAddressControl.value,
                defaultAddress: this.paymentMethodForm.get('defaultAddress').value,
                visibleAddress: this.paymentMethodForm.get('visibleAddress').value
            };

            const promises: any[] = [];

            if (this.addNewPaymentMethod) {
                if (this.addNewBillingAddress) {
                    promises.push(this.saveAddressBookItem(billingAddress));
                } else {
                    promises.push(Promise.resolve(this.selectedBillingAddress.addressBookItemId));
                }
            } else {
                promises.push(Promise.resolve(this.selectedPaymentMethod.addressBookItem.addressBookItemId));
            }

            Promise.all(promises)
                .then(([billingAddressId]) => {
                    if (billingAddressId) {
                        if (this.user) {
                            paymentMethod.addressBookItemId = billingAddressId;
                        } else {
                            paymentMethod.addressBookItem = billingAddressId;
                        }
                    }

                    if (this.addNewPaymentMethod) {
                        return this.addPaymentToken(paymentMethod, billingAddress);
                    } else {
                        return Promise.resolve(this.selectedPaymentMethod.paymentMethodId);
                    }
                })
                .then((paymentMethodId) => {
                    if (paymentMethodId) {
                        if (this.user) {
                            donation.paymentMethodId = paymentMethodId;
                        } else {
                            donation.paymentMethod = paymentMethod;
                        }
                    }
                    return this.saveDonation(donation);
                })
                .then(({message, id}) => {
                    if (message === 'donation_confirmed') {
                        return this.router.navigate(['/donations/donation-confirmed', id]);
                    }

                    if (message === 'donation_failed') {
                        return this.router.navigate(['/donations/donation-failed', id]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    private saveDonation(donation: IDonation): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            if (!this.user) {
                return this.donationService
                    .addGuestDonation(donation)
                    .subscribe(data => resolve(data), err => reject(err));
            }

            if (donation.donationType === 'one_time') {
                return this.donationService
                    .addOneTimeDonation(donation)
                    .subscribe(data => resolve(data), err => reject(err));
            }

            if (donation.donationType === 'recurring') {
                return this.donationService
                    .addRecurringDonation(donation)
                    .subscribe(data => resolve(data), err => reject(err));
            }
        });
    }

    private createFormGroup(): void {
        this.donationForm = this.fb.group({
            amount: [null, [Validators.required, Validators.min(5), Validators.max(10000)]],
            donationType: ['one_time', [Validators.required]],
            email: ['', [Validators.required, this.validatorService.email]],
            frequency: [''],
            isCompany: [false],
            matchingGifts: [false],
            companyName: [''],
            isTribute: [false],
            tributeType: ['in_memory_of'],
            tributeFirstName: [''],
            tributeLastName: [''],
            isAnonymous: [false],
            firstName: [''],
            lastName: [''],
        });

        this.paymentMethodForm = this.fb.group({
            cardHolderName: ['', Validators.required],
            defaultPaymentMethod: [true],
            visiblePaymentMethod: [true],
            defaultAddress: [false],
            visibleAddress: [true]
        });

        if (!environment.production) {
            this.paymentMethodForm.patchValue({
                cardHolderName: `${faker.name.firstName().toUpperCase()} ${faker.name.lastName().toUpperCase()}`,
                firstName: faker.name.firstName(),
                lastName: faker.name.lastName(),
                phone: faker.phone.phoneNumber()
                    .replace(/-/g, '')
                    .replace(/ /g, '')
                    .replace(/\(/g, '')
                    .replace(/\)/g, '')
                    .replace(/x/g, ''),
                address: faker.address.streetAddress(),
                city: faker.address.city(),
                state: faker.address.state(),
                country: faker.address.country(),
                postalCode: faker.address.zipCode()
            });
        }
    }
}
