import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {
    ArtworkService,
    FavoriteService,
    ArtistService
} from '../shared-module/services';
import {ActivatedRoute} from '@angular/router';
import {IArtist, IArtwork, IUser} from '../shared-module/interfaces';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-artist-artwork-list',
    templateUrl: './artist-artwork-list.component.html',
    styleUrls: ['./artist-artwork-list.component.scss']
})
export class ArtistArtworkListComponent implements OnInit, AfterViewInit, OnDestroy {
    user: IUser;
    private artistId: string;
    artworks: IArtwork[] = [];
    artist: IArtist;
    similarArtists: IArtist[];
    count: number;
    params: any = {};
    eventTypes: any = {
        group_exhibition: 'Group Exhibition',
        solo_exhibition: 'Solo Exhibition',
        air_fairs: 'Air Fairs'
    };
    element: HTMLElement;
    listener: any;
    editorContent: string;
    fromTheArtistContent: string;
    safeHtml: SafeHtml;
    fromTheArtistHtml: SafeHtml;

    constructor(private route: ActivatedRoute,
                private artworkService: ArtworkService,
                private favoriteService: FavoriteService,
                private artistService: ArtistService,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.user = this.route.snapshot.data.user;
        this.route.queryParams
            .subscribe((params) => {
                this.artistId = this.route.snapshot.params.artistId;
                this.params = {...params};
                this.getArtworks();
            });

        this.route.params
            .subscribe((params) => {
                this.artistId = params.artistId;
                this.getArtist();
            });
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
        this.element = document.querySelector('mat-drawer-content');
        this.listener = () => {
            const container: HTMLElement = document.querySelector('mat-drawer-content');
            const filters: HTMLElement = document.querySelector('.artist-photo');
            filters.style.top = `${container.scrollTop}px`;
        };
        this.element.addEventListener('scroll', this.listener);
    }

    getArtist(): void {
        this.artistService
            .getArtistById(this.artistId)
            .subscribe((artist) => {
                this.artist = artist;
                this.editorContent = this.artist.about;
                this.fromTheArtistContent = this.artist.fromTheArtist;
                this.safeHtml = this.updateSafeHtml(this.editorContent);
                this.fromTheArtistHtml = this.updateSafeHtml(this.fromTheArtistContent);
                this.getArtworks();
                this.getSimilarArtists();
                document.querySelector('mat-drawer-content').scrollTo(0, 0);
            });
    }

    updateSafeHtml(content: string) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

    getArtworks() {
        if (this.user) {
            this.params.customerId = this.user.customer.customerId;
        }
        this.artworkService
            .getArtistArtworks(this.artistId, this.params)
            .subscribe(({results, count}) => {
                this.artworks = results;
                this.count = count;
                // document.querySelector('mat-drawer-content').scrollTo(0, 0);
            });
    }

    filterArtworks(status){
        if(status){
            this.params.status = status; 
        }else{
            delete this.params.status;
        }
        this.getArtworks();
        document.querySelector('mat-drawer-content').scrollTo(0, 950);
    }

    addFavoriteArtist() {
        this.favoriteService
            .addFavoriteArtist(this.artistId)
            .subscribe(() => {
                this.getArtist();
            });
    }

    deleteFavoriteArtist() {
        this.favoriteService
            .deleteFavoriteArtist(this.artist.artistId, this.user.customer.customerId)
            .subscribe(() => {
                this.getArtist();
            });
    }

    getSimilarArtists() {
        const params = {
            artistId: this.artistId,
            offset: 0,
            limit: 3,
            specializations: this.artist.specializations.map(item => item.specializationId).join(',')
        };

        this.artistService
            .getSimilarArtists(params)
            .subscribe((data) => {
                this.similarArtists = data.results;
            });
    }

    ngOnDestroy(): void {
        this.element.removeEventListener('scroll', this.listener);
    }
}
