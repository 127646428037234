import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {SharedService, ValidatorService} from '../../shared-module/services';
import {IGiftCard} from '../../shared-module/interfaces';
import {GiftCardService} from '../../shared-module/services/gift-card.service';
import { Title, Meta } from "@angular/platform-browser";

@Component({
    selector: 'app-buy-gift-card',
    templateUrl: './buy-gift-card.component.html',
    styleUrls: ['./buy-gift-card.component.scss']
})
export class BuyGiftCardComponent implements OnInit, AfterViewInit {
    giftCards: IGiftCard[];
    selectedCard: IGiftCard;
    values: number[] = [50, 75, 100, 125, 150, 175, 200, 250, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 3000, 4000, 5000, 7500, 10000];
    slide: number;
    selectedSlide: any;
    selectedSlideCards: any;
    buyGiftCardForm: FormGroup;

    constructor(private fb: FormBuilder,
                private router: Router,
                private sharedService: SharedService,
                private validatorService: ValidatorService,
                private giftCardService: GiftCardService,
                private title: Title,
                private meta: Meta) {
        this.createFormGroup();
    }

    ngOnInit() {
        this.title.setTitle("Gift Card");
        this.meta.updateTag({ 
            name: 'description',
            content: 'Gift card purchase is 100% income tax deductible for the US citizens. Gift the joy of Armenian art.'
        }); 
        this.giftCardService.getAllGiftCards().subscribe((data) => {
            this.giftCards = data.results;
            this.selectCard(data.results[0].giftCardPictureId);
            this.selectSlide(1);
        });
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    buyGiftCard(): void {
        if (this.buyGiftCardForm.invalid) {
            this.buyGiftCardForm.markAllAsTouched();
            return;
        }

        const giftCard: IGiftCard = this.buyGiftCardForm.getRawValue();
        giftCard.picture = this.selectedCard.picture;
        giftCard.name = this.selectedCard.title;
        giftCard.giftCardPictureId = this.selectedCard.giftCardPictureId;
        if (giftCard.deliveryMethod === 'receive_gift_card') {
            delete giftCard.message;
            delete giftCard.recipientEmail;
            delete giftCard.senderName;
            delete giftCard.recipientName;
        }

        this.sharedService.setGiftCard(giftCard);
        this.router.navigate(['/gift-card/checkout']).then();
    }

    deliveryMethodChanged() {
        if (this.buyGiftCardForm.get('deliveryMethod').value === 'receive_gift_card') {
            this.buyGiftCardForm.get('recipientEmail').setValidators([]);
            this.buyGiftCardForm.get('recipientEmail').setErrors(null);
            this.buyGiftCardForm.get('senderName').setValidators([]);
            this.buyGiftCardForm.get('senderName').setErrors(null);
            this.buyGiftCardForm.get('recipientName').setValidators([]);
            this.buyGiftCardForm.get('recipientName').setErrors(null);
        }

        if (this.buyGiftCardForm.get('deliveryMethod').value === 'send_gift_card') {
            this.buyGiftCardForm.get('recipientEmail').setValidators([this.validatorService.email, Validators.required]);
            this.buyGiftCardForm.get('senderName').setValidators([Validators.required]);
            this.buyGiftCardForm.get('recipientName').setValidators([Validators.required]);
        }
    }

    private createFormGroup(): void {
        this.buyGiftCardForm = this.fb.group({
            amount: [null, [Validators.required]],
            deliveryMethod: ['send_gift_card', [Validators.required]],
            recipientEmail: ['', [this.validatorService.email, Validators.required]],
            senderName: ['', [Validators.required]],
            recipientName: ['', [Validators.required]],
            message: [''],
        });
    }

    selectCard(cardId) {
        this.selectedCard = this.giftCards.find(item => item.giftCardPictureId === cardId);
    }

    selectSlide(slide) {
        this.selectedSlide = slide;
        const giftCards = this.giftCards;
        this.selectedSlideCards = giftCards;
        if (slide === 1) {
            this.selectedSlideCards = giftCards.slice(1, 4);
        }
        if (slide === 2) {
            this.selectedSlideCards = giftCards.slice(4, 7);
        }
    }

}
