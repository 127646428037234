import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IApiReceiveResponse, IArtwork} from '../interfaces';
import ntc from '../../../assets/ntc';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ArtworkReviewService {

    constructor(private apiService: ApiService) {
    }

    getArtistArtworks(params: any = {}): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artwork-review/artist/receive/all', {params}, true);
    }

    getArtistArtwork(artworkId: string): Observable<IArtwork> {
        return this.apiService.get<IArtwork>(`artwork-review/artist/receive/${artworkId}`, {}, true)
            .pipe(
                map((artwork) => {
                    artwork.styles = artwork.artworkStyles.map(artworkStyle => artworkStyle.style);
                    artwork.subjects = artwork.artworkSubjects.map(artworkSubject => artworkSubject.subject);
                    artwork.mediums = artwork.artworkMediums.map(artworkMedium => artworkMedium.medium);
                    artwork.materials = artwork.artworkMaterials.map(artworkMaterial => artworkMaterial.material);
                    artwork.colors = artwork.artworkColors.map(artworkColor => {
                        artworkColor.color.name = ntc.name(artworkColor.color.code)[1];
                        return artworkColor.color;
                    });
                    delete artwork.artworkStyles;
                    delete artwork.artworkSubjects;
                    delete artwork.artworkMediums;
                    delete artwork.artworkMaterials;
                    delete artwork.artworkColors;
                    return artwork;
                }),
            );
    }
}
