import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-back-to-button',
    templateUrl: './back-to-button.component.html',
    styleUrls: ['./back-to-button.component.scss']
})
export class BackToButtonComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    back() {
        window.history.back();
    }

}
