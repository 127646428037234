import {Component, Input} from '@angular/core';

import {ITeamMember} from '../../interfaces';

@Component({
    selector: 'app-team-member-item',
    templateUrl: './team-member-item.component.html',
    styleUrls: ['./team-member-item.component.scss']
})
export class TeamMemberItemComponent {
    @Input() member: ITeamMember;
    @Input() imageSize: number;
    @Input() paddingBottom: number;
    @Input() displayCity: boolean;
    @Input() displayPosition: boolean;
    @Input() showImage: boolean;

}
