import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IDonation} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class DonationService {

    constructor(private apiService: ApiService) {
    }

    getDonationAngels(params: any = {}): Observable<IApiReceiveResponse<IDonation>> {
        return this.apiService.get<IApiReceiveResponse<IDonation>>('donations/angels/receive/all', {params}, true);
    }

    getDonations(params: any = {}): Observable<IApiReceiveResponse<IDonation>> {
        return this.apiService.get<IApiReceiveResponse<IDonation>>('donations/receive/all', {params}, true);
    }

    getDonation(donationId: string): Observable<IDonation> {
        return this.apiService.get<IDonation>(`donations/receive/${donationId}`, {}, true);
    }

    getGuestDonation(donationId: string): Observable<IDonation> {
        return this.apiService.get<IDonation>(`donations/guest/receive/${donationId}`, {}, true);
    }

    addOneTimeDonation(donation: IDonation): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('donations/one-time/add', donation, {}, true);
    }

    addRecurringDonation(donation: IDonation): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('donations/recurring/add', donation, {}, true);
    }

    addGuestDonation(donation: IDonation): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('donations/guest/add', donation, {}, true);
    }

    capturePaypalDonation(donationId: string, data: any = {}): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`donations/capture-paypal-donation/${donationId}`, data, {}, true);
    }

    updateDonation(donationId: string, data: any): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`donations/recurring/update/${donationId}`, data, {}, true, true);
    }

    endDonation(donationId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`donations/recurring/end/${donationId}`, {}, {}, true, true);
    }
}
