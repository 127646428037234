import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    UserService,
    AuthService,
    SharedService,
    CategoryService,
    NavService,
    CartItemService,
    ArtworkService
} from '../../services';
import {IUser, ICategory, IArtwork} from '../../interfaces';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
    animations: [
        trigger(
            'slideView',
            [
                state('true', style({transform: 'translateX(100%)', opacity: 0})),
                state('false', style({transform: 'translateX(0)', opacity: 1})),
                transition('0 => 1', animate('600ms ease-in', style({transform: 'translateX(0)', opacity: 1}))),
                transition('1 => 1', animate('0ms ease-in', style({transform: 'translateX(100%)', opacity: 0}))),
            ]),

        trigger('slideInOut', [
            transition(':enter', [
                style({transform: 'translateX(100%)', opacity: 0}),
                animate('600ms ease-in', style({transform: 'translateX(0%)', opacity: 1}))
            ]),

            transition(':leave', [
                style({transform: 'translateX(0%)', opacity: 1}),
                animate('0ms ease-in', style({transform: 'translateX(100%)', opacity: 0}))
            ])
        ])
    ]
})
export class TopNavComponent implements OnInit {
    @Input() logoStyle: string;
    @Input() textColor: string;
    @Input() showMenu: boolean;
    @Input() toggleMenu: boolean;
    @Input() showUser: boolean;
    @Input() showSwitchBuyer: boolean;
    @Input() showSwitchArtist: boolean;
    @Input() showLang: boolean;
    @Input() showCart: boolean;
    @Input() showCartResponsive: boolean;
    @Input() badgeColor: string;
    @Input() menuLineColor: string;
    @Input() hideMobileMenu: boolean;
    @Input() showMusic: boolean;
    @Input() hideLogo: boolean;
    @Input() positionFixed: boolean;
    user: IUser;
    categories: ICategory[] = [];
    artworks: IArtwork[] = [];
    count = 0;
    total = 0;
    searchOpened = false;
    searchForm: FormGroup;
    navigatingToSearch: boolean;
    _cartItemsCount = 0;
    @ViewChild('searchInput') searchField: any;

    constructor(private userService: UserService,
                private authService: AuthService,
                private router: Router,
                private fb: FormBuilder,
                private sharedService: SharedService,
                private categoryService: CategoryService,
                private cartItemService: CartItemService,
                private artworkService: ArtworkService,
                private navService: NavService) {
        this.searchForm = this.fb.group({
            searchText: ['', Validators.required]
        });

        this.cartItemService.execChange.subscribe((value) => {
            this._cartItemsCount =  value;
        });
    }

    ngOnInit() {
        this.getProfile();
        this.getCategories();
    }

    private getCartItems(): void {
        if (!this.user) {
            const localCartItems = this.cartItemService.getLocalCartItems();
            this.cartItemService.getCartItemsCount(localCartItems.length);
            if (localCartItems.length) {
                Promise
                    .all(localCartItems.map(artworkId => this.getArtwork(artworkId)))
                    .then((artworks) => {
                        this.artworks = artworks;
                        this.count = artworks.length;
                        this.total = this.artworks.reduce((sum, artwork) => sum + artwork.completedPrice, 0);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }else{
                this.artworks = [];
            }

            return;
        }
        this.cartItemService
            .getCartItems()
            .subscribe(({results, count, total}) => {
                this.cartItemService.getCartItemsCount(results.length);
                this.artworks = results;
                this.count = count;
                this.total = total;
            });
    }

    private getArtwork(artworkId: string): Promise<IArtwork> {
        return new Promise<IArtwork>((resolve, reject) => {
            this.artworkService
                .getArtwork(artworkId)
                .subscribe((artwork) => {
                    resolve(artwork);
                }, (err) => {
                    reject(err);
                });
        });
    }

    getProfile() {
        if (!this.sharedService.getToken()) {
            this.user = null;
            this.getCartItems();
            return;
        }
        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
                this.getCartItems();
            });
    }

    logout() {
        this.authService
            .logout()
            .subscribe(() => {
                this.user = null;
                this.sharedService.removeToken();
                this.router.navigate(['/home'])
                    .then(() => window.location.reload());
            });
    }

    getCategories() {
        this.categoryService
            .getNestedCategories()
            .subscribe((categories) => {
                this.categories = categories;
            });

    }

    onToggleSidenav() {
        this.navService.openNav();
    }

    onSearchOpened() {
        if (!this.searchOpened) {
            this.searchOpened = true;
            setTimeout(() => {
                this.searchField.nativeElement.focus();
            }, 400 );

            return;
        }

        if (this.searchOpened && this.searchForm.valid) {
            this.navigatingToSearch = true;
            this.router.navigate(['/search'], {queryParams: this.searchForm.getRawValue()});
        }
    }

    onSearchBlur() {
        setTimeout(() => {
            if (!this.navigatingToSearch) {
                this.searchOpened = false;
                this.searchForm = this.fb.group({
                    searchText: ['', Validators.required]
                });
            }
        }, 500);
    }
}
