import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ArtistListRoutingModule} from './artist-list-routing.module';
import {ArtistListComponent} from './artist-list.component';
import {SharedModule} from '../shared-module/shared.module';
import {ArtistListFiltersComponent} from './components/artist-list-filters/artist-list-filters.component';

@NgModule({
    declarations: [
        ArtistListComponent,
        ArtistListFiltersComponent
    ],
    imports: [
        CommonModule,
        ArtistListRoutingModule,
        SharedModule
    ]
})
export class ArtistListModule {
}
