import {Component, OnInit} from '@angular/core';
import {IPaymentMethod} from '../../interfaces';
import {MatDialogRef} from '@angular/material/dialog';
import {PaymentMethodService} from '../../services/payment-method.service';

@Component({
    selector: 'app-select-payment-method',
    templateUrl: './select-payment-method.component.html',
    styleUrls: ['./select-payment-method.component.scss']
})
export class SelectPaymentMethodComponent implements OnInit {
    paymentMethods: IPaymentMethod[] = [];

    constructor(private dialogRef: MatDialogRef<SelectPaymentMethodComponent>,
                private paymentMethodService: PaymentMethodService) {
    }

    ngOnInit() {
        this.paymentMethodService
            .getPaymentMethods({})
            .subscribe(({results}) => {
                this.paymentMethods = results;
            });
    }

    selectPaymentMethod(addressBookItem) {
        this.dialogRef.close(addressBookItem);
    }

    close() {
        this.dialogRef.close();
    }
}
