import {Injectable} from '@angular/core';
import {IApiReceiveResponse, IApiResponse, IGiftCard} from '../interfaces';
import {Observable} from 'rxjs';

import {ApiService} from './api.service';
import {IGiftCardPicture} from '../interfaces/gift-card';

@Injectable({
    providedIn: 'root'
})
export class GiftCardService {

    constructor(private apiService: ApiService) {
    }

    getGiftCardBalance(): Observable<{ balance: number }> {
        return this.apiService.get<{ balance: number }>('gift-cards/balance', {}, true);
    }

    getGiftCardOrders(params: any): Observable<IApiReceiveResponse<IGiftCard>> {
        return this.apiService.get<IApiReceiveResponse<IGiftCard>>('gift-cards/orders/receive/all', {params}, true);
    }

    getReceivedGiftCards(params: any): Observable<IApiReceiveResponse<IGiftCard>> {
        return this.apiService.get<IApiReceiveResponse<IGiftCard>>('gift-cards/received/receive/all', {params}, true);
    }

    getUsedGiftCards(params: any): Observable<IApiReceiveResponse<IGiftCard>> {
        return this.apiService.get<IApiReceiveResponse<IGiftCard>>('gift-cards/used/receive/all', {params}, true);
    }

    getGiftCard(giftCardId: string): Observable<IGiftCard> {
        return this.apiService.get<IGiftCard>(`gift-cards/receive/${giftCardId}`, {}, true);
    }

    getAllGiftCards(): Observable<IGiftCardPicture> {
        return this.apiService.get<IGiftCardPicture>(`gift-card-pictures/receive/all`, {}, true);
    }

    redeemGiftCard(code: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`gift-cards/redeem`, {code}, {}, true, true);
    }

    addGiftCardOrder(giftCard: IGiftCard): Observable<IApiResponse> {
        console.log(giftCard, 'gift');
        return this.apiService.post<IApiResponse>('gift-cards/order/add', {
            ...giftCard,
            amount: giftCard.amount,
            giftCardPictureId: giftCard.giftCardPictureId,
            name: giftCard.name
        }, {}, true);
    }

    createPaypalGiftCardOrder(giftCardId: string): Observable<{ url: string }> {
        return this.apiService.post<{ url: string }>(`gift-cards/paypal/add/${giftCardId}`, {}, {}, true);
    }

    confirmGiftCardOrder(giftCardId: string): Observable<IApiResponse> {
        console.log(giftCardId, 'giftCardId')
        return this.apiService.post<IApiResponse>(`gift-cards/order/confirm/${giftCardId}`, {}, {}, true);
    }
}
