import {Component, Input, OnInit} from '@angular/core';
import {IArtist} from '../../interfaces';

@Component({
    selector: 'app-artist-item',
    templateUrl: './artist-item.component.html',
    styleUrls: ['./artist-item.component.scss']
})
export class ArtistItemComponent implements OnInit {
    @Input() artist: IArtist;
    @Input() imageSize: number;
    @Input() paddingBottom: number;
    pictureLoaded = false;
    constructor() {
    }

    ngOnInit() {
    }
}
