import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ArtCenterComponent} from './art-center.component';
import {ArtCenterItemComponent} from './art-center-item/art-center-item.component';

const routes: Routes = [
    {
        path: 'mouradick-art-center',
        component: ArtCenterComponent,
    },
    {
        path: 'mouradick-art-center/:artCenterId',
        component: ArtCenterItemComponent
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ArtCenterRoutingModule {
}
