import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IColor} from '../interfaces';
import {ColorService} from '../services';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ColorsResolverService implements Resolve<IColor[]> {

    constructor(private colorService: ColorService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IColor[]> {
        return this.colorService.getColors({}).pipe(map(data => data.results));
    }
}
