import {Component, OnInit} from '@angular/core';
import {IUser} from '../shared-module/interfaces';
import {Router} from '@angular/router';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
    user: IUser;
    orderId: string;

    constructor(public router: Router) {
    }

    ngOnInit() {
        this.orderId = this.router.url.split('/')[3] || '';
    }
}
