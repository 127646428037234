import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-side-menu-subcategories',
    templateUrl: './side-menu-subcategories.component.html',
    styleUrls: ['./side-menu-subcategories.component.scss']
})
export class SideMenuSubcategoriesComponent implements OnInit {
    @Input() category: any;

    constructor() {
    }

    ngOnInit() {
    }
}
