import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, ICategory} from '../interfaces';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    constructor(private apiService: ApiService) {
    }

    getNestedCategories(): Observable<ICategory[]> {
        return this.apiService.get<ICategory[]>('categories/receive/nested', {}, true)
            .pipe(
                map(categories => categories.map(category => ({
                    categoryId: category.categoryId,
                    name: category.name,
                    order: category.order,
                    styles: category.categoryStyles.map(categoryStyle => ({
                        styleId: categoryStyle.style.styleId,
                        name: categoryStyle.style.name,
                        order: categoryStyle.order
                    })),
                    subjects: category.categorySubjects.map(categorySubject => ({
                        subjectId: categorySubject.subject.subjectId,
                        name: categorySubject.subject.name,
                        order: categorySubject.order
                    })),
                    mediums: category.categoryMediums.map(categoryMedium => ({
                        mediumId: categoryMedium.medium.mediumId,
                        name: categoryMedium.medium.name,
                        order: categoryMedium.order
                    }))
                })))
            );
    }

    getCategories(params: any): Observable<IApiReceiveResponse<ICategory>> {
        return this.apiService.get<IApiReceiveResponse<ICategory>>('categories/receive/all', {params}, true);
    }
}
