import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IArtwork} from '../interfaces';
import {ArtworkReviewService} from '../services';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ArtistArtworkResolverService implements Resolve<IArtwork> {

    constructor(private artworkReviewService: ArtworkReviewService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IArtwork> {
        const artworkId = route.params.artworkId;
        return this.artworkReviewService.getArtistArtwork(artworkId);
    }
}
