import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'app-profile-picture-uploader',
    templateUrl: './profile-picture-uploader.component.html',
    styleUrls: ['./profile-picture-uploader.component.scss']
})
export class ProfilePictureUploaderComponent implements OnInit, OnChanges {
    @Input() picture: any;
    @Input() parentFormSubmitted: boolean;
    inputControl: FormControl;
    invalidPicture = false;
    imageChangedEvent: any = '';
    croppedImage: any = '';

    constructor() {
    }

    ngOnInit() {
        this.inputControl = new FormControl('');
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.parentFormSubmitted = changes.parentFormSubmitted.currentValue;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.picture.url = event.base64;
        fetch(this.picture.url)
            .then(res => res.blob())
            .then((file) => {
                this.picture.file = file;
                this.inputControl.setValue(null);
                this.croppedImage = event.base64;
            })
            .catch((err) => {
                console.log(err);
            });
    }
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

}
