import {Component, OnInit} from '@angular/core';
import {IArtist, IUser} from '../shared-module/interfaces';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
    selector: 'app-artist-review',
    templateUrl: './artist-review.component.html',
    styleUrls: ['./artist-review.component.scss']
})
export class ArtistReviewComponent implements OnInit {
    user: IUser;
    artist: IArtist;
    url: string;

    constructor(private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.user = this.route.snapshot.data.user;
        this.artist = this.route.snapshot.data.artist;
        this.url = this.router.url.split('/').slice(0, 3).join('/');
        this.router.events.subscribe(() => {
            this.url = this.router.url.split('/').slice(0, 3).join('/');
        });
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

}
