import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IArtwork} from '../interfaces';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilteredLinkService {

    constructor(private apiService: ApiService) {
    }

    filterArtworks(params: any): Observable<IApiReceiveResponse<IArtwork>> {
      return this.apiService.get<IApiReceiveResponse<IArtwork>>('filteredLink/receive/filtered', {params}, true).pipe(
          map((data) => {
              return data;
          })
      );
    }

    getFilters(): Observable<any> {
      return this.apiService.get<any>('filteredLink/receive/all');
    }

}
