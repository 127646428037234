import {Component, OnInit} from '@angular/core';
import {SearchService} from '../shared-module/services/search.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IArtist, IArtwork, IUser} from '../shared-module/interfaces';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    user: IUser;
    artists: IArtist[] = [];
    artworks: IArtwork[] = [];
    searchForm: FormGroup;
    params: any = {};

    constructor(private searchService: SearchService,
                private router: Router,
                private fb: FormBuilder,
                private route: ActivatedRoute) {
        this.searchForm = this.fb.group({
            searchText: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.user = this.route.snapshot.data.user;
        this.route.queryParams.pipe(
            tap(params => this.params = params),
            switchMap(params => this.searchService.search(params))
        ).subscribe(({artists, artworks}) => {
            this.artists = artists;
            this.artworks = artworks;
            this.searchForm.patchValue(this.params)
        });
    }

    search() {
        if (this.searchForm.invalid) {
            return;
        }

        this.router.navigate(['/search'], {queryParams: this.searchForm.getRawValue()});
    }

}
