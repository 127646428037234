import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared-module/services';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-terms',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit, AfterViewInit {

    constructor(private sharedService: SharedService,
        private title: Title) {
    }

    ngOnInit() {
        this.title.setTitle('Terms And Conditions')
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

}
