import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IArtwork, ICustomer, IUser} from '../../interfaces';
import {Router} from '@angular/router';
import {ArtworkService} from '../../services';
import {FormControl, Validators} from '@angular/forms';
import {PromoCodeService} from '../../../shared-module/services/promo-code.service';

@Component({
    selector: 'app-artwork-buy-now',
    templateUrl: './artwork-buy-now.component.html',
    styleUrls: ['./artwork-buy-now.component.scss']
})
export class ArtworkBuyNowComponent implements OnInit {
    artwork: IArtwork;
    customer: ICustomer;
    user: IUser;
    addNewPromoCode = false;
    discount: number;
    promoCodeId: string;
    promoCode: FormControl;
    total = 0;
    subtotal = 0;

    constructor(private dialogRef: MatDialogRef<ArtworkBuyNowComponent>,
                private router: Router,
                private artworkService: ArtworkService,
                private promoCodeService: PromoCodeService,
                @Inject(MAT_DIALOG_DATA) private data: any) {
                    this.promoCode = new FormControl('', [Validators.required]);
    }

    ngOnInit() {
        this.artwork = this.data.artwork;
        this.customer = this.data.customer;
        this.subtotal = this.artwork.completedPrice;
    }

    checkPromoCode(): void {
        if (!this.promoCode.value) {
            return;
        }
        const params: any = {code: this.promoCode.value};
        if (this.user) {
            params.customerId = this.user.customer.customerId;
        }
        
        this.promoCodeService
            .checkPromoCode(params)
            .subscribe(({valid, id, discount}) => {
                if (valid) {
                    this.promoCodeId = id;
                    this.addNewPromoCode = false;
                    this.discount = discount;
                    this.promoCode.setErrors(null);
                    this.getArtworkPrice();
                } else {
                    this.discount = 0;
                    this.promoCodeId = null;
                    this.promoCode.setErrors({invalid: true});
                    this.getArtworkPrice();
                }
            });

        
    }

    getArtworkPrice(){
        this.subtotal = this.artwork.completedPrice;
        this.total = this.subtotal - ((this.subtotal / 100) * this.discount);
    }

    checkout(){
        this.close();
        setTimeout(() => {
            this.router.navigate(['/cart/checkout'], {queryParams: {promoCode: this.promoCode.value || '', artwork: this.artwork.artworkId}}).then();
        }, 0);
    }

    close(): void {
        this.dialogRef.close();
    }
}
