import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IArtist, IExhibition} from '../../interfaces';
import {ArtistService} from '../../services';

@Component({
  selector: 'app-update-artist-exhibitions',
  templateUrl: './update-artist-exhibitions.component.html',
  styleUrls: ['./update-artist-exhibitions.component.scss']
})
export class UpdateArtistExhibitionsComponent implements OnInit {
    @Input() artist: IArtist;
    @Output() artistChanged = new EventEmitter<void>();
    exhibitions: IExhibition[] = [];
    parentFormSubmitted = false;

    constructor(private artistService: ArtistService) {
    }

    ngOnInit() {
        if (this.artist.exhibitions && this.artist.exhibitions.length) {
            this.artist.exhibitions.forEach((exhibition) => {
                exhibition.valid = true;
                this.addExhibition(exhibition);
            });
        } else {
            this.addExhibition();
        }
    }

    save() {
        this.parentFormSubmitted = true;
        if (!this.exhibitions.every(exhibition => exhibition.valid)) {
            return;
        }

        const data: IArtist = {
            exhibitions: this.exhibitions
        };

        this.artistService
            .updateArtistProfile(data)
            .subscribe(() => {
                this.artistChanged.emit();
                this.parentFormSubmitted = false;
            });
    }

    addExhibition(exhibition?: IExhibition): void {
        this.exhibitions.push(exhibition || {});
    }

    removeExhibition(index: number) {
        this.exhibitions.splice(index, 1);

    }
}
