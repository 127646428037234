import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IArtwork, IGiftCard} from '../interfaces';
import ntc from '../../../assets/ntc';
import {MatSnackBar} from '@angular/material/snack-bar';
import messages from '../../../assets/messages';

const APP_TOKEN = 'APP_WEB_TOKEN';
const APP_GIFT_CARD = 'APP_GIFT_CARD';
const APP_REDIRECT_URL = 'APP_REDIRECT_URL';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    private loaderChanged = new Subject();

    constructor(private snackBar: MatSnackBar) {
    }

    getOrientations() {
        return [
            {
                key: 'portrait',
                name: 'Portrait'
            },
            {
                key: 'landscape',
                name: 'Landscape'
            },
            {
                key: 'square',
                name: 'Square'
            }
        ];
    }

    getSizes() {
        return [
            {
                key: 'small',
                name: 'Small'
            },
            {
                key: 'medium',
                name: 'Medium'
            },
            {
                key: 'large',
                name: 'Large'
            },
            {
                key: 'oversized',
                name: 'Oversize'
            }
        ];
    }

    getToken(): string {
        return localStorage.getItem(APP_TOKEN) || '';
    }

    setToken(token: string): void {
        localStorage.setItem(APP_TOKEN, token);
    }

    removeToken(): void {
        localStorage.removeItem(APP_TOKEN);
    }

    getGiftCard(): IGiftCard {
        const giftCardStr: string = localStorage.getItem(APP_GIFT_CARD) || null;
        return giftCardStr ? JSON.parse(giftCardStr) : null;
    }

    setGiftCard(giftCard: IGiftCard): void {
        localStorage.setItem(APP_GIFT_CARD, JSON.stringify(giftCard));
    }

    removeGiftCard(): void {
        localStorage.removeItem(APP_GIFT_CARD);
    }

    getRedirectUrl(): string {
        return localStorage.getItem(APP_REDIRECT_URL) || null;
    }

    setRedirectUrl(redirectUrl: string): void {
        localStorage.setItem(APP_REDIRECT_URL, redirectUrl);
    }

    removeRedirectUrl(): void {
        localStorage.removeItem(APP_REDIRECT_URL);
    }

    emitLoaderChange(loading: boolean) {
        this.loaderChanged.next(loading);
    }

    onLoaderChange(): Observable<any> {
        return this.loaderChanged.asObservable();
    }

    getParameterByName(name: string, url?: string) {
        url = url || window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results) {
            return null;
        }

        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    formatArtwork(artwork: any): IArtwork {
        artwork.styles = artwork.artworkStyles.map(artworkStyle => artworkStyle.style);
        artwork.subjects = artwork.artworkSubjects.map(artworkSubject => artworkSubject.subject);
        artwork.mediums = artwork.artworkMediums.map(artworkMedium => artworkMedium.medium);
        artwork.materials = artwork.artworkMaterials.map(artworkMaterial => artworkMaterial.material);
        artwork.colors = artwork.artworkColors.map(artworkColor => {
            artworkColor.color.name = ntc.name(artworkColor.color.code)[1];
            return artworkColor.color;
        });
        delete artwork.artworkStyles;
        delete artwork.artworkSubjects;
        delete artwork.artworkMediums;
        delete artwork.artworkMaterials;
        delete artwork.artworkColors;
        return artwork;
    }

    showNotification(message: string, type: string) {
        this.snackBar.open(messages[message] || message, 'x', {
            duration: type === 'error' ? 10000 : 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: type
        });
    }

    copy(value: string) {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.snackBar.open('Copied to clipboard', 'x', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: 'success'
        });
    }
}
