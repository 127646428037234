import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiService} from './api.service';
import {IApiReceiveResponse, IArtist, IBanner} from '../interfaces';
import {shareReplay} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BannerService {

    private readonly promoCodesBanner$: Observable<IApiReceiveResponse<IBanner>>;

    constructor(private apiService: ApiService) {
        this.promoCodesBanner$ = this.apiService.get<IApiReceiveResponse<IBanner>>('promo-codes/receive/banner', {}, true).pipe(shareReplay(1));
    }

    getBanners(): Observable<IApiReceiveResponse<IBanner>> {
        return this.promoCodesBanner$;
    }
}
