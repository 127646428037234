import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {IPicture, ICompany} from '../../interfaces';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-artwork-picture-uploader',
    templateUrl: './artwork-picture-uploader.component.html',
    styleUrls: ['./artwork-picture-uploader.component.scss']
})
export class ArtworkPictureUploaderComponent implements OnInit, OnChanges {
    @Input() pictures: IPicture[];
    @Input() company: ICompany;
    @Input() parentFormSubmitted: boolean;
    @Output() pictureChanged = new EventEmitter<void>();
    filteredPictures: IPicture[] = [];
    invalidPicture = false;
    inputControl: FormControl;

    constructor() {
    }

    ngOnInit() {
        this.inputControl = new FormControl('');
        this.checkPictures();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.parentFormSubmitted = changes.parentFormSubmitted.currentValue;
        this.checkPictures();
    }

    onSelectFiles(event) {
        if (event.target.files) {
            const files = event.target.files;
            const promises = [];
            Object.keys(files).forEach((index) => {
                let item = null;
                if (this.pictures.length) {
                    item = this.pictures.find(picture => picture.file && picture.file.name === files[index].name);
                }

                if (!item) {
                    promises.push(this.readAsDataURL(files[index]));
                }
            });

            Promise
                .all(promises)
                .then(() => {
                    this.inputControl.setValue(null);
                    this.pictureChanged.emit();
                    this.checkPictures();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    readAsDataURL(item: any): Promise<void> {
        return new Promise<any>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(item);
            reader.onload = (file: any) => {
                const img = new Image();
                img.onload = (data: any) => {
                    let width = 0;
                    let height = 0;
                    if (data.path && data.path[0]) {
                        width = data.path[0].width;
                        height = data.path[0].height;
                    }

                    if (data.target) {
                        width = data.target.width;
                        height = data.target.height;
                    }
                    const size = Math.floor(item.size / 1000);

                    if (size <= (this.company.maxArtworkSize * 1000)) {
                        if (width >= this.company.minPictureResolution || height >= this.company.minPictureResolution) {
                            this.pictures.push({
                                url: file.target.result,
                                file: item,
                                added: true
                            });
                        } else {
                            this.showError();
                        }

                        resolve();
                    } else {
                        this.showError();
                        resolve();
                    }
                };
                img.src = file.target.result;
            };
        });
    }

    onDelete(pictureIndex) {
        if (this.pictures[pictureIndex].pictureId) {
            this.pictures[pictureIndex].deleted = true;
        } else {
            this.pictures.splice(pictureIndex, 1);
        }

        this.pictureChanged.emit();
        this.checkPictures();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.pictures, event.previousIndex, event.currentIndex);
        this.pictureChanged.emit();
    }

    checkPictures(): void {
        this.filteredPictures = this.pictures.filter(picture => !picture.deleted);
    }

    private showError(): void {
        this.invalidPicture = true;
        setTimeout(() => {
            this.invalidPicture = false;
        }, 5000);
    }

}
