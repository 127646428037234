import {Component, Input, OnInit} from '@angular/core';
import {IUser} from '../../interfaces';

@Component({
    selector: 'app-profile-preview',
    templateUrl: './profile-preview.component.html',
    styleUrls: ['./profile-preview.component.scss']
})
export class ProfilePreviewComponent implements OnInit {
    @Input() user: IUser;

    constructor() {
    }

    ngOnInit() {
    }
}
