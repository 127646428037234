import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IAddressBookItem} from '../../interfaces';
import {ValidatorService, AddressBookService} from '../../services';
import faker from 'faker';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {
    @Input() addressBookItem: IAddressBookItem;
    @Output() changed = new EventEmitter<string>();
    addressBookForm: FormGroup;

    constructor(private fb: FormBuilder,
                private addressBookService: AddressBookService,
                private validatorService: ValidatorService) {
        this.createFormGroup();
    }

    ngOnInit() {
        if (!this.addressBookItem && !environment.production) {
            const addressBookItem: IAddressBookItem = {};
            addressBookItem.firstName = faker.name.firstName();
            addressBookItem.lastName = faker.name.lastName();
            addressBookItem.phone = faker.phone.phoneNumber().replace(/[^\d]/g, '');
            addressBookItem.address = faker.address.streetAddress();
            addressBookItem.city = faker.address.city();
            addressBookItem.state = faker.address.state();
            addressBookItem.country = faker.address.country();
            addressBookItem.postalCode = faker.address.zipCode();
            this.addressBookForm.patchValue(addressBookItem);
        }

        if (this.addressBookItem && this.addressBookItem.addressBookItemId) {
            this.addressBookItem.phone = this.addressBookItem.phone.replace(/[^\d]/g, '');

            if (this.addressBookItem.secondPhone) {
                this.addressBookItem.secondPhone = this.addressBookItem.secondPhone.replace(/[^\d]/g, '');
            }
            this.addressBookForm.patchValue(this.addressBookItem);
        }
    }

    inputPhone() {
        this.addressBookForm.get('phone').setValue(this.addressBookForm.get('phone').value.replace(/[^\d]/g, ''));
    }

    inputSecondPhone() {
        this.addressBookForm.get('secondPhone').setValue(this.addressBookForm.get('secondPhone').value.replace(/[^\d]/g, ''));
    }

    saveAddressBook() {
        if (!this.addressBookForm.valid) {
            return;
        }

        const values: IAddressBookItem = this.addressBookForm.getRawValue();

        if (this.addressBookForm.get('secondAddress').value) {
            values.secondAddress = this.addressBookForm.get('secondAddress').value;
        } else {
            delete values.secondAddress;
        }

        if (this.addressBookForm.get('secondPhone').value) {
            values.secondPhone = this.addressBookForm.get('secondPhone').value.replace(/[^\d]/g, '');
        } else {
            delete values.secondPhone;
        }

        values.phone = values.phone.replace(/[^\d]/g, '');

        if (this.addressBookItem && this.addressBookItem.addressBookItemId) {
            return this.addressBookService
                .updateAddressBookItem(this.addressBookItem.addressBookItemId, values)
                .subscribe((res) => {
                    this.changed.emit(res.id);
                });
        }

        this.addressBookService
            .addAddressBookItem(values)
            .subscribe((res) => {
                this.changed.emit(res.id);
            });
    }

    private createFormGroup() {
        this.addressBookForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            phone: ['', [
                Validators.required,
                this.validatorService.phone
            ]],
            secondPhone: ['', [this.validatorService.phone]],
            address: ['', [Validators.required]],
            secondAddress: [''],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            country: ['', [Validators.required]],
            postalCode: ['', [Validators.required]],
            defaultAddress: [true],
            visibleAddress: [true]
        });
    }
}
