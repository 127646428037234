import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ArtistService} from '../shared-module/services';
import {
    IArtist,
    IStyle,
    IMedium,
    ISubject,
    IMaterial
} from '../shared-module/interfaces';
import {MediaMatcher} from '@angular/cdk/layout';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-artist-list',
    templateUrl: './artist-list.component.html',
    styleUrls: ['./artist-list.component.scss']
})
export class ArtistListComponent implements OnInit, AfterViewInit, OnDestroy {
    artists: IArtist[] = [];
    count = 0;
    styles: IStyle[] = [];
    mediums: IMedium[] = [];
    subjects: ISubject[] = [];
    materials: IMaterial[] = [];
    params: any = {};
    sidenavActive = true;
    tabletMediaMatcher: MediaQueryList;
    element: HTMLElement;
    listener: any;

    constructor(private route: ActivatedRoute,
                private mediaMatcher: MediaMatcher,
                private artistService: ArtistService,
                private title: Title) {
        this.tabletMediaMatcher = mediaMatcher.matchMedia('(max-width: 1160px)');
        this.sidenavActive = !this.tabletMediaMatcher.matches;
        this.tabletMediaMatcher.onchange = () => {
            this.sidenavActive = !this.tabletMediaMatcher.matches;
        };
    }

    ngOnInit() {
        this.title.setTitle('Our Artists');
        this.styles = this.route.snapshot.data.styles;
        this.subjects = this.route.snapshot.data.subjects;
        this.mediums = this.route.snapshot.data.mediums;
        this.materials = this.route.snapshot.data.materials;
        this.route.queryParams
            .subscribe((params) => {
                this.params = {...params};
                this.getArtists();
            });
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
        this.element = document.querySelector('mat-drawer-content');
        this.listener = () => {
            const container = document.querySelector('mat-drawer-content');
            const filters = document.querySelector('.vertical-toggle-container');
            filters.setAttribute('style', `top: ${container.scrollTop > 460 ? container.scrollTop - 380 : 80}px; display: ${this.sidenavActive ? 'none' : 'block'}`);
        };
        this.element.addEventListener('scroll', this.listener);

        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }


    getArtists() {
        this.artists = [];
        this.artistService
            .searchArtists(this.params)
            .subscribe(({results, count}) => {
                this.artists = results;
                this.count = count;
                document.querySelector('mat-drawer-content').scrollTo(0, 0);
            });
    }

    ngOnDestroy(): void {
        this.element.removeEventListener('scroll', this.listener);
    }
}
