import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CartComponent} from './cart.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {OrderReviewComponent} from './order-review/order-review.component';
import {OrderConfirmationComponent} from './order-confirmation/order-confirmation.component';
import {CheckoutUnauthorizedComponent} from './checkout-unauthorized/checkout-unauthorized.component';
import {CartGuard} from './guards/cart.guard';
import {ProfileResolverService} from '../shared-module/resolvers';

const routes: Routes = [
    {
        path: 'cart',
        component: CartComponent,
        children: [
            {
                path: 'shopping-cart',
                component: ShoppingCartComponent
            },
            {
                path: 'checkout-unauthorized',
                component: CheckoutUnauthorizedComponent
            },
            {
                path: 'checkout',
                component: CheckoutComponent,
                canActivate: [CartGuard],
                resolve: {
                    user: ProfileResolverService
                }
            },
            {
                path: 'order-review/:orderId',
                component: OrderReviewComponent,
                canActivate: [CartGuard]
            },
            {
                path: 'order-confirmation/:orderId',
                component: OrderConfirmationComponent,
                canActivate: [CartGuard]
            },
            { path: '', redirectTo: '/cart/shopping-cart', pathMatch: 'full'},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CartRoutingModule {
}
