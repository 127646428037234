import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ArtworkComponent} from './artwork.component';
import {ColorsResolverService} from '../shared-module/resolvers';
import {AuthGuard} from '../shared-module/guards';

const routes: Routes = [
    {
        path: 'artwork/:artworkId',
        component: ArtworkComponent,
        resolve: {
            colors: ColorsResolverService
        }
    },
    {
        path: 'artwork/offer/:artworkId',
        component: ArtworkComponent,
        canActivate: [AuthGuard],
        resolve: {
            colors: ColorsResolverService
        }
    },
    {
        path: 'artwork/favorite/:artworkId',
        component: ArtworkComponent,
        resolve: {
            colors: ColorsResolverService
        }
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArtworkRoutingModule { }
