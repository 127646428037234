import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IDonation, IUser} from '../../shared-module/interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {DonationService} from '../../shared-module/services/donation.service';
import {SharedService, UserService} from '../../shared-module/services';

@Component({
    selector: 'app-donation-paypal-redirect',
    templateUrl: './donation-paypal-redirect.component.html',
    styleUrls: ['./donation-paypal-redirect.component.scss']
})
export class DonationPaypalRedirectComponent implements OnInit, AfterViewInit {
    user: IUser;
    donation: IDonation;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private userService: UserService,
                private sharedService: SharedService,
                private donationService: DonationService) {
    }

    ngOnInit(): void {
        this.getProfile();
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    private getProfile(): void {
        if (!this.sharedService.getToken()) {
            this.user = null;
            this.getDonation();
            return;
        }

        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
                this.getDonation();
            });
    }

    private getDonation(): void {
        const donationId: string = this.route.snapshot.params.donationId;
        if (this.user) {
            this.donationService
                .getDonation(donationId)
                .subscribe(donation => {
                    this.donation = donation;
                    this.capturePaypalDonation();
                });
            return;
        }

        this.donationService
            .getGuestDonation(donationId)
            .subscribe(donation => {
                this.donation = donation;
                this.capturePaypalDonation();
            });
    }

    capturePaypalDonation(): void {
        const data: any = {paypalBuyerId: this.route.snapshot.queryParams.PayerID};

        if (this.donation.status !== 'pending' || this.donation.paymentMethodType !== 'paypal' || !data.paypalBuyerId) {
            this.router.navigate(['/donations/donation-failed', this.donation.donationId]).then();
            return;
        }

        this.donationService
            .capturePaypalDonation(this.donation.donationId, data)
            .subscribe(({message}) => {
                if (message === 'donation_confirmed') {
                    this.router.navigate(['/donations/donation-confirmed', this.donation.donationId]).then()
                }

                if (message === 'donation_failed') {
                    this.router.navigate(['/donations/donation-failed', this.donation.donationId]).then()
                }
            }, () => this.router.navigate(['/donations/donation-failed', this.donation.donationId]).then());
    }

}
