import {Component, OnInit} from '@angular/core';
import {FavoriteService} from '../../shared-module/services';
import {IFavoriteArtist} from '../../shared-module/interfaces';

@Component({
    selector: 'app-favorite-artists',
    templateUrl: './favorite-artists.component.html',
    styleUrls: ['./favorite-artists.component.scss']
})
export class FavoriteArtistsComponent implements OnInit {
    favoriteArtists: IFavoriteArtist[] = [];
    count = 0;
    constructor(private favoriteService: FavoriteService) {
    }

    ngOnInit() {
        this.getFavoriteArtists();
    }

    getFavoriteArtists() {
        this.favoriteService
            .getFavoriteArtists()
            .subscribe(({ results, count }) => {
                this.favoriteArtists = results;
                this.count = count;
            });
    }

    deleteFavoriteArtist(artistFavorite: IFavoriteArtist) {
        this.favoriteService
            .deleteFavoriteArtist(artistFavorite.artistId, artistFavorite.customerId)
            .subscribe((res) => {
                this.getFavoriteArtists();
            });
    }

}
