import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidatorService} from '../../services';
import {MailService} from '../../services/mail.service';

@Component({
    selector: 'app-promo-code',
    templateUrl: './promo-code.component.html',
    styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit {
    subscribeForm: FormGroup;
    stopAnimation: boolean = false;
    constructor(private matDialogRef: MatDialogRef<PromoCodeComponent>,
                private mailService: MailService,
                private validatorService: ValidatorService,
                private fb: FormBuilder) {
        this.subscribeForm = this.fb.group({
            email: ['', [Validators.required, this.validatorService.email]]
        });
    }

    ngOnInit() {
        setTimeout(() => {
            if(this.stopAnimation === false){
                const wrapper = document.getElementsByClassName(
                    "mat-dialog-container"
                )[0];

                const animation = wrapper.animate({ left: "-500px" }, {
                    duration: 600,
                });

                animation.onfinish = () => {
                    this.matDialogRef.addPanelClass('slide-out')
                    this.matDialogRef.close();
                }
            }
        }, 8000);
    }

    pauseAnimation() {  
        this.stopAnimation = true;
    }

    subscribe(): void {
        if (this.subscribeForm.invalid) {
            return;
        }

        this.mailService
            .subscribe(this.subscribeForm.getRawValue().email)
            .subscribe(() => {
                this.close();
            });
    }

    close() {
        this.matDialogRef.close();
    }

}
