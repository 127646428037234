import {NgModule} from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {LayoutModule} from '@angular/cdk/layout';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RequestInterceptorService} from './services';
import {ArtworkAddedComponent, ArtworkBuyNowComponent, ArtworkZoomComponent, RemoveOfferComponent} from './modals';
import {OrderModule} from 'ngx-order-pipe';
import {PicturePipe, TextPipe, TitlePipe} from './pipes';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {
    AddressBookPreviewComponent,
    AddressFormComponent,
    ArtistItemComponent,
    ArtistProfilePreviewComponent,
    ArtworkItemComponent,
    ArtworkPictureUploaderComponent,
    ArtworkRoomComponent,
    ArtworksListComponent,
    BackgroundSectionComponent,
    BackToButtonComponent,
    VerticalButtonComponent,
    ChipAutocompleteComponent,
    EducationFormComponent,
    ExhibitionFormComponent,
    FavoriteListFormComponent,
    FooterComponent,
    FooterSecondComponent,
    HomeSlideComponent,
    MasonryListComponent,
    MusicButtonToolbarComponent,
    PageLoaderComponent,
    PaginationComponent,
    ProfilePictureUploaderComponent,
    ProfilePreviewComponent,
    SideMenuSubcategoriesComponent,
    SocialLoginComponent,
    SocialShareComponent,
    TeamMemberItemComponent,
    TopNavComponent,
    UpdateArtistProfileComponent,
    UpdatePasswordComponent,
    UpdateProfileComponent,
    PromoCodesBannerComponent,
    FilteredLinkBannerComponent
} from './components';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {PictureLoadDirective} from './directives/picture-load.directive';
import {PromoCodeComponent} from './components/promo-code/promo-code.component';
import {InputEventDirective} from './directives/input-event.directive';
import {UpdateArtistEducationsComponent} from './components/update-artist-educations/update-artist-educations.component';
import {UpdateArtistExhibitionsComponent} from './components/update-artist-exhibitions/update-artist-exhibitions.component';
import {PaymentMethodFormComponent} from './components/payment-method-form/payment-method-form.component';
import {PaymentMethodPreviewComponent} from './components/payment-method-preview/payment-method-preview.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatStepperModule} from '@angular/material/stepper';
import {MatNativeDateModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SelectAddressBookItemComponent} from './dialogs/select-address-book-item/select-address-book-item.component';
import {SelectPaymentMethodComponent} from './dialogs/select-payment-method/select-payment-method.component';
import {RedeemGiftCardComponent} from './dialogs/redeem-gift-card/redeem-gift-card.component';
import {DimensionUnitPipe} from './pipes/dimension-unit.pipe';
import {MusicRoomComponent} from '../static-pages-module/music-room/music-room.component';
import {EditDonationComponent} from './dialogs/edit-donation/edit-donation.component';
import { BillingAddressComponent } from './components/billing-address/billing-address.component';
import { FateModule, FateMaterialModule } from 'fate-editor';
@NgModule({
    declarations: [
        // components
        UpdateArtistEducationsComponent,
        UpdateArtistExhibitionsComponent,
        PaginationComponent,
        AddressBookPreviewComponent,
        AddressFormComponent,
        ArtistItemComponent,
        ArtistProfilePreviewComponent,
        ArtworkItemComponent,
        ArtworkPictureUploaderComponent,
        ArtworksListComponent,
        BackToButtonComponent,
        VerticalButtonComponent,
        BackgroundSectionComponent,
        ChipAutocompleteComponent,
        EducationFormComponent,
        ExhibitionFormComponent,
        FavoriteListFormComponent,
        FooterComponent,
        FooterSecondComponent,
        HomeSlideComponent,
        MasonryListComponent,
        PageLoaderComponent,
        ProfilePictureUploaderComponent,
        ProfilePreviewComponent,
        SideMenuSubcategoriesComponent,
        SocialLoginComponent,
        SocialShareComponent,
        TopNavComponent,
        UpdateArtistProfileComponent,
        UpdatePasswordComponent,
        UpdateProfileComponent,
        ArtworkAddedComponent,
        ArtworkBuyNowComponent,
        ArtworkZoomComponent,
        RemoveOfferComponent,
        TextPipe,
        PicturePipe,
        DimensionUnitPipe,
        TitlePipe,
        ArtworkRoomComponent,
        // directives
        PictureLoadDirective,
        InputEventDirective,
        PromoCodeComponent,
        UpdateArtistEducationsComponent,
        UpdateArtistExhibitionsComponent,
        PaymentMethodFormComponent,
        PaymentMethodPreviewComponent,
        SelectAddressBookItemComponent,
        SelectPaymentMethodComponent,
        RedeemGiftCardComponent,
        MusicRoomComponent,
        MusicButtonToolbarComponent,
        EditDonationComponent,
        BillingAddressComponent,
        TeamMemberItemComponent,
        PromoCodesBannerComponent,
        FilteredLinkBannerComponent
    ],
    providers: [
        // interceptors
        {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true},
    ],
    imports: [
        CommonModule,
        OverlayModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatIconModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatListModule,
        MatMenuModule,
        MatTableModule,
        MatDialogModule,
        MatSelectModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatBottomSheetModule,
        MatCardModule,
        MatDatepickerModule,
        MatStepperModule,
        MatNativeDateModule,
        MatRadioModule,
        MatBadgeModule,
        MatSidenavModule,
        RouterModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        FilterPipeModule,
        LayoutModule,
        DragDropModule,
        OrderModule,
        NgxImageZoomModule,
        MatTooltipModule,
        ImageCropperModule,
        MatSnackBarModule,
        ScrollToModule,
        FateModule,
        FateMaterialModule
    ],
    exports: [
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatListModule,
        MatMenuModule,
        MatTableModule,
        MatDialogModule,
        MatSelectModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatBottomSheetModule,
        MatCardModule,
        MatStepperModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatBadgeModule,
        MatSidenavModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatAutocompleteModule,
        FilterPipeModule,
        LayoutModule,
        DragDropModule,
        ArtworkAddedComponent,
        ArtworkBuyNowComponent,
        ArtworkZoomComponent,
        RemoveOfferComponent,
        OrderModule,
        TextPipe,
        PicturePipe,
        DimensionUnitPipe,
        TitlePipe,
        NgxImageZoomModule,
        MatSnackBarModule,
        // components
        PaginationComponent,
        AddressBookPreviewComponent,
        AddressFormComponent,
        ArtistItemComponent,
        ArtistProfilePreviewComponent,
        ArtworkItemComponent,
        ArtworkPictureUploaderComponent,
        ArtworksListComponent,
        BackToButtonComponent,
        VerticalButtonComponent,
        BackgroundSectionComponent,
        ChipAutocompleteComponent,
        EducationFormComponent,
        ExhibitionFormComponent,
        FavoriteListFormComponent,
        FooterComponent,
        FooterSecondComponent,
        HomeSlideComponent,
        MasonryListComponent,
        PageLoaderComponent,
        ProfilePictureUploaderComponent,
        ProfilePreviewComponent,
        SideMenuSubcategoriesComponent,
        SocialLoginComponent,
        SocialShareComponent,
        TopNavComponent,
        UpdateArtistProfileComponent,
        UpdatePasswordComponent,
        UpdateProfileComponent,
        ArtworkRoomComponent,
        UpdateArtistEducationsComponent,
        UpdateArtistExhibitionsComponent,
        PaymentMethodFormComponent,
        PaymentMethodPreviewComponent,
        ScrollToModule,
        MusicButtonToolbarComponent,
        // directives
        PictureLoadDirective,
        InputEventDirective,
        SelectAddressBookItemComponent,
        SelectPaymentMethodComponent,
        EditDonationComponent,
        BillingAddressComponent,
        TeamMemberItemComponent,
        PromoCodesBannerComponent,
        FilteredLinkBannerComponent
    ],
})
export class SharedModule {
}
