import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FavoriteService} from '../../../shared-module/services';

@Component({
    selector: 'app-add-wish-list-dialog',
    templateUrl: './add-wish-list-dialog.component.html',
    styleUrls: ['./add-wish-list-dialog.component.scss']
})
export class AddWishListDialogComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<AddWishListDialogComponent>,
                private favoriteService: FavoriteService,
                @Inject(MAT_DIALOG_DATA) private data: { artworkId: string, addToWishList: boolean }) {
    }

    ngOnInit(): void {
    }

    favoriteListChanged(favoriteListId: string) {
        if (this.data.addToWishList) {
            this.favoriteService
                .addFavoriteArtwork(favoriteListId, this.data.artworkId)
                .subscribe(() => this.dialogRef.close(true));
        } else {
            this.dialogRef.close(true);
        }
    }

    close() {
        this.dialogRef.close();
    }
}
