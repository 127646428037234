import {Component, OnInit} from '@angular/core';
import {OfferService} from '../../shared-module/services';
import {IOffer} from '../../shared-module/interfaces';
import {RemoveOfferComponent} from '../../shared-module/modals';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-offers-history',
    templateUrl: './offers-history.component.html',
    styleUrls: ['./offers-history.component.scss']
})
export class OffersHistoryComponent implements OnInit {
    offers: IOffer[] = [];
    count = 0;
    constructor(private offerService: OfferService, private dialog: MatDialog) {
    }

    ngOnInit() {
        this.getOffers();
    }

    getOffers() {
        this.offerService
            .getCustomerOffers()
            .subscribe(({ results, count }) => {
                this.offers = results;
                this.count = count;
            });
    }

    removeOffer(artworkId: string): void {
        this.dialog.open(RemoveOfferComponent, {
            width: '50vw',
            data: {artworkId},
            panelClass: 'add-to-cart-modal'
        });
    }
}
