import {AfterViewInit, Component, OnInit} from '@angular/core';
import {GiftCardService} from '../../shared-module/services/gift-card.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IGiftCard} from '../../shared-module/interfaces';
import {SharedService} from '../../shared-module/services';

@Component({
    selector: 'app-buy-gift-card-review',
    templateUrl: './buy-gift-card-review.component.html',
    styleUrls: ['./buy-gift-card-review.component.scss']
})
export class BuyGiftCardReviewComponent implements OnInit, AfterViewInit {
    giftCard: IGiftCard;
    hasReview: boolean;

    constructor(private giftCardService: GiftCardService,
                private route: ActivatedRoute,
                private sharedService: SharedService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.giftCard = this.route.snapshot.data.giftCard;
        const paypalBuyerId = this.route.snapshot.queryParams.PayerID;
        if (paypalBuyerId) {
            this.confirmGiftCardOrder();
        }

        console.log(this.giftCard, 'this.giftCard')
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    confirmGiftCardOrder(): void {
        console.log(this.giftCard.giftCardId, this.giftCard);
        this.giftCardService
            .confirmGiftCardOrder(this.giftCard.giftCardId)
            .subscribe(() => {
                this.router.navigate(['/gift-card/complete', this.giftCard.giftCardId]);
            }, () => {
                this.router.navigate(['/gift-card/complete', this.giftCard.giftCardId]);
            });
    }

    placeGiftCardOrder(): void {
        this.hasReview = true;
        if (this.giftCard.paymentMethodType === 'paypal') {
            this.giftCardService
                .createPaypalGiftCardOrder(this.giftCard.giftCardId)
                .subscribe(({url}) => {
                    this.sharedService.emitLoaderChange(true);
                    window.location.href = url;
                });
        }

        if (this.giftCard.paymentMethodType === 'credit_card') {
            this.confirmGiftCardOrder();
        }
    }

}
