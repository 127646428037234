import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {AddressBookService, CartItemService, SharedService, ValidatorService} from '../../shared-module/services';
import {ActivatedRoute, Router} from '@angular/router';
import {IAddressBookItem, IApiResponse, IArtwork, IOrder, IPaymentMethod, IUser} from '../../shared-module/interfaces';
import {PaymentMethodService} from '../../shared-module/services/payment-method.service';
import {OrderService} from '../../shared-module/services/order.service';
import {MatDialog} from '@angular/material/dialog';
import {SelectAddressBookItemComponent} from '../../shared-module/dialogs/select-address-book-item/select-address-book-item.component';
import {SelectPaymentMethodComponent} from '../../shared-module/dialogs/select-payment-method/select-payment-method.component';
import faker from 'faker';
import {PromoCodeService} from '../../shared-module/services/promo-code.service';
import {GiftCardService} from '../../shared-module/services/gift-card.service';
import {environment} from '../../../environments/environment';
import {RedeemGiftCardComponent} from '../../shared-module/dialogs/redeem-gift-card/redeem-gift-card.component';

// @ts-ignore
const stripe = window.Stripe(environment.stripePublicKey);

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterViewInit {
    user: IUser;
    artworks: IArtwork[] = [];
    paymentMethods: IPaymentMethod[] = [];
    addresses: IAddressBookItem[] = [];
    count = 0;
    total = 0;
    taxAmount = 0;
    taxAmountPreview = 0;
    totalPreview = 0;
    subtotal = 0;
    paymentMethodForm: FormGroup;
    billingAddressControl = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        phone: new FormControl('', [Validators.required, this.validatorService.phone]),
        secondPhone: new FormControl('', this.validatorService.phone),
        address: new FormControl('', Validators.required),
        secondAddress: new FormControl(''),
        city: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required),
        postalCode: new FormControl('', Validators.required),
    });
    shippingAddressForm: FormGroup;
    selectedPaymentMethod: IPaymentMethod;
    selectedShippingAddress: IAddressBookItem;
    selectedBillingAddress: IAddressBookItem;
    addNewShippingAddress = false;
    addNewBillingAddress = false;
    paymentMethodType: FormControl;
    sameAsShippingAddress: FormControl;
    useGiftCard: FormControl;
    addNewPaymentMethod = false;
    promoCode: FormControl;
    promoCodeId: string;
    artworkId:string;
    discount: number;
    discountAmount: number;
    addNewPromoCode = false;
    cardElement: any;
    giftCardBalance = 0;
    usedGiftCardBalance = 0;
    availableGiftCardBalance = 0;
    values: number[] = [5, 10, 25, 50, 100, 200, 500];
    showOtherAmount = false;
    isDonationAmount: boolean;
    allFormValidation: boolean;

    donationForm = new FormGroup({
        amount: new FormControl(null,),
        donationType: new FormControl('one_time', Validators.required),
        frequency: new FormControl(''),
        isCompany: new FormControl(false),
        matchingGifts: new FormControl(false),
        companyName: new FormControl(''),
        isTribute: new FormControl(false),
        tributeType: new FormControl('in_memory_of'),
        tributeFirstName: new FormControl(''),
        tributeLastName: new FormControl(''),
        isAnonymous: new FormControl(false),
        firstName: new FormControl(''),
        lastName: new FormControl(''),
    });

    lastThingControl = new FormControl(false);

    private readonly defaultDonationFormValue = this.donationForm.value;

    constructor(private router: Router,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private validatorService: ValidatorService,
                private addressBookService: AddressBookService,
                private paymentMethodService: PaymentMethodService,
                private orderService: OrderService,
                private sharedService: SharedService,
                private promoCodeService: PromoCodeService,
                private giftCardService: GiftCardService,
                private cartItemService: CartItemService) {
        this.createFormGroup();
        this.paymentMethodType = new FormControl('credit_card', [Validators.required]);
        this.sameAsShippingAddress = new FormControl(false);
        this.useGiftCard = new FormControl(false);
        this.promoCode = new FormControl('', [Validators.required]);
    }

    get amount(): AbstractControl {
        return this.donationForm.get('amount');
    }

    get email(): AbstractControl {
        return this.donationForm.get('email');
    }

    get donationType(): AbstractControl {
        return this.donationForm.get('donationType');
    }

    get isCompany(): AbstractControl {
        return this.donationForm.get('isCompany');
    }

    get companyName(): AbstractControl {
        return this.donationForm.get('companyName');
    }

    get matchingGifts(): AbstractControl {
        return this.donationForm.get('matchingGifts');
    }

    get isTribute(): AbstractControl {
        return this.donationForm.get('isTribute');
    }

    get tributeType(): AbstractControl {
        return this.donationForm.get('tributeType');
    }

    get tributeFirstName(): AbstractControl {
        return this.donationForm.get('tributeFirstName');
    }

    get tributeLastName(): AbstractControl {
        return this.donationForm.get('tributeLastName');
    }

    get isAnonymous(): AbstractControl {
        return this.donationForm.get('isAnonymous');
    }

    get firstName(): AbstractControl {
        return this.donationForm.get('firstName');
    }

    get lastName(): AbstractControl {
        return this.donationForm.get('lastName');
    }

    get frequency(): AbstractControl {
        return this.donationForm.get('frequency');
    }

    changeAmount(value: number) {
        // this.showOtherAmount = false;
        this.amount.setValue(value);
    }

    donationTypeChanged(): void {
        if (this.donationType.value === 'recurring') {
            this.paymentMethodType.setValue('credit_card');
        }
    }

    isAnonymousChanged(): void {
        if (this.isAnonymous.value) {
            this.isCompany.setValue(false);
        }
    }

    ngOnInit() {
        this.user = this.route.snapshot.data.user;
        const promoCode = this.route.snapshot.queryParams.promoCode;
        this.billingAddressControl.setValidators(Validators.required);
        if (promoCode) {
            this.promoCode.setValue(promoCode);
            this.checkPromoCode();
        }
        if (!promoCode) {
            this.getCartItems();
        }
        this.getAddresses();
        this.getPaymentMethods();
        this.getGiftCardBalance();

        this.donationForm.get('amount').valueChanges.subscribe((value) => {
            this.total = this.totalPreview + value;
            this.taxAmount = this.taxAmountPreview + value;
        });

        this.lastThingControl.valueChanges.subscribe((value => {
            if (!value) {
                this.total = this.totalPreview;
                this.taxAmount = this.taxAmountPreview;
                this.isDonationAmount = false;
                this.donationForm.reset(this.defaultDonationFormValue);
                this.donationForm.updateValueAndValidity();
            }
        }));
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    private getGiftCardBalance(): void {
        this.giftCardService
            .getGiftCardBalance()
            .subscribe(({balance}) => {
                this.giftCardBalance = balance;
            });
    }

    openRedeemGiftCardDialog() {
        this.dialog.open(RedeemGiftCardComponent, {
            width: '484px',
            maxHeight: '448px'
        }).afterClosed().subscribe((refresh) => {
            if (refresh) {
                this.getGiftCardBalance();
            }
        });
    }

    initNewPaymentMethod() {
        const elements = stripe.elements();
        this.addNewPaymentMethod = true;
        this.cardElement = elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    backgroundColor: '#fff',
                    color: '#000',
                    fontFamily: 'Lato, san-serif',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textTransform: 'uppercase'
                }
            }
        });
        this.cardElement.mount('#card-element');
        this.cardElement.on('change', (event) => {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else if (!event.complete) {
                displayError.textContent = 'Card information is required';
            } else {
                displayError.textContent = '';
            }
        });
    }

    getCartItems(done?: any): void {
        this.artworkId = this.route.snapshot.queryParams.artwork;
        const params: any = {};
        if (this.promoCodeId) {
            params.promoCodeId = this.promoCodeId;
        }
        if (this.useGiftCard.value) {
            params.useGiftCard = true;
        }
        if (this.donationForm && this.donationForm.get('amount').value) {
            params.donationAmount = this.donationForm.get('amount').value;
        }
        if(this.artworkId) {
            params.artworkId = this.artworkId;
        }

        this.cartItemService
            .getCartItems(params)
            .subscribe(({
                            results,
                            count,
                            total,
                            subtotal,
                            discountAmount,
                            usedGiftCardBalance,
                            availableGiftCardBalance,
                            taxAmount
                        }) => {
                this.artworks = results;
                this.count = count;
                this.subtotal = subtotal;
                this.discountAmount = discountAmount;
                this.total = total;
                this.totalPreview = total;
                this.usedGiftCardBalance = usedGiftCardBalance;
                this.availableGiftCardBalance = availableGiftCardBalance;
                this.taxAmount = taxAmount;
                this.taxAmountPreview = taxAmount;
                if (!this.artworks.length) {
                    this.router.navigate(['/cart/shopping-cart']).then();
                } else if (done) {
                    done();
                }
            });
    }

    private getPaymentMethods(): void {
        this.paymentMethodService
            .getPaymentMethods()
            .subscribe(({results}) => {
                this.paymentMethods = results;
                if (this.paymentMethods.length) {
                    const defaultPaymentMethod = this.paymentMethods.find(item => item.defaultPaymentMethod);
                    if (defaultPaymentMethod) {
                        this.selectedPaymentMethod = defaultPaymentMethod;
                    }
                } else {
                    setTimeout(() => {
                        this.initNewPaymentMethod();
                    }, 1000);
                }
            });
    }

    private getAddresses(): void {
        this.addressBookService
            .getAddresses()
            .subscribe(({results, count}) => {
                this.addresses = results;
                if (this.addresses.length) {
                    const defaultAddress = this.addresses.find(item => item.defaultAddress);
                    if (defaultAddress) {
                        this.selectedShippingAddress = defaultAddress;
                        this.selectedBillingAddress = defaultAddress;
                    }
                } else {
                    this.addNewShippingAddress = true;
                    this.addNewBillingAddress = true;
                    this.sameAsShippingAddress.setValue(true);
                }
            });
    }

    checkPromoCode(): void {
        if (!this.promoCode.value) {
            return;
        }

        this.promoCodeService
            .checkPromoCode({customerId: this.user.customer.customerId, code: this.promoCode.value})
            .subscribe(({valid, id, discount}) => {
                if (valid) {
                    this.promoCodeId = id;
                    this.discount = discount;
                    this.addNewPromoCode = false;
                    this.promoCode.setErrors(null);
                    this.getCartItems();
                } else {
                    this.discount = 0;
                    this.promoCodeId = null;
                    this.promoCode.setErrors({invalid: true});
                    this.getCartItems();
                }
            });
    }

    changePromoCode(): void {
        this.addNewPromoCode = true;
        this.discount = 0;
        this.promoCodeId = null;
        this.getCartItems();
    }

    selectAddress(type: string) {
        this.dialog.open(SelectAddressBookItemComponent, {
            width: '718px',
            maxHeight: '448px',
            data: {type}
        }).afterClosed().subscribe((addressBookItem) => {
            if (addressBookItem) {
                if (type === 'shipping') {
                    this.addNewShippingAddress = false;
                    this.selectedShippingAddress = addressBookItem;
                }

                if (type === 'billing') {
                    this.addNewBillingAddress = false;
                    this.selectedBillingAddress = addressBookItem;
                }
            }
        });
    }

    selectPaymentMethod() {
        this.dialog.open(SelectPaymentMethodComponent, {
            width: '718px',
            maxHeight: '448px'
        }).afterClosed().subscribe((paymentMethod) => {
            if (paymentMethod) {
                this.selectedPaymentMethod = paymentMethod;
                this.addNewPaymentMethod = false;
            }
        });
    }

    async onSubmitStripe() {
        const {error} = await stripe.createToken(this.cardElement);

        if (error) {
            console.log('Something is wrong:');
            this.allFormValidation = true;
            return;
        }
    }

    addOrder() {
        if(this.lastThingControl.value) {
            this.isDonationAmount = true;
        }
        if(this.addNewPaymentMethod) {
            this.onSubmitStripe().then();
        }

        if (this.lastThingControl.value && this.donationForm.invalid) {
            this.allFormValidation = true;
            this.donationForm.markAllAsTouched();
            return;
        }

        if(this.addNewPaymentMethod && this.paymentMethodForm.invalid) {
            this.allFormValidation = true;
            this.onSubmitStripe().then();
            this.paymentMethodForm.markAllAsTouched();
            return;
        }

        if(this.shippingAddressForm.invalid) {
            this.allFormValidation = true;
            this.onSubmitStripe().then();
            this.shippingAddressForm.markAllAsTouched();
            return;
        }
        this.allFormValidation = false;

        this.getCartItems(() => {
            const soldArtwork = this.artworks.find(artwork => artwork.customerId);

            if (soldArtwork) {
                this.sharedService.showNotification('artwork_sold', 'error');
                return;
            }

            const onHoldArtwork = this.artworks.find(artwork => artwork.orderId);

            if (onHoldArtwork) {
                this.sharedService.showNotification('artwork_on_hold', 'error');
                return;
            }

            if (this.paymentMethodType.value === 'paypal') {
                if (this.addNewShippingAddress && this.shippingAddressForm.invalid) {
                    this.shippingAddressForm.markAllAsTouched();
                    return;
                }

                if (!this.addNewShippingAddress && !this.selectedShippingAddress) {
                    return;
                }

                const order: IOrder = {
                    paymentMethodType: this.paymentMethodType.value
                };

                const shippingAddress: IAddressBookItem = this.shippingAddressForm.getRawValue();
                if (!shippingAddress.visibleAddress) {
                    shippingAddress.defaultAddress = false;
                }

                if (this.promoCodeId) {
                    order.promoCodeId = this.promoCodeId;
                }

                if (this.useGiftCard.value) {
                    order.useGiftCard = true;
                }

                if(this.artworkId){
                    order.artworkId = this.artworkId;
                }

                const promises: any[] = [];

                if (this.addNewShippingAddress) {
                    promises.push(this.saveAddressBookItem(shippingAddress));
                } else {
                    promises.push(Promise.resolve(this.selectedShippingAddress.addressBookItemId));
                }

                Promise.all(promises)
                    .then(([shippingAddressId]) => {
                        order.addressBookItemId = shippingAddressId;
                        return this.saveOrder({
                            ...order,
                            donation: this.lastThingControl.value ? this.donationForm.value : null,
                        });
                    })
                    .then(({id, donationId}) => {
                        this.router.navigate(['/cart/order-review', id], {queryParams: {donationId}});
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            if (this.paymentMethodType.value === 'credit_card') {
                if (this.addNewShippingAddress && this.shippingAddressForm.invalid) {
                    return;
                }

                if (!this.addNewShippingAddress && !this.selectedShippingAddress) {
                    return;
                }

                if (this.addNewPaymentMethod && (this.paymentMethodForm.invalid || !this.cardElement._complete)) {
                    return;
                }

                if (!this.addNewPaymentMethod && !this.selectedPaymentMethod) {
                    return;
                }

                const order: IOrder = {
                    paymentMethodType: this.paymentMethodType.value
                };

                if (this.promoCodeId) {
                    order.promoCodeId = this.promoCodeId;
                }

                if (this.useGiftCard.value) {
                    order.useGiftCard = true;
                }

                if(this.artworkId){
                    order.artworkId = this.artworkId;
                }

                const paymentMethod: IPaymentMethod = {
                    cardHolderName: this.paymentMethodForm.get('cardHolderName').value,
                    defaultPaymentMethod: this.paymentMethodForm.get('defaultPaymentMethod').value,
                    visiblePaymentMethod: this.paymentMethodForm.get('visiblePaymentMethod').value
                };
                if (!paymentMethod.visiblePaymentMethod) {
                    paymentMethod.defaultPaymentMethod = false;
                }
                const billingAddress: IAddressBookItem = {
                    ...this.billingAddressControl.value,
                    defaultAddress: this.paymentMethodForm.get('defaultAddress').value,
                    visibleAddress: this.paymentMethodForm.get('visibleAddress').value
                };
                const shippingAddress: IAddressBookItem = this.shippingAddressForm.getRawValue();
                if (!shippingAddress.visibleAddress) {
                    shippingAddress.defaultAddress = false;
                }

                const promises: any[] = [];

                if (this.addNewShippingAddress) {
                    promises.push(this.saveAddressBookItem(shippingAddress));
                } else {
                    promises.push(Promise.resolve(this.selectedShippingAddress.addressBookItemId));
                }
                if (this.total > 0) {
                    if (this.addNewPaymentMethod) {
                        if (this.addNewBillingAddress) {
                            if (this.sameAsShippingAddress.value) {
                                promises.push(Promise.resolve(null));
                            } else {
                                promises.push(this.saveAddressBookItem(billingAddress));
                            }
                        } else {
                            promises.push(Promise.resolve(this.selectedBillingAddress.addressBookItemId));
                        }
                    } else {
                        promises.push(Promise.resolve(this.selectedPaymentMethod.addressBookItem.addressBookItemId));
                    }
                }

                Promise.all(promises)
                    .then(([shippingAddressId, billingAddressId]) => {
                        order.addressBookItemId = shippingAddressId;
                        if (this.total > 0) {
                            if (billingAddressId) {
                                paymentMethod.addressBookItemId = billingAddressId;
                            } else {
                                paymentMethod.addressBookItemId = shippingAddressId;
                            }

                            if (this.addNewPaymentMethod) {
                                return this.addPaymentToken(paymentMethod, billingAddress);
                            } else {
                                return Promise.resolve(this.selectedPaymentMethod.paymentMethodId);
                            }
                        } else {
                            return Promise.resolve(null);
                        }
                    })
                    .then((paymentMethodId) => {
                        if (paymentMethodId) {
                            order.paymentMethodId = paymentMethodId;
                        }
                        return this.saveOrder({
                            ...order,
                            donation: this.lastThingControl.value ? this.donationForm.value : null,
                        });
                    })
                    .then(({id, donationId}) => {
                        this.router.navigate(['/cart/order-review', id], {queryParams: {donationId}});
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    }

    private saveOrder(order: IOrder): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            this.orderService
                .addOrder(order)
                .subscribe((value) => {
                    console.log(value);
                    resolve(value);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private addPaymentToken(paymentMethod: IPaymentMethod, billingAddress: IAddressBookItem): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const addressBookItem = this.addresses.find(address => address.addressBookItemId === paymentMethod.addressBookItemId) || billingAddress;
            this.sharedService.emitLoaderChange(true);
            stripe.createToken(this.cardElement, {
                name: paymentMethod.cardHolderName,
                address_line1: addressBookItem.address,
                address_line2: addressBookItem.secondAddress || '',
                address_city: addressBookItem.city,
                address_state: addressBookItem.state,
                address_zip: addressBookItem.postalCode,
                address_country: addressBookItem.country
            }).then(({token}) => {
                this.sharedService.emitLoaderChange(false);
                paymentMethod.paymentToken = token.id;
                paymentMethod.cardId = token.card.id;
                paymentMethod.cardBrand = token.card.brand;
                paymentMethod.cardLast4 = token.card.last4;
                paymentMethod.cardExpireMonth = token.card.exp_month;
                paymentMethod.cardExpireYear = token.card.exp_year;
                paymentMethod.cardFunding = token.card.funding;
                this.savePaymentMethod(paymentMethod)
                    .then((id => resolve(id)))
                    .catch(err => reject(err));
            }).catch((err) => {
                this.sharedService.emitLoaderChange(false);
                reject(err);
            });
        });
    }

    private savePaymentMethod(paymentMethod: IPaymentMethod): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.paymentMethodService
                .addPaymentMethod(paymentMethod)
                .subscribe(({id}) => {
                    resolve(id);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private saveAddressBookItem(addressBookItem: IAddressBookItem): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.addressBookService
                .addAddressBookItem(addressBookItem)
                .subscribe(({id}) => {
                    resolve(id);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private createFormGroup(): void {
        this.shippingAddressForm = this.fb.group({
            firstName: ['', []],
            lastName: ['', []],
            phone: ['', [
                this.validatorService.phone
            ]],
            secondPhone: ['', [this.validatorService.phone]],
            address: ['', []],
            secondAddress: [''],
            city: ['', []],
            state: ['', []],
            country: ['', []],
            postalCode: ['', []],
            defaultAddress: [true],
            visibleAddress: [true]
        });
        this.paymentMethodForm = this.fb.group({
            cardHolderName: ['', Validators.required],
            defaultPaymentMethod: [true],
            visiblePaymentMethod: [true],
            defaultAddress: [false],
            visibleAddress: [true]
        });

        if (!environment.production) {
            // this.paymentMethodForm.patchValue({
            //     cardHolderName: `${faker.name.firstName().toUpperCase()} ${faker.name.lastName().toUpperCase()}`,
            // });

            // this.shippingAddressForm.patchValue({
            //     firstName: faker.name.firstName(),
            //     lastName: faker.name.lastName(),
            //     phone: faker.phone.phoneNumber()
            //         .replace(/-/g, '')
            //         .replace(/ /g, '')
            //         .replace(/\(/g, '')
            //         .replace(/\)/g, '')
            //         .replace(/x/g, ''),
            //     address: faker.address.streetAddress(),
            //     city: faker.address.city(),
            //     state: faker.address.state(),
            //     country: faker.address.country(),
            //     postalCode: faker.address.zipCode()
            // });
        }
    }

}
