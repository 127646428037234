import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {StaticPageComponent} from './static-page.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {CopyrightPolicyComponent} from './copyright-policy/copyright-policy.component';
import {TermsConditionsComponent} from './terms-conditions/terms-conditions.component';
import {ReturnRefundPolicyComponent} from './return-refund-policy/return-refund-policy.component';
import {CookiesPolicyComponent} from './cookies-policy/cookies-policy.component';
import {DisclaimerComponent} from './disclaimer/disclaimer.component';
import {TaxDeductibleComponent} from './tax-deductible/tax-deductible.component';
import {IncomeTaxDeductibleArtComponent} from './income-tax-deductible-art/income-tax-deductible-art.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {FactsAboutArmeniaComponent} from './facts-about-armenia/facts-about-armenia.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {FaqComponent} from './faq/faq.component';
import {MusicRoomComponent} from './music-room/music-room.component';

const routes: Routes = [
    {
        path: 'page',
        component: StaticPageComponent,
        children: [
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent
            },
            {
                path: 'copyright-policy',
                component: CopyrightPolicyComponent
            },
            {
                path: 'terms-conditions',
                component: TermsConditionsComponent
            },
            {
                path: 'returns-refunds-policy',
                component: ReturnRefundPolicyComponent
            },
            {
                path: 'cookies-policy',
                component: CookiesPolicyComponent
            },
            {
                path: 'disclaimer',
                component: DisclaimerComponent
            }
        ]
    },
    {
        path: 'tax-deductible-art',
        component: TaxDeductibleComponent
    },
    {
        path: 'income-tax-deductible-art',
        component: IncomeTaxDeductibleArtComponent
    },
    {
        path: 'our-mission',
        component: AboutUsComponent
    },
    {
        path: 'facts-about-armenia',
        component: FactsAboutArmeniaComponent
    },
    {
        path: 'contact',
        component: ContactUsComponent
    },
    {
        path: 'faq',
        component: FaqComponent
    },
    {
        path: 'music-room',
        component: MusicRoomComponent
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticPagesRoutingModule { }
