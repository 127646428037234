import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ArtworkService} from '../shared-module/services';
import {ActivatedRoute} from '@angular/router';
import {
    IStyle,
    IMedium,
    ISubject,
    IMaterial,
    IColor,
    ICategory,
    IArtist,
    IArtwork, IUser
} from '../shared-module/interfaces';
import ntc from '../../assets/ntc';
import {MediaMatcher} from '@angular/cdk/layout';
import { Title } from "@angular/platform-browser";
@Component({
    selector: 'app-artwork-list',
    templateUrl: './artwork-list.component.html',
    styleUrls: ['./artwork-list.component.scss']
})
export class ArtworkListComponent implements OnInit, AfterViewInit {
    user: IUser;
    artworks: IArtwork[] = [];
    categories: ICategory[] = [];
    styles: IStyle[] = [];
    subjects: ISubject[] = [];
    mediums: IMedium[] = [];
    materials: IMaterial[] = [];
    colors: IColor[] = [];
    artists: IArtist[] = [];
    selectedCategory: ICategory;
    params: any = {};
    count: number;
    sidenavActive = true;
    tabletMediaMatcher: MediaQueryList;
    showSingleBannerPromoCode: boolean;
    showMultipleBannerPromoCodes: boolean;

    constructor(private route: ActivatedRoute,
                private mediaMatcher: MediaMatcher,
                private artworkService: ArtworkService,
                private title: Title) {
        this.tabletMediaMatcher = mediaMatcher.matchMedia('(max-width: 1160px)');
        this.sidenavActive = !this.tabletMediaMatcher.matches;
        this.tabletMediaMatcher.onchange = () => {
            this.sidenavActive = !this.tabletMediaMatcher.matches;
        };
    }

    ngOnInit() {
        this.user = this.route.snapshot.data.user;
        this.categories = this.route.snapshot.data.categories;
        this.styles = this.route.snapshot.data.styles;
        this.subjects = this.route.snapshot.data.subjects;
        this.mediums = this.route.snapshot.data.mediums;
        this.materials = this.route.snapshot.data.materials;
        this.colors = this.route.snapshot.data.colors;
        this.colors = this.colors.map((color) => {
            // @ts-ignore
            color.name = ntc.name(color.code)[1];
            return color;
        });
        this.artists = this.route.snapshot.data.artists;
        this.artists = this.artists.map((artist) => {
            artist.name = `${artist.user.firstName} ${artist.user.lastName}`;
            return artist;
        });
        this.route.queryParams.subscribe((params) => {
            this.params = {...params};
            if (params.categoryId) {
                this.selectedCategory = this.categories.find(category => category.categoryId === params.categoryId);
            }
            this.getArtworks();
        });

        this.title.setTitle(this.selectedCategory.name.charAt(0).toUpperCase() + this.selectedCategory.name.slice(1));
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    getArtworks() {
        this.artworkService
            .searchArtworks(this.params)
            .subscribe(({results, count}) => {
                this.artworks = results;
                this.count = count;
                document.querySelector('mat-drawer-content').scrollTo(0, 0);
            });
    }

    setBannersMode(data: {
        showSingleBannerPromoCode?: boolean,
        showMultipleBannerPromoCodes?: boolean
    }) {
        this.showSingleBannerPromoCode = data.showSingleBannerPromoCode;
        this.showMultipleBannerPromoCodes = data.showMultipleBannerPromoCodes;
    }
}
