import {Component, OnInit} from '@angular/core';
import {IArtist, IArtwork, ICompany} from '../../shared-module/interfaces';
import {ArtistReviewService, ArtworkReviewService} from '../../shared-module/services';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-artist-artwork-review-list',
    templateUrl: './artist-artwork-review-list.component.html',
    styleUrls: ['./artist-artwork-review-list.component.scss']
})
export class ArtistArtworkReviewListComponent implements OnInit {
    artist: IArtist;
    company: ICompany;
    artworks: IArtwork[] = [];
    parentFormSubmitted = false;

    constructor(private artworkReviewService: ArtworkReviewService,
                private artistReviewService: ArtistReviewService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.artist = this.route.snapshot.data.artist;
        this.company = this.route.snapshot.data.company;
        this.getArtistArtworks();
    }

    getArtistArtworks(): void {
        this.artworkReviewService
            .getArtistArtworks()
            .subscribe(({ results }) => {
                this.artworks = results;
            });
    }

    saveArtistReview(): void {
        this.parentFormSubmitted = true;
        if (this.artworks.length < 9) {
            document.querySelector('mat-drawer-content').scrollTo(0, 0);
            setTimeout(() => {
                this.parentFormSubmitted = false;
            }, 3000);
            return;
        }
        this.artistReviewService
            .sendArtistReview()
            .subscribe(() => {
                setTimeout(() => {
                    this.router.navigate(['/artist-review/complete']).then();
                }, 0);
            });
    }
}
