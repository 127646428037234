import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService, ValidatorService} from '../../services';

@Component({
    selector: 'app-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
    updatePasswordForm: FormGroup;
    showCurrentPassword = false;
    showNewPassword = false;
    showRepeatPassword = false;

    constructor(private userService: UserService,
                private validatorService: ValidatorService,
                private fb: FormBuilder) {
        this.createFormGroup();
    }

    ngOnInit() {
    }

    updatePassword(form: HTMLFormElement) {
        if (!this.updatePasswordForm.valid) {
            return;
        }

        const data: any = this.updatePasswordForm.getRawValue();

        this.userService
            .updatePassword(data)
            .subscribe(() => {
                this.updatePasswordForm.reset();
                form.reset();
            });
    }

    private createFormGroup() {
        this.updatePasswordForm = this.fb.group({
            currentPassword: ['', [
                Validators.required
            ]],
            password: ['', [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(24),
                this.validatorService.password
            ]],
            confirmPassword: ['', [
                Validators.required
            ]]
        }, {validator: this.validatorService.confirmPassword});
    }

}
