import {Component, Input, OnInit} from '@angular/core';
import {IAddressBookItem, IPaymentMethod} from '../../interfaces';

@Component({
    selector: 'app-payment-method-preview',
    templateUrl: './payment-method-preview.component.html',
    styleUrls: ['./payment-method-preview.component.scss']
})
export class PaymentMethodPreviewComponent implements OnInit {
    @Input() paymentMethod: IPaymentMethod;
    @Input() showBilling = true;
    @Input() showDefault = true;
    constructor() {
    }

    ngOnInit() {
    }

}
