import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IArtwork, ICustomer} from '../../interfaces';
import {Router} from '@angular/router';
import {ArtworkService, CartItemService} from '../../services';

@Component({
    selector: 'app-artwork-added',
    templateUrl: './artwork-added.component.html',
    styleUrls: ['./artwork-added.component.scss']
})
export class ArtworkAddedComponent implements OnInit {
    artwork: IArtwork;
    customer: ICustomer;

    constructor(private dialogRef: MatDialogRef<ArtworkAddedComponent>,
                private router: Router,
                private cartItemService: CartItemService,
                private artworkService: ArtworkService,
                @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit() {
        this.artwork = this.data.artwork;
        this.customer = this.data.customer;
        this.addToCart();
    }

    addToCart() {
        if (!this.customer) {
            this.cartItemService.addLocalCartItem(this.artwork.artworkId);
            this.cartItemService.updateLocalCartItem(this.artwork.artworkId);
            return;
        }
        this.cartItemService
            .addCartItem(this.artwork.artworkId)
            .subscribe(() => {
                this.getArtwork();
                this.cartItemService.updateCartItemsCount(this.artwork.artworkId);
            });
    }

    private getArtwork() {
        this.artworkService
            .getArtwork(this.artwork.artworkId, {customerId: this.customer.customerId})
            .subscribe((artwork) => {
                this.artwork = artwork;
            });
    }

    goToCart() {
        this.close();
        setTimeout(() => {
            this.router.navigate(['/cart/shopping-cart']).then();
        }, 0);
    }

    deleteCartItem(): void {
        if (!this.customer) {
            this.cartItemService.deleteLocalCartItem(this.artwork.artworkId);
            this.close();
        } else {
            this.cartItemService
                .deleteCartItem(this.artwork.cartItem.cartItemId)
                .subscribe(() => {
                    this.close();
                });
        }
    }

    close(): void {
        this.dialogRef.close();
    }
}
