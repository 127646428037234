import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {OurTeamComponent} from './our-team/our-team.component';
import {SharedModule} from '../shared-module/shared.module';
import {TeamRoutingModule} from './team-routing.module';
import {FounderComponentComponent} from './founder-component/founder-component.component';

@NgModule({
    declarations: [OurTeamComponent, FounderComponentComponent],
    imports: [
        CommonModule,
        SharedModule,
        TeamRoutingModule
    ]
})
export class TeamModule {
}
