import {Component, OnInit, Input} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {IArtwork} from '../../../../app/shared-module/interfaces';

@Component({
    selector: 'app-vertical-button',
    templateUrl: './vertical-button.component.html',
    styleUrls: ['./vertical-button.component.scss']
})

export class VerticalButtonComponent implements OnInit {
    @Input() title = '';
    @Input() artwork:IArtwork;
    @Input() url = '';
    @Input() verticalText = '';
    @Input() left = '';
    offer:boolean=false;

    constructor(
        private _location: Location,
        private router: Router,
    ) {
        
    }

    ngOnInit() {
       
        if (this.router.url.indexOf('artwork/offer/'+ this.artwork?.artworkId) > -1) {
            this.title = "Back";
            this.offer = true;
        }
    }

    back() {
        window.history.back();
    }

}
