import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {UserService} from '../../shared-module/services';
import {IUser} from '../../shared-module/interfaces';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
    user: IUser;
    editProfile = false;

    constructor(private fb: FormBuilder,
                private userService: UserService) {
    }

    ngOnInit() {
        this.getProfile();
    }

    userChanged(): void {
        this.getProfile();
    }

    private getProfile(): void {
        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
            });
    }

}
