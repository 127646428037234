import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IEducation} from '../../interfaces';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-education-form',
    templateUrl: './education-form.component.html',
    styleUrls: ['./education-form.component.scss']
})
export class EducationFormComponent implements OnInit, OnChanges {
    @Input() education: IEducation;
    @Input() educationIndex: number;
    @Input() parentFormSubmitted: boolean;
    @Output() educationRemoved = new EventEmitter<number>();
    educationForm: FormGroup;
    name: FormControl;
    specialization: FormControl;
    startDate: FormControl;
    endDate: FormControl;
    city: FormControl;
    country: FormControl;
    startDates: number[] = [];
    endDates: number[] = [];
    currentYear: number = moment().year();

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        for (let i = this.currentYear + 20; i >= this.currentYear - 80; i--) {
            if (i <= this.currentYear) {
                this.startDates.push(i);
            }
            this.endDates.push(i);
        }
        this.createFormControls();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.parentFormSubmitted && this.educationForm) {
            this.name.markAsTouched({onlySelf: true});
            this.specialization.markAsTouched({onlySelf: true});
            this.startDate.markAsTouched({onlySelf: true});
            this.endDate.markAsTouched({onlySelf: true});
            this.city.markAsTouched({onlySelf: true});
            this.country.markAsTouched({onlySelf: true});
        }
    }

    removeEducation(): void {
        this.educationRemoved.emit(this.educationIndex);
    }

    private createFormControls(): void {
        this.name = new FormControl(this.education.name || '', [
            Validators.required
        ]);
        this.specialization = new FormControl(this.education.specialization || '', [
            Validators.required
        ]);
        this.startDate = new FormControl(this.education.startDate || null, [
            Validators.required
        ]);
        this.endDate = new FormControl(this.education.endDate || null, [
            Validators.required
        ]);
        this.city = new FormControl(this.education.city || '', [
            Validators.required
        ]);
        this.country = new FormControl(this.education.country || '', [
            Validators.required
        ]);
        this.createFormGroup();
    }

    private createFormGroup(): void {
        this.educationForm = this.fb.group({
            name: this.name,
            specialization: this.specialization,
            startDate: this.startDate,
            endDate: this.endDate,
            city: this.city,
            country: this.country
        });

        if (this.parentFormSubmitted) {
            this.name.markAsTouched({onlySelf: true});
            this.specialization.markAsTouched({onlySelf: true});
            this.startDate.markAsTouched({onlySelf: true});
            this.endDate.markAsTouched({onlySelf: true});
            this.city.markAsTouched({onlySelf: true});
            this.country.markAsTouched({onlySelf: true});
        }

        this.educationForm
            .valueChanges
            .subscribe((result) => {
                Object.keys(result).forEach((key) => {
                    this.education[key] = result[key];
                });
                this.education.valid = this.educationForm.valid;
            });
    }

}
