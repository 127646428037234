import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {IApiReceiveResponse} from '../interfaces';
import {IDonationMade} from '../interfaces/donation-made';

@Injectable({
  providedIn: 'root'
})
export class DonationMadeService {

    constructor(private apiService: ApiService) {
    }

    getDonationMades(params: any): Observable<IApiReceiveResponse<IDonationMade>> {
        return this.apiService.get<IApiReceiveResponse<IDonationMade>>('donation-made/receive/all', {params}, true);
    }

    getDonationMade(donationMadeId: string): Observable<IDonationMade> {
      return this.apiService.get<IDonationMade>(`donation-made/receive/${donationMadeId}`, {}, true);
    }
}
