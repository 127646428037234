import {Directive, HostListener, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from '../services';

@Directive({
    selector: '[appPictureLoad]',
})
export class PictureLoadDirective implements OnInit, OnDestroy {
    loaded = false;

    @HostListener('load')
    onLoad() {
        this.loaded = true;
        this.sharedService.emitLoaderChange(false);
    }

    @HostListener('error')
    onError() {
        this.loaded = true;
        this.sharedService.emitLoaderChange(false);
    }

    constructor(private sharedService: SharedService) {
    }

    ngOnInit(): void {
        this.sharedService.emitLoaderChange(true);
    }

    ngOnDestroy(): void {
        if (!this.loaded) {
            this.sharedService.emitLoaderChange(false);
        }
    }
}
