import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SharedService, UserService} from '../services';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private sharedService: SharedService,
                private userService: UserService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const token: string = this.sharedService.getToken();
            let redirectUrl = '/home';

            if (state.url.indexOf('gift-card') > -1) {
                redirectUrl = '/gift-card/unauthorized';
            }

            if (!token) {
                this.router.navigate([redirectUrl]).then();
                return resolve(false);
            }

            this.userService
                .getProfile()
                .subscribe((user) => {
                    if (route.routeConfig.path === 'artist' && user.roleName !== 'artist') {
                        this.router.navigate([redirectUrl]).then();
                        return resolve(false);
                    }
                    return resolve(true);
                }, (err) => {
                    if (err.status === 401) {
                        this.sharedService.removeToken();
                        this.router.navigate([redirectUrl]).then();
                    }
                    return resolve(false);
                });
        });
    }
}
