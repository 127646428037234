import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FavoriteService} from '../../services';
import {IFavoriteList} from '../../interfaces';

@Component({
    selector: 'app-favorite-list-form',
    templateUrl: './favorite-list-form.component.html',
    styleUrls: ['./favorite-list-form.component.scss']
})
export class FavoriteListFormComponent implements OnInit {
    @Input() favoriteList: IFavoriteList;
    @Output() changed = new EventEmitter<string>();
    favoriteListForm: FormGroup;

    constructor(private fb: FormBuilder,
                private favoriteService: FavoriteService) {
        this.createFormGroup();
    }

    ngOnInit() {
        if (this.favoriteList.favoriteListId) {
            this.favoriteListForm.patchValue(this.favoriteList);
        }
    }

    saveFavoriteList(): void {
        if (this.favoriteListForm.invalid) {
            return;
        }

        const favoriteList: IFavoriteList = this.favoriteListForm.getRawValue();

        if (!favoriteList.description) {
            delete favoriteList.description;
        }

        if (this.favoriteList.favoriteListId) {
            this.favoriteService
                .updateFavoriteList(this.favoriteList.favoriteListId, favoriteList)
                .subscribe(() => {
                    this.changed.emit(this.favoriteList.favoriteListId);
                });
            return;
        }
        this.favoriteService
            .addFavoriteList(favoriteList)
            .subscribe(({id}) => {
                this.changed.emit(id);
            });
    }

    private createFormGroup(): void {
        this.favoriteListForm = this.fb.group({
            name: ['', [Validators.required]],
            description: [],
        });
    }
}
