import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DonationMadeComponent} from '../donation-made/donation-made.component';

const routes: Routes = [
    {
        path: 'donations',
        component: DonationMadeComponent,
        children: [
            {path: 'donation-made', component: DonationMadeComponent},
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DonationMadeRoutingModule {
}
