import {Component, OnInit} from '@angular/core';
import {SocialService} from '../../services';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-social-login',
    templateUrl: './social-login.component.html',
    styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {
    facebookAuthorizeUrl: string;
    googleAuthorizeUrl: string;
    twitterAuthorizeUrl: string;
    linkedinAuthorizeUrl: string;
    instagramAuthorizeUrl: string;

    constructor(private socialService: SocialService) {
    }

    ngOnInit() {
        this.getSocialAuthorizeUrl('facebook', 'facebookAuthorizeUrl');
        this.getSocialAuthorizeUrl('google', 'googleAuthorizeUrl');
        this.getSocialAuthorizeUrl('twitter', 'twitterAuthorizeUrl');
        this.getSocialAuthorizeUrl('linkedin', 'linkedinAuthorizeUrl');
    }

    private getSocialAuthorizeUrl(socialName: string, key: string) {
        const redirectUrl = `${environment.clientUrl}/auth/social-redirect/${socialName}`;
        this.socialService
            .getSocialAuthorizeUrl(socialName, redirectUrl)
            .subscribe((res) => {
                this[key] = res.url;
            });
    }

}
