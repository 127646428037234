import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';

@Pipe({
    name: 'picture'
})
export class PicturePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) {
            return '';
        }

        return `${environment.apiUrl}/api/pictures/picture/${value}`;
    }

}
