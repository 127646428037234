import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ArtistRoutingModule} from './artist-routing.module';
import {ArtistComponent} from './artist.component';
import {AddEditArtworkReviewComponent} from './add-edit-artwork-review/add-edit-artwork-review.component';
import {ArtistProfileComponent} from './artist-profile/artist-profile.component';
import {ArtworkReviewListComponent} from './artwork-review-list/artwork-review-list.component';
import {SoldArtworksComponent} from './sold-artworks/sold-artworks.component';
import {SharedModule} from '../shared-module/shared.module';

@NgModule({
    declarations: [
        ArtistComponent,
        AddEditArtworkReviewComponent,
        ArtistProfileComponent,
        ArtworkReviewListComponent,
        SoldArtworksComponent
    ],
    imports: [
        CommonModule,
        ArtistRoutingModule,
        SharedModule
    ]
})
export class ArtistModule {
}
