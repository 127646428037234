import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared-module/services';
import { Title } from "@angular/platform-browser";
@Component({
    selector: 'app-return-refund-policy',
    templateUrl: './return-refund-policy.component.html',
    styleUrls: ['./return-refund-policy.component.scss']
})
export class ReturnRefundPolicyComponent implements OnInit, AfterViewInit {

    constructor(private sharedService: SharedService,
        private title: Title) {
    }

    ngOnInit() {
        this.title.setTitle('Return And Refund Policy')
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }
}
