import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IUser} from '../interfaces';
import {SharedService, UserService} from '../services';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProfileResolverService implements Resolve<IUser> {

    constructor(private userService: UserService, private sharedService: SharedService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IUser> {
        if (!this.sharedService.getToken()) {
            return of(null);
        }
        return this.userService.getProfile().pipe(
            catchError(() => {
                return of(null);
            })
        );
    }
}
