import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ArtworkListComponent} from './artwork-list.component';
import {
    ArtistsResolverService,
    CategoriesResolverService, ColorsResolverService,
    MaterialsResolverService,
    MediumsResolverService, ProfileResolverService,
    StylesResolverService,
    SubjectsResolverService
} from '../shared-module/resolvers';
import {SoldArtworksListComponent} from './sold-artworks-list/sold-artworks-list.component';

const routes: Routes = [
    {
        path: 'artwork-list',
        component: ArtworkListComponent,
        resolve: {
            categories: CategoriesResolverService,
            styles: StylesResolverService,
            subjects: SubjectsResolverService,
            mediums: MediumsResolverService,
            materials: MaterialsResolverService,
            colors: ColorsResolverService,
            artists: ArtistsResolverService,
            user: ProfileResolverService
        },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'sold-artworks',
        component: SoldArtworksListComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ArtworkListRoutingModule {
}
