import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ArtistArtworkListModule} from './artist-artwork-list-module/artist-artwork-list.module';
import {ArtistListModule} from './artist-list-module/artist-list.module';
import {ArtistModule} from './artist-module/artist.module';
import {ArtistReviewModule} from './artist-review-module/artist-review.module';
import {ArtworkListModule} from './artwork-list-module/artwork-list.module';
import {ArtworkModule} from './artwork-module/artwork.module';
import {AuthModule} from './auth-module/auth.module';
import {CartModule} from './cart-module/cart.module';
import {HomeModule} from './home-module/home.module';
import {SearchModule} from './search-module/search.module';
import {SharedModule} from './shared-module/shared.module';
import {StaticPagesModule} from './static-pages-module/static-pages.module';
import {UserModule} from './user-module/user.module';
import {GiftCardModule} from './gift-card-module/gift-card.module';
import {DonationsModule} from './donations-module/donations.module';
import {DonationMadeModule} from './donations-module/donation-made/donation-made.module';
import {ArtCenterModule} from './art-center-module/art-center.module';
import {TeamModule} from './team-module/team.module';
import {PartnerModule} from './partner-module/partner.module';
import {LoaderModule} from './loader/loader.module';
import {FilteredLinkModule} from './filtered-link-module/filtered-link.module';
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ArtistArtworkListModule,
        ArtistListModule,
        ArtistModule,
        ArtistReviewModule,
        ArtworkListModule,
        ArtworkModule,
        AuthModule,
        GiftCardModule,
        CartModule,
        HomeModule,
        SearchModule,
        DonationsModule,
        DonationMadeModule,
        ArtCenterModule,
        SharedModule,
        StaticPagesModule,
        UserModule,
        BrowserAnimationsModule,
        TeamModule,
        PartnerModule,
        LoaderModule.forRoot(),
        FilteredLinkModule,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
