import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appInputEvent]'
})
export class InputEventDirective {
    @Output() changed = new EventEmitter<boolean>();
    inputTimeout: any;

    @HostListener('input')
    onInput() {
        this.changed.emit(false);
        if (this.inputTimeout) {
            clearTimeout(this.inputTimeout);
        }

        this.inputTimeout = setTimeout(() => {
            this.changed.emit(true);
        }, 4000);
    }

    @HostListener('keypress', ['$event'])
    onEnter(event: any) {
        if (event.code === 'Enter') {
            if (this.inputTimeout) {
                clearTimeout(this.inputTimeout);
            }

            this.inputTimeout = setTimeout(() => {
                this.changed.emit(true);
            }, 0);
        }
    }

    constructor() {
    }
}
