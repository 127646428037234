import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IMaterial} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class MaterialService {

    constructor(private apiService: ApiService) {
    }

    getMaterials(params: any): Observable<IApiReceiveResponse<IMaterial>> {
        return this.apiService.get<IApiReceiveResponse<IMaterial>>('materials/receive/all', {params}, true);
    }
}
