import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home.component';
import {IndexComponent} from './index/index.component';
import {NotFoundPageComponent} from './not-found-page/not-found-page.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: 'home',
                component: IndexComponent
            },
            {
                path: 'not-found',
                component: NotFoundPageComponent
            }
        ],
    },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule {
}
