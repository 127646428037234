import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import * as moment from 'moment-timezone';
import {Observable, merge} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';

import {ArtworkService, SharedService, UserService} from '../../shared-module/services';
import {IApiReceiveResponse, IArtwork, IUser} from '../../shared-module/interfaces';
import {MatDialog} from '@angular/material/dialog';
import {PromoCodeComponent} from '../../shared-module/components/promo-code/promo-code.component';
import {LoaderService} from '../../loader/loader.service';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, AfterViewInit {
    user: IUser = null;
    sliderArtworks: IArtwork[] = [];
    featuredArtworks: IArtwork[] = [];
    newArtworks: IArtwork[] = [];
    soldArtworks: IArtwork[] = [];

    constructor(private artworkService: ArtworkService,
                private dialog: MatDialog,
                private sharedService: SharedService,
                private userService: UserService,
                protected readonly loaderService: LoaderService,
                readonly ngZone: NgZone) {
    }

    ngOnInit() {
        this.ngZone.run(() => {
            this.loaderService.showLoader();
            this.onRequestingInitialData().pipe(
                finalize(() => {
                    this.loaderService.hideLoader();
                }),
            ).subscribe();
            this.getProfile();
        });
    }

    protected onRequestingInitialData(): Observable<unknown> {

        return merge(
            this.getSliderArtworks(),
            this.getFeaturedArtworks(),
            this.getNewArtworks (),
            this.getSoldArtworks(),
        );
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    private getProfile(): void {

        if (this.sharedService.getToken()) {
            this.userService
                .getProfile()
                .subscribe(() => {
                }, (err) => {
                    if (err.status === 401) {
                        this.initSubscribeDialog();
                    }
                });
        } else {
            this.initSubscribeDialog();
        }

    }


    private initSubscribeDialog(): void {
        const key = `subscribe_${moment.utc().format('DD-MM-YYYY')}`;
        const value = localStorage.getItem(key);

        if (!value && window.innerWidth > 480) {
            setTimeout(() => {
                const dialogRef = this.dialog.open(PromoCodeComponent, {
                    autoFocus: false,
                    disableClose: true,
                    panelClass: 'promo-code-panel',
                    backdropClass: 'promo-code-backdrop'
                });
                dialogRef.afterClosed().subscribe(() => {
                    localStorage.setItem(key, 'opened');
                });
            }, 1000)

        }
    }

    getSliderArtworks(): Observable<IApiReceiveResponse<IArtwork>> {
        return this.artworkService.getSliderArtworks()
            .pipe(
                tap((data) => this.sliderArtworks = data.results)
            );
    }

    getFeaturedArtworks(): Observable<IApiReceiveResponse<IArtwork>> {
        return this.artworkService.getFeaturedArtworks()
            .pipe(
                tap((data) => this.featuredArtworks = data.results)
            );
    }

    getNewArtworks (): Observable<IApiReceiveResponse<IArtwork>> {
        return this.artworkService.getNewArtworks ()
            .pipe(
                tap((data) => this.newArtworks  = data.results)
            );  
    }

    getSoldArtworks(): Observable<IApiReceiveResponse<IArtwork>> {
        return this.artworkService.getSoldArtworks()
            .pipe(
                tap((data) => this.soldArtworks = data.results)
            );
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }
}
