import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IAddressBookItem, IApiReceiveResponse, IApiResponse} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class AddressBookService {

    constructor(private apiService: ApiService) {
    }

    getAddressBookItems(): Observable<IApiReceiveResponse<IAddressBookItem>> {
        return this.apiService.get<IApiReceiveResponse<IAddressBookItem>>(`address-book-items/receive/all`, {}, true);
    }

    getAddresses(): Observable<IApiReceiveResponse<IAddressBookItem>> {
        return this.apiService.get<IApiReceiveResponse<IAddressBookItem>>(`address-book-items/receive/all`, {}, true);
    }

    getAddressBookItem(addressBookItemId: string): Observable<IAddressBookItem> {
        return this.apiService.get<IAddressBookItem>(`address-book-items/receive/${addressBookItemId}`, {}, true);
    }

    addAddressBookItem(data: IAddressBookItem): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`address-book-items/add`, data, {}, true, true);
    }

    updateAddressBookItem(addressBookItemId: string, data: IAddressBookItem): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`address-book-items/update/${addressBookItemId}`, data, {}, true, true);
    }

    deleteAddressBookItem(addressBookItemId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`address-book-items/delete/${addressBookItemId}`, {}, true, true);
    }
}
