import { AbstractControl } from '@angular/forms';

import { asapScheduler, merge, of, scheduled, EMPTY } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { fromMethodCall } from '../src/app/utils/rx-js-helpers';

export const propagateControlErrors = (source: AbstractControl, receiver: AbstractControl, async = false) => {
  const errorChanges = merge(
    scheduled(of(null), asapScheduler),
    source.statusChanges
  );

  const finalStream = async ? errorChanges.pipe(debounceTime(0)) : errorChanges;
  finalStream.subscribe(
    () => receiver.setErrors(source.invalid ? source.errors ?? {} : null)
  );
};

export const propagateControlTouched = (source: AbstractControl, receiver: AbstractControl, all = false) => {
  merge(
    source.touched ? scheduled(of(null), asapScheduler) : EMPTY,
    fromMethodCall(source, 'markAsTouched')
  ).subscribe(
    () => all ? receiver.markAllAsTouched() : receiver.markAsTouched()
  );
};

export const propagateControlUntouched = (source: AbstractControl, receiver: AbstractControl, all = false) => {
  merge(
    source.touched ? scheduled(of(null), asapScheduler) : EMPTY,
    fromMethodCall(source, 'markAsUntouched')
  ).subscribe(
    () => all ? receiver.markAsUntouched() : receiver.markAsUntouched()
  );
};
