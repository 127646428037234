import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {IArtist} from '../interfaces';
import {ArtistService} from '../services';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ArtistResolverService {

    constructor(private artistService: ArtistService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IArtist> {
        return this.artistService.getArtist();
    }
}
