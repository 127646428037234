import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SearchComponent} from './search.component';
import {ProfileResolverService} from '../shared-module/resolvers';

const routes: Routes = [
    {
        path: 'search',
        component: SearchComponent,
        resolve: {
            user: ProfileResolverService
        }
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule { }
