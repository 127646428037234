import {AfterViewInit, Component, OnInit} from '@angular/core';
import {OrderService} from '../../shared-module/services/order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {IDonation, IOrder} from '../../shared-module/interfaces';
import {GiftCardService} from '../../shared-module/services/gift-card.service';

@Component({
    selector: 'app-order-review',
    templateUrl: './order-review.component.html',
    styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent implements OnInit, AfterViewInit {
    order: IOrder;
    giftCardBalance = 0;
    donation: IDonation;

    constructor(private orderService: OrderService,
                private giftCardService: GiftCardService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    ngOnInit() {
        combineLatest(
            this.route.params,
            this.route.queryParams,
        ).pipe(
            map(([params, queryParams]) => ({...params, ...queryParams})),
            switchMap(params => this.orderService.getOrder(params.orderId, params.donationId && {donationId: params.donationId}))
        ).subscribe((order) => {
            this.order = order;
            this.donation = order.donation;
            const paypalBuyerId = this.route.snapshot.queryParams.PayerID;
            if (paypalBuyerId) {
                this.confirmOrder(paypalBuyerId);
            } else {
                this.getGiftCardBalance();
            }
        });
    }

    private getGiftCardBalance(): void {
        this.giftCardService
            .getGiftCardBalance()
            .subscribe(({balance}) => {
                this.giftCardBalance = balance;
            });
    }

    confirmOrder(paypalBuyerId?: string) {
        const data: any = {};
        const params: any = {};

        if (paypalBuyerId) {
            data.paypalBuyerId = paypalBuyerId;
        }

        if (this.donation) {
            if(this.donation.donationId) {
                params.donationId = this.donation.donationId;
            }
        }


        this.orderService
            .confirmOrder(this.order.orderId, data, params)
            .subscribe(() => {
                if (params?.donationId) {
                    this.router.navigate(['/cart/order-confirmation', this.order.orderId], {queryParams: { donationId: this.donation.donationId }});
                } else {
                    this.router.navigate(['/cart/order-confirmation', this.order.orderId]);
                }

            }, () => {
                if (params?.donationId) {
                    this.router.navigate(['/cart/order-confirmation', this.order.orderId], {queryParams: { donationId: this.donation.donationId }});
                } else {
                    this.router.navigate(['/cart/order-confirmation', this.order.orderId]);
                }
            });
    }

    placeOrder() {
        const params: any = {};

        if (this.order.paymentMethodType === 'credit_card') {
            this.confirmOrder();
        }

        if (this.order.paymentMethodType === 'paypal') {
            if (this.donation) {
                if(this.donation.donationId) {
                    params.donationId = this.donation.donationId;
                }
            }

            this.orderService
                .createPaypalOrder(this.order.orderId, params)
                .subscribe(({url}) => {
                    window.location.href = url;
                });
        }
    }
}
