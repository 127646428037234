import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IUser, ICompany, IApiResponse} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private apiService: ApiService) {
    }

    getProfile(): Observable<IUser> {
        return this.apiService.get<IUser>('users/profile', {});
    }


    updateProfile(user: IUser): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>('users/update/profile', user, {}, true, true);
    }

    updatePassword(data: any): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>('users/update/password', data, {}, true, true);
    }

    getCompany(): Observable<ICompany> {
        return this.apiService.get<ICompany>('company/receive', {}, true);
    }
}
