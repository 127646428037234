import {Component, OnInit} from '@angular/core';
import {FavoriteService} from '../../../shared-module/services';
import {ActivatedRoute} from '@angular/router';
import {IFavoriteList} from '../../../shared-module/interfaces';
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-wish-list-item',
    templateUrl: './wish-list-item.component.html',
    styleUrls: ['./wish-list-item.component.scss']
})
export class WishListItemComponent implements OnInit {
    favoriteList: IFavoriteList;

    constructor(private favoriteService: FavoriteService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.params.pipe(
            switchMap((params) => this.favoriteService.getFavoriteListAndArtworks(params.favoriteListId))
        ).subscribe(favoriteList => this.favoriteList = favoriteList);
    }

    deleteFavoriteArtwork(favoriteArtworkId: string): void {
        this.favoriteService
            .deleteFavoriteArtwork(favoriteArtworkId)
            .subscribe(() => window.location.reload());
    }
}
