import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ArtistReviewRoutingModule} from './artist-review-routing.module';
import {ArtistReviewComponent} from './artist-review.component';
import {ArtistArtworkReviewListComponent} from './artist-artwork-review-list/artist-artwork-review-list.component';
import {ArtistReviewDetailsComponent} from './artist-review-details/artist-review-details.component';
import {ArtistReviewUnauthorizedComponent} from './artist-review-unauthorized/artist-review-unauthorized.component';
import {ArtistReviewCompleteComponent} from './artist-review-complete/artist-review-complete.component';
import {SharedModule} from '../shared-module/shared.module';
import { FateModule, FateMaterialModule } from 'fate-editor';

@NgModule({
    declarations: [
        ArtistReviewComponent,
        ArtistArtworkReviewListComponent,
        ArtistReviewCompleteComponent,
        ArtistReviewDetailsComponent,
        ArtistReviewUnauthorizedComponent
    ],
    imports: [
        CommonModule,
        ArtistReviewRoutingModule,
        SharedModule,
        FateModule,
        FateMaterialModule
    ]
})
export class ArtistReviewModule {
}
