import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    ICategory,
    IStyle,
    ISubject,
    IMedium,
    IMaterial,
    IColor,
    IArtwork,
    IPicture,
    IArtist,
    ICompany
} from '../../shared-module/interfaces';
import {
    StyleService,
    SubjectService,
    MediumService,
    MaterialService,
    ArtworkService,
    PictureService,
    ValidatorService
} from '../../shared-module/services';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import faker from 'faker';

@Component({
    selector: 'app-artwork-review-form',
    templateUrl: './add-edit-artwork-review.component.html',
    styleUrls: ['./add-edit-artwork-review.component.scss']
})
export class AddEditArtworkReviewComponent implements OnInit, AfterViewInit {
    artwork: IArtwork;
    artist: IArtist;
    categories: ICategory[];
    colors: IColor[];
    company: ICompany;
    styles: IStyle[] = [];
    subjects: ISubject[] = [];
    mediums: IMedium[] = [];
    materials: IMaterial[] = [];
    artworkReviewForm: FormGroup;
    name: FormControl;
    description: FormControl;
    year: FormControl;
    width: FormControl;
    height: FormControl;
    depth: FormControl;
    weight: FormControl;
    basePrice: FormControl;
    size: FormControl;
    categoryId: FormControl;
    sizes: string[] = ['small', 'medium', 'large', 'oversized'];
    parentFormSubmitted = false;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private subjectService: SubjectService,
                private mediumService: MediumService,
                private materialService: MaterialService,
                private styleService: StyleService,
                private artworkService: ArtworkService,
                private validatorService: ValidatorService,
                private pictureService: PictureService) {
    }

    ngOnInit() {
        this.categories = this.route.snapshot.data.categories;
        this.colors = this.route.snapshot.data.colors;
        this.company = this.route.snapshot.data.company;
        this.artist = this.route.snapshot.data.artist;
        if (this.route.snapshot.params.artworkId) {
            this.artwork = this.route.snapshot.data.artwork;
        } else {
            this.artwork = {
                pictures: [],
                styles: [],
                subjects: [],
                mediums: [],
                materials: [],
                colors: [],
            };
        }
        if (!this.artwork.artworkId && !environment.production) {
            // fake start
            if (!this.artwork.name) {
                this.artwork.name = faker.lorem.words(2);
            }

            if (!this.artwork.year) {
                this.artwork.year = faker.random.number({min: 1980, max: 2019});
            }

            if (!this.artwork.width) {
                this.artwork.width = faker.random.number({min: 10, max: 100});
            }

            if (!this.artwork.height) {
                this.artwork.height = faker.random.number({min: 10, max: 50});
            }

            if (!this.artwork.depth) {
                this.artwork.depth = faker.random.number({min: 10, max: 40});
            }

            if (!this.artwork.weight) {
                this.artwork.weight = faker.random.number({min: 1, max: 30});
            }

            if (!this.artwork.basePrice) {
                this.artwork.basePrice = faker.random.number({min: 300, max: 10000, precision: 50});
            }

            if (!this.artwork.categoryId) {
                this.artwork.categoryId = this.categories[faker.random.number({min: 0, max: this.categories.length - 1})].categoryId;
            }

            if (!this.artwork.size) {
                this.artwork.size = this.sizes[faker.random.number({min: 0, max: this.sizes.length - 1})];
            }

            if (!this.artwork.colors.length) {
                const numbers = this.colors.map((color, index) => index);
                const random1 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                numbers.splice(random1, 1);
                const random2 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                numbers.splice(random2, 1);
                const random3 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                numbers.splice(random3, 1);
                this.artwork.colors.push(this.colors[random1]);
                this.artwork.colors.push(this.colors[random2]);
                this.artwork.colors.push(this.colors[random3]);
            }

            if (!this.artwork.description) {
                this.artwork.description = faker.lorem.words(60);
            }
            // fake end
        }

        if (this.artwork.categoryId) {
            this.getStyles(this.artwork.categoryId);
            this.getSubjects(this.artwork.categoryId);
            this.getMaterials(this.artwork.categoryId);
            this.getMediums(this.artwork.categoryId);
        }
        this.createFormControls();
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    categoryChanged(): void {
        this.getStyles(this.categoryId.value);
        this.getSubjects(this.categoryId.value);
        this.getMaterials(this.categoryId.value);
        this.getMediums(this.categoryId.value);
    }

    private getStyles(categoryId: string) {
        this.styles = [];
        this.styleService
            .getStyles({categoryId})
            .subscribe(({results}) => {
                this.styles = results;
                if (!environment.production) {
                    if (!this.artwork.styles.length && this.styles.length) {
                        const numbers = this.styles.map((style, index) => index);
                        const random1 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random1, 1);
                        const random2 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random2, 1);
                        const random3 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random3, 1);
                        this.artwork.styles.push(this.styles[random1]);
                        this.artwork.styles.push(this.styles[random2]);
                        this.artwork.styles.push(this.styles[random3]);
                    }
                }
            });
    }

    private getSubjects(categoryId: string) {
        this.subjects = [];
        this.subjectService
            .getSubjects({categoryId})
            .subscribe(({results}) => {
                this.subjects = results;
                if (!environment.production) {
                    if (!this.artwork.subjects.length && this.subjects.length) {
                        const numbers = this.subjects.map((subject, index) => index);
                        const random1 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random1, 1);
                        const random2 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random2, 1);
                        const random3 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random3, 1);
                        this.artwork.subjects.push(this.subjects[random1]);
                        this.artwork.subjects.push(this.subjects[random2]);
                        this.artwork.subjects.push(this.subjects[random3]);
                    }
                }
            });
    }

    private getMediums(categoryId: string) {
        this.mediums = [];
        this.mediumService
            .getMediums({categoryId})
            .subscribe(({results}) => {
                this.mediums = results;
                if (!environment.production) {
                    if (!this.artwork.mediums.length && this.mediums.length) {
                        const numbers = this.mediums.map((medium, index) => index);
                        const random1 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random1, 1);
                        const random2 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random2, 1);
                        const random3 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random3, 1);
                        this.artwork.mediums.push(this.mediums[random1]);
                        this.artwork.mediums.push(this.mediums[random2]);
                        this.artwork.mediums.push(this.mediums[random3]);
                    }
                }
            });
    }

    private getMaterials(categoryId: string) {
        this.materials = [];
        this.materialService
            .getMaterials({categoryId})
            .subscribe(({results}) => {
                this.materials = results;
                if (!environment.production) {
                    if (!this.artwork.materials.length && this.materials.length) {
                        const numbers = this.materials.map((material, index) => index);
                        const random1 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random1, 1);
                        const random2 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random2, 1);
                        const random3 = numbers[faker.random.number({min: 0, max: numbers.length - 1})];
                        numbers.splice(random3, 1);
                        this.artwork.materials.push(this.materials[random1]);
                        this.artwork.materials.push(this.materials[random2]);
                        this.artwork.materials.push(this.materials[random3]);
                    }
                }
            });
    }

    addEditArtwork(): void {
        this.parentFormSubmitted = true;
        if (!this.checkArtworkReviewForm()) {
            return;
        }
        const artwork: any = {
            name: this.name.value,
            description: this.description.value,
            year: this.year.value,
            width: this.width.value,
            height: this.height.value,
            depth: this.depth.value,
            weight: this.weight.value,
            basePrice: this.basePrice.value,
            size: this.size.value,
            categoryId: this.categoryId.value
        };
        artwork.styles = this.artwork.styles.map(style => style.styleId);
        artwork.subjects = this.artwork.subjects.map(subject => subject.subjectId);
        artwork.mediums = this.artwork.mediums.map(medium => medium.mediumId);
        artwork.materials = this.artwork.materials.map(material => material.materialId);
        artwork.colors = this.artwork.colors.map(color => color.colorId);
        if (!this.artwork.artworkId) {
            this.uploadPictures(this.artwork.pictures)
                .then((pictures) => {
                    artwork.pictures = pictures;
                    return this.addArtwork(artwork);
                })
                .then(() => {
                    this.navigate();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            artwork.artworkId = this.artwork.artworkId;
            artwork.addedPictures = this.artwork.pictures.filter(picture => picture.added);
            artwork.deletedPictures = this.artwork.pictures.filter(picture => picture.deleted).map(picture => picture.pictureId);

            this.uploadPictures(artwork.addedPictures)
                .then((pictures) => {
                    artwork.addedPictures = pictures;
                    return this.updateArtwork(artwork);
                })
                .then((data) => {
                    this.navigate();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    navigate(): void {
        if (this.artist.status !== 'approved') {
            this.router.navigate(['/artist-review/artwork-list']).then();
        } else {
            this.router.navigate(['/artist/artwork-list'], {queryParams: {offset: 0, limit: 20}}).then();
        }
    }

    private addArtwork(artwork: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.artworkService
                .addArtwork(artwork)
                .subscribe(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
        });
    }

    private updateArtwork(artwork: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.artworkService
                .updateArtwork(artwork.artworkId, artwork)
                .subscribe(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
        });
    }

    private uploadPictures(pictures: IPicture[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const files = pictures.map((picture) => {
                return picture.file;
            });

            const data: any[] = [];

            let index = 0;
            const save = (items) => {
                const item = items[index];
                this.pictureService
                    .uploadPicture([item])
                    .subscribe((res) => {
                        data.push(res[0]);
                        index++;

                        if (items[index]) {
                            save(items);
                        } else {
                            resolve(data);
                        }
                    }, (err) => {
                        reject(err);
                    });
            };

            if (files.length) {
                save(files);
            } else {
                resolve(data);
            }
        });
    }

    chipAutocompleteChanged(event: any[], type: string): void {
        this.artwork[type] = event;
        this.checkArtworkReviewForm();
    }

    checkArtworkReviewForm(): boolean {
        const picturesLength = this.artwork.pictures.filter(picture => !picture.deleted).length;
        return Boolean(this.artworkReviewForm.valid &&
            picturesLength &&
            picturesLength >= this.company.artworkPictureFrom &&
            picturesLength <= this.company.artworkPictureTo &&
            this.artwork.subjects.length &&
            this.artwork.subjects.length >= 1 &&
            this.artwork.subjects.length <= this.company.maxArtworkSubject &&
            this.artwork.mediums.length &&
            this.artwork.mediums.length >= 1 &&
            this.artwork.mediums.length <= this.company.maxArtworkMedium &&
            this.artwork.materials.length &&
            this.artwork.materials.length >= 1 &&
            this.artwork.materials.length <= this.company.maxArtworkMaterial &&
            this.artwork.colors.length &&
            this.artwork.colors.length >= 1 &&
            this.artwork.colors.length <= this.company.maxArtworkColor &&
            this.artwork.styles.length &&
            this.artwork.styles.length >= 1 &&
            this.artwork.styles.length <= this.company.maxArtworkStyle);
    }

    private createFormControls(): void {
        this.name = new FormControl(this.artwork.name || '', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(40)
        ]);
        this.description = new FormControl(this.artwork.description || '', [
            Validators.minLength(20)
        ]);
        this.year = new FormControl(this.artwork.year || null, [
            Validators.required
        ]);
        this.width = new FormControl(this.artwork.width || null, [
            Validators.required,
            Validators.min(1),
            this.validatorService.integerNumber
        ]);
        this.height = new FormControl(this.artwork.height || null, [
            Validators.required,
            Validators.min(1),
            this.validatorService.integerNumber
        ]);
        this.depth = new FormControl(this.artwork.depth || null, [
            Validators.required,
            Validators.min(1),
            this.validatorService.integerNumber
        ]);
        this.weight = new FormControl(this.artwork.weight || null, [
            Validators.required,
            Validators.min(0.05)
        ]);
        this.basePrice = new FormControl(this.artwork.basePrice || null, [
            Validators.required,
            Validators.min(1)
        ]);
        this.categoryId = new FormControl(this.artwork.categoryId || '', [Validators.required]);
        this.size = new FormControl(this.artwork.size || '', [Validators.required]);
        this.createFormGroup();
    }

    private createFormGroup(): void {
        this.artworkReviewForm = this.fb.group({
            name: this.name,
            description: this.description,
            year: this.year,
            width: this.width,
            height: this.height,
            depth: this.depth,
            weight: this.weight,
            basePrice: this.basePrice,
            categoryId: this.categoryId,
            size: this.size
        }, {validator: this.validatorService.maxShippingWeight});
    }
}
