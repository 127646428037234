import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ArtistListComponent} from './artist-list.component';
import {MaterialsResolverService, MediumsResolverService, StylesResolverService, SubjectsResolverService} from '../shared-module/resolvers';

const routes: Routes = [
    {
        path: 'artist-list',
        component: ArtistListComponent,
        resolve: {
            styles: StylesResolverService,
            subjects: SubjectsResolverService,
            mediums: MediumsResolverService,
            materials: MaterialsResolverService
        }
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArtistListRoutingModule { }
