import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserRoutingModule} from './user-routing.module';
import {SharedModule} from '../shared-module/shared.module';
import {AddressBookComponent} from './address-book/address-book.component';
import {BidHistoryComponent} from './bid-history/bid-history.component';
import {BillingsHistoryComponent} from './billings-history/billings-history.component';
import {FavoriteArtistsComponent} from './favorite-artists/favorite-artists.component';
import {OffersHistoryComponent} from './offers-history/offers-history.component';
import {OrderDetailsComponent} from './order-details/order-details.component';
import {OrdersHistoryComponent} from './orders-history/orders-history.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {WishListComponent} from './wish-list/wish-list.component';
import {SaveWishListComponent} from './wish-list/save-wish-list/save-wish-list.component';
import {WishListInviteComponent} from './wish-list/wish-list-invite/wish-list-invite.component';
import {UserComponent} from './user.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { GiftCardsComponent } from './gift-cards/gift-cards.component';
import { WishListItemComponent } from './wish-list/wish-list-item/wish-list-item.component';
import { GiftCardOrdersComponent } from './gift-cards/gift-card-orders/gift-card-orders.component';
import { ReceivedGiftCardsComponent } from './gift-cards/received-gift-cards/received-gift-cards.component';
import { UsedGiftCardsComponent } from './gift-cards/used-gift-cards/used-gift-cards.component';
import { DonationsHistoryComponent } from './donations-history/donations-history.component';
import { DonationDetailsComponent } from './donation-details/donation-details.component';

@NgModule({
    declarations: [
        UserComponent,
        AddressBookComponent,
        BidHistoryComponent,
        BillingsHistoryComponent,
        FavoriteArtistsComponent,
        OffersHistoryComponent,
        OrderDetailsComponent,
        OrdersHistoryComponent,
        UserProfileComponent,
        WishListComponent,
        SaveWishListComponent,
        WishListInviteComponent,
        PaymentOptionsComponent,
        GiftCardsComponent,
        WishListItemComponent,
        GiftCardOrdersComponent,
        ReceivedGiftCardsComponent,
        UsedGiftCardsComponent,
        DonationsHistoryComponent,
        DonationDetailsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        UserRoutingModule
    ]
})
export class UserModule {
}
