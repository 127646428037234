import { ComponentType } from '@angular/cdk/overlay';
import { Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { noop } from 'rxjs';

export const provideValueAccessor = <T>(componentType: ComponentType<T>, property: keyof T | null = null): Provider => {
    if (property === null) {
        return { provide: NG_VALUE_ACCESSOR, useExisting: componentType, multi: true };
    }

    return {
        provide: NG_VALUE_ACCESSOR,
        useFactory: (instance: T) => instance[property],
        deps: [componentType],
        multi: true
    };
};


export const createValueAccessor = <T>(
    registerOnChange: (fn: (x: T) => void) => void,
    writeValue: (value: T) => void = noop,
    registerOnTouched: (fn: () => void) => void = noop,
    setDisabledState: (disabled: boolean) => void = noop
): ControlValueAccessor => ({
    writeValue,
    registerOnChange,
    registerOnTouched,
    setDisabledState
});
