import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {IApiResponse, IAuthResponse} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private apiService: ApiService) {
    }

    login(data: any): Observable<IAuthResponse> {
        return this.apiService.post<IAuthResponse>('auth/user/login', data, {}, true);
    }

    register(data: any): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('auth/user/register', data, {}, true);
    }

    logout(): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('auth/user/logout', {}, {}, true);
    }

    forgotPassword(email: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('auth/user/forgot-password', {email}, {}, true);
    }

    resetPassword(data: any): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('auth/user/reset-password', data, {}, true);
    }

    verifyUser(email: string, verificationCode: string): Observable<IAuthResponse> {
        return this.apiService.post<IAuthResponse>('auth/user/verify-user', {email, verificationCode}, {}, true);
    }

    resendVerifyEmail(email: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('auth/user/send-verify-email', {email}, {}, true, true);
    }
}
