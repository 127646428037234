import { Component, OnInit, AfterViewInit } from '@angular/core';
import {SharedService} from '../../shared-module/services';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-facts-about-armenia',
  templateUrl: './facts-about-armenia.component.html',
  styleUrls: ['./facts-about-armenia.component.scss']
})
export class FactsAboutArmeniaComponent implements OnInit, AfterViewInit {

    constructor(private sharedService: SharedService,
                private title: Title) {
    }

    ngOnInit() {
      this.title.setTitle('Armenia Unknown');
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

}
