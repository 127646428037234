import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {VerifyUserComponent} from './verify-user/verify-user.component';
import {SocialRedirectComponent} from './social-redirect/social-redirect.component';
import {AnonymousGuard} from '../shared-module/guards/anonymous.guard';

const routes: Routes = [
    {
        path: 'auth',
        component: AuthComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [AnonymousGuard]
            },
            {
                path: 'register',
                component: RegisterComponent,
                canActivate: [AnonymousGuard]
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
                canActivate: [AnonymousGuard]
            },
            {
                path: 'reset-password/:email/:resetPasswordCode',
                component: ResetPasswordComponent,
                canActivate: [AnonymousGuard]
            },
            {
                path: 'verify-user/:email/:verificationCode',
                component: VerifyUserComponent,
                canActivate: [AnonymousGuard]
            },
            {
                path: 'social-redirect/:type',
                component: SocialRedirectComponent,
                canActivate: [AnonymousGuard]
            },
            { path: '', redirectTo: '/auth/login', pathMatch: 'full'},
        ]
    },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
