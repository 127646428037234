import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ArtistComponent} from './artist.component';
import {AuthGuard} from '../shared-module/guards';
import {ArtistProfileComponent} from './artist-profile/artist-profile.component';
import {ArtworkReviewListComponent} from './artwork-review-list/artwork-review-list.component';
import {SoldArtworksComponent} from './sold-artworks/sold-artworks.component';
import {AddEditArtworkReviewComponent} from './add-edit-artwork-review/add-edit-artwork-review.component';
import {
    ArtistArtworkResolverService,
    ArtistResolverService,
    CategoriesResolverService,
    ColorsResolverService,
    CompanyResolverService
} from '../shared-module/resolvers';

const routes: Routes = [
    {
        path: 'artist',
        component: ArtistComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'profile-preview',
                component: ArtistProfileComponent,
            },
            {
                path: 'update-profile',
                component: ArtistProfileComponent,
            },
            {
                path: 'update-biography',
                component: ArtistProfileComponent,
            },
            {
                path: 'update-educations',
                component: ArtistProfileComponent,
            },
            {
                path: 'update-exhibitions',
                component: ArtistProfileComponent,
            },
            {
                path: 'artwork-list',
                component: ArtworkReviewListComponent,
            },
            {
                path: 'sold-artworks',
                component: SoldArtworksComponent
            },
            {
                path: 'add-artwork',
                component: AddEditArtworkReviewComponent,
                canActivate: [AuthGuard],
                resolve: {
                    categories: CategoriesResolverService,
                    colors: ColorsResolverService,
                    company: CompanyResolverService,
                    artist: ArtistResolverService
                }
            },
            {
                path: 'update-artwork/:artworkId',
                component: AddEditArtworkReviewComponent,
                canActivate: [AuthGuard],
                resolve: {
                    categories: CategoriesResolverService,
                    colors: ColorsResolverService,
                    company: CompanyResolverService,
                    artist: ArtistResolverService,
                    artwork: ArtistArtworkResolverService
                }
            },
        ]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArtistRoutingModule { }
