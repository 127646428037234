import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {IAuthResponse, ISocialAuthResponse} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class SocialService {

    constructor(private apiService: ApiService) {
    }

    getSocialAuthorizeUrl(socialName: string, redirectUrl): Observable<ISocialAuthResponse> {
        return this.apiService.get<ISocialAuthResponse>(`socials/${socialName}/authorize/url?redirectUrl=${redirectUrl}`, {}, true);
    }

    authorizeFacebook(code: string, redirectUrl: string): Observable<IAuthResponse> {
        return this.apiService.post<IAuthResponse>('socials/facebook/authorize', {code, redirectUrl}, {}, true);
    }

    authorizeGoogle(code: string, redirectUrl: string): Observable<IAuthResponse> {
        return this.apiService.post<IAuthResponse>('socials/google/authorize', {code, redirectUrl}, {}, true);
    }

    authorizeLinkedin(code: string, state: string, redirectUrl: string): Observable<IAuthResponse> {
        return this.apiService.post<IAuthResponse>('socials/linkedin/authorize', {code, state, redirectUrl}, {}, true);
    }

    authorizeTwitter(oauthToken: string, oauthVerifier: string, redirectUrl: string): Observable<IAuthResponse> {
        return this.apiService.post<IAuthResponse>('socials/twitter/authorize', {
            oauthToken,
            oauthVerifier,
            redirectUrl
        }, {}, true);
    }
}
