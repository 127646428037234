import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DonationsComponent} from './donations.component';
import {DonationConfirmedComponent} from './donation-confirmed/donation-confirmed.component';
import {DonationFailedComponent} from './donation-failed/donation-failed.component';
import {DonationAngelsComponent} from './donation-angels/donation-angels.component';
import {MakeDonationComponent} from './make-donation/make-donation.component';
import {DonationMadeItemComponent} from './donation-made-item/donation-made-item.component';
import {DonationPaypalRedirectComponent} from './donation-paypal-redirect/donation-paypal-redirect.component';

const routes: Routes = [
    {
        path: 'donations',
        component: DonationsComponent,
        children: [
            {path: 'make-donation', component: MakeDonationComponent},
            {path: 'donation-confirmed/:donationId', component: DonationConfirmedComponent},
            {path: 'donation-failed/:donationId', component: DonationFailedComponent},
            {path: 'angels', component: DonationAngelsComponent},
            {path: 'donation-paypal-redirect/:donationId', component: DonationPaypalRedirectComponent},
            {path: '', redirectTo: '/donations/make-donation', pathMatch: 'full'},
            {path: 'donation-made/:donationMadeId', component: DonationMadeItemComponent},
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DonationsRoutingModule {
}
