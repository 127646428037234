import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CartRoutingModule} from './cart-routing.module';
import {CartComponent} from './cart.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {OrderConfirmationComponent} from './order-confirmation/order-confirmation.component';
import {OrderReviewComponent} from './order-review/order-review.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {SharedModule} from '../shared-module/shared.module';
import { CheckoutUnauthorizedComponent } from './checkout-unauthorized/checkout-unauthorized.component';

@NgModule({
    declarations: [
        CartComponent,
        CheckoutComponent,
        OrderConfirmationComponent,
        OrderReviewComponent,
        ShoppingCartComponent,
        CheckoutUnauthorizedComponent
    ],
    imports: [
        CommonModule,
        CartRoutingModule,
        SharedModule
    ]
})
export class CartModule {
}
