import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {IApiResponse} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class MailService {

    constructor(private apiService: ApiService) {
    }

    subscribe(email: any): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('mail/subscribe', {email}, {}, true, true);
    }

    contact(data: any): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('mail/contact', data, {}, true, true);
    }
}
