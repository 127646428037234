import { Component, Input } from '@angular/core';
import {StreamState} from '../../interfaces/stream-state';
import {AudioService} from '../../services/audio.service';

@Component({
  selector: 'app-music-button-toolbar',
  templateUrl: './music-button-toolbar.component.html',
  styleUrls: ['./music-button-toolbar.component.scss']
})
export class MusicButtonToolbarComponent {

    files: Array<any> = [];
    state: StreamState;
    currentFile: any = {};
    show = false;

    @Input() muziqPlayerPosition = '';
    @Input() musicPage = Boolean;
    constructor(private audioService: AudioService) {
        // get media files
        audioService.getFiles().subscribe(files => {
            this.files = files;
        });

        // listen to stream state
        this.audioService.getState()
            .subscribe(state => {
                this.state = state;
                // console.log(state)
            });
    }

    pause() {
        this.audioService.pause();
        this.show = true;
    }

    play() {
        this.audioService.play();
    }

    stop() {
        this.audioService.stop();
    }

    next() {
        this.audioService.next()
    }

    previous() {
        this.audioService.previous()
    }

    isFirstPlaying() {
    }

    isLastPlaying() {
        return this.currentFile.index === this.files.length - 1;
    }

    // isFirstPlaying() {
    //     return this.currentFile.index === 0;
    // }
    //
    // isLastPlaying() {
    //     return this.currentFile.index === this.files.length - 1;
    // }

    onSliderChangeEnd(change) {
        this.audioService.seekTo(change.value);
    }

    showHideMusicInfo(){
        this.show = !this.show;
        console.log(this.show)
    }

}
