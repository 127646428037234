import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService, SharedService} from '../services';

@Injectable({
    providedIn: 'root'
})
export class ArtistReviewUnauthorizedGuard implements CanActivate {

    constructor(private userService: UserService,
                private sharedService: SharedService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (!this.sharedService.getToken()) {
                return resolve(true);
            }
            this.userService
                .getProfile()
                .subscribe(() => {
                    this.navigate();
                    resolve(false);
                }, () => {
                    return resolve(true);
                });
        });
    }

    private navigate(): void {
        setTimeout(() => {
            this.router.navigate(['/artist-review/details']).then();
        }, 0);
    }
}
