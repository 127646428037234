export default {
    email_required: 'Email is required',
    email_invalid: 'Email is invalid',
    email_unique: 'Email already registered',
    email_unverified: 'User is not verified',
    email_not_found: 'Email is not registered',
    password_required: 'Password is required',
    email_or_password_incorrect: 'Email or password is incorrect',
    first_name_required: 'First name is required',
    donation_amount_required: 'Amount is required',
    first_name_min_length: '2 characters minimum',
    first_name_max_length: '40 characters maximum',
    first_name_invalid: 'First name is invalid',
    last_name_required: 'Last name is required',
    last_name_min_length: '2 characters minimum',
    last_name_max_length: '40 characters maximum',
    last_name_invalid: 'Last name is invalid',
    missing_lowercase: 'Missing lowercase character',
    missing_uppercase: 'Missing uppercase character',
    missing_numeric: 'Missing numeric character',
    missing_non_alphanumeric: 'Missing non alphanumeric character',
    password_min_length: '8 characters minimum',
    password_max_length: '24 characters maximum',
    confirm_password_required: 'Confirm password is required',
    confirm_password_mismatch: 'Passwords don\'t match',
    phone_required: 'Phone is required',
    phone_invalid: 'Phone is invalid',
    primary_phone_required: 'Primary phone number is required',
    primary_phone_invalid: 'Primary phone number is invalid',
    phone_unique: 'Phone already registered',
    address_required: 'Address is required',
    city_required: 'City is required',
    state_required: 'State is required',
    country_required: 'Country is required',
    pseudonym_required: 'Pseudonym is required',
    date_of_birth_required: 'Date of birth is required',
    about_required: 'About is required',
    from_the_artist_required: 'From the artist is required',
    education_name_required: 'Name is required',
    education_from_year_required: 'From year is required',
    education_to_year_required: 'To year is required',
    education_specialization_required: 'Specialization is required',
    exhibition_name_required: 'Name is required',
    exhibition_date_required: 'Date is required',
    exhibition_type_required: 'Type is required',
    profile_picture_required: 'Profile picture is required',
    about_min_length: '20 characters minimum',
    from_the_artist_min_length: '20 characters minimum',
    pseudonym_min_length: '2 characters minimum',
    pseudonym_max_length: '40 characters maximum',
    artwork_pictures_required: 'Pictures is required',
    artwork_pictures_min: '{args} images minimum',
    artwork_pictures_max: '{args} images maximum',
    artwork_name_required: 'Name is required',
    artwork_name_min_length: '2 characters minimum',
    artwork_name_max_length: '40 characters maximum',
    artwork_year_required: 'Year is required',
    artwork_width_required: 'Width is required',
    artwork_height_required: 'Height is required',
    artwork_depth_required: 'Depth is required',
    artwork_weight_required: 'Weight is required',
    artwork_height_min: 'Minimum 1 cm',
    artwork_width_min: 'Minimum 1 cm',
    artwork_depth_min: 'Minimum 1 cm',
    artwork_weight_min: 'Minimum 0.1 kg',
    artwork_height_max: 'Maximum 300 cm',
    artwork_width_max: 'Maximum 300 cm',
    artwork_depth_max: 'Maximum 50 cm',
    artwork_weight_max: 'Maximum 50 kg',
    artwork_base_price_required: 'Price is required',
    artwork_category_required: 'Category is required',
    artwork_size_required: 'Size is required',
    artwork_style_required: 'Styles is required',
    artwork_subject_required: 'Subjects is required',
    artwork_medium_required: 'Mediums is required',
    artwork_material_required: 'Materials is required',
    artwork_color_required: 'Colors is required',
    artwork_style_max: 'Maximum {args} style',
    artwork_subject_max: 'Maximum {args} subject',
    artwork_medium_max: 'Maximum {args} medium',
    artwork_material_max: 'Maximum {args} material',
    artwork_color_max: 'Maximum {args} color',
    current_password_required: 'Current password is required',
    postal_code_required: 'Postal code is required',
    second_address_required: 'Address 2 is required',
    artist_specialization_required: 'Specialization is required',
    required_integer_number: 'Round to the nearest whole number',
    artwork_name_invalid: 'English text only',
    max_shipping_size: 'Out of shipping standard size',
    min_artist_age: 'To register you should be at least 18 years old',
    gender_required: 'Gender is required',
    approved: 'Approved',
    pending_admin_review: 'Pending',
    pending_jury_review: 'Pending',
    pending_artist_review: 'Requires an update',
    gift_card_amount_required: 'Amount is required',
    recipient_name_required: 'Recipient name is required',
    sender_name_required: 'Sender name is required',
    card_name_required: 'Cardholder name is required',
    address_book_item_added: 'Address was successfully added',
    address_book_item_updated: 'Address was successfully updated',
    address_book_item_deleted: 'Address was successfully deleted',
    payment_method_added: 'Payment method was successfully added',
    payment_method_updated: 'Payment method was successfully updated',
    payment_method_deleted: 'Payment method was successfully deleted',
    recipient_email_required: 'Recipient email is required',
    gift_card_order_failed: 'Order declined',
    order_failed: 'Order declined',
    gift_card_code_invalid: 'Gift card code is invalid',
    cart_item_deleted: 'Item was successfully deleted',
    promo_code_invalid: 'Promo code is invalid',
    promo_code_required: 'Promo code is required',
    unauthorized: 'Your session was expired',
    offer_sent: 'Offer was successfully submitted',
    offer_approved: 'Offer was approved',
    offer_declined: 'Offer was declined',
    all_fields_required: 'Please fill all required fields'
};
