import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MailService} from '../../shared-module/services/mail.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidatorService} from '../../shared-module/services';
import {Router} from '@angular/router';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterViewInit {
    subjects: string[] = [
        'I\'M AN ARTISTS & I HAVE A QUESTION',
        'I\'M A CUSTOMER & I HAVE A QUESTION',
        'OTHER'
    ];
    contactForm: FormGroup;

    constructor(private mailService: MailService,
                private validatorService: ValidatorService,
                private router: Router,
                private fb: FormBuilder,
                private title: Title) {
        this.createFormGroup();
    }

    get currentYear() {
        return new Date().getFullYear();
    }

    ngOnInit() {
        this.title.setTitle('Contact Us');
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    contact(): void {
        if (this.contactForm.invalid) {
            return;
        }

        const contactData = this.contactForm.getRawValue();

        this.mailService
            .contact(contactData)
            .subscribe(() => {
                this.router.navigate(['/home']);
            });
    }

    private createFormGroup(): void {
        this.contactForm = this.fb.group({
            email: ['', [Validators.required, this.validatorService.email]],
            fullName: ['', [Validators.required]],
            subject: ['', [Validators.required]],
            message: ['', [Validators.required]],
        });
    }
}
