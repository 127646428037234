import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ArtistReviewComponent} from './artist-review.component';
import {
    ArtistArtworkResolverService,
    ArtistResolverService,
    CategoriesResolverService, ColorsResolverService,
    CompanyResolverService,
    ProfileResolverService,
    SpecializationResolverService
} from '../shared-module/resolvers';
import {ArtistReviewUnauthorizedComponent} from './artist-review-unauthorized/artist-review-unauthorized.component';
import {ArtistReviewAuthorizedGuard, ArtistReviewUnauthorizedGuard} from '../shared-module/guards';
import {ArtistReviewDetailsComponent} from './artist-review-details/artist-review-details.component';
import {ArtistArtworkReviewListComponent} from './artist-artwork-review-list/artist-artwork-review-list.component';
import {AddEditArtworkReviewComponent} from '../artist-module/add-edit-artwork-review/add-edit-artwork-review.component';
import {ArtistReviewCompleteComponent} from './artist-review-complete/artist-review-complete.component';

const routes: Routes = [
    {
        path: 'artist-review',
        component: ArtistReviewComponent,
        resolve: {
            user: ProfileResolverService,
            artist: ArtistResolverService
        },
        children: [
            {
                path: 'unauthorized',
                component: ArtistReviewUnauthorizedComponent,
                canActivate: [ArtistReviewUnauthorizedGuard],
                resolve: {
                    company: CompanyResolverService
                }
            },
            {
                path: 'details',
                component: ArtistReviewDetailsComponent,
                canActivate: [ArtistReviewAuthorizedGuard],
                resolve: {
                    specializations: SpecializationResolverService,
                    user: ProfileResolverService,
                    artist: ArtistResolverService
                }
            },
            {
                path: 'artwork-list',
                component: ArtistArtworkReviewListComponent,
                canActivate: [ArtistReviewAuthorizedGuard],
                resolve: {
                    company: CompanyResolverService,
                    artist: ArtistResolverService
                },
            },
            {
                path: 'add-artwork',
                component: AddEditArtworkReviewComponent,
                canActivate: [ArtistReviewAuthorizedGuard],
                resolve: {
                    categories: CategoriesResolverService,
                    colors: ColorsResolverService,
                    company: CompanyResolverService,
                    artist: ArtistResolverService
                }
            },
            {
                path: 'update-artwork/:artworkId',
                component: AddEditArtworkReviewComponent,
                canActivate: [ArtistReviewAuthorizedGuard],
                resolve: {
                    categories: CategoriesResolverService,
                    colors: ColorsResolverService,
                    company: CompanyResolverService,
                    artist: ArtistResolverService,
                    artwork: ArtistArtworkResolverService
                }
            },
            {
                path: 'complete',
                component: ArtistReviewCompleteComponent,
                canActivate: [ArtistReviewAuthorizedGuard],
            },
            {path: '', redirectTo: '/artist-review/details', pathMatch: 'full'},
        ]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArtistReviewRoutingModule { }
