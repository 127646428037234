import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {StaticPagesRoutingModule} from './static-pages-routing.module';
import {AboutUsComponent} from './about-us/about-us.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {CookiesPolicyComponent} from './cookies-policy/cookies-policy.component';
import {CopyrightPolicyComponent} from './copyright-policy/copyright-policy.component';
import {DisclaimerComponent} from './disclaimer/disclaimer.component';
import {FactsAboutArmeniaComponent} from './facts-about-armenia/facts-about-armenia.component';
import {FaqComponent} from './faq/faq.component';
import {IncomeTaxDeductibleArtComponent} from './income-tax-deductible-art/income-tax-deductible-art.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {ReturnRefundPolicyComponent} from './return-refund-policy/return-refund-policy.component';
import {TaxDeductibleComponent} from './tax-deductible/tax-deductible.component';
import {TermsConditionsComponent} from './terms-conditions/terms-conditions.component';
import {StaticPageComponent} from './static-page.component';
import {SharedModule} from '../shared-module/shared.module';

@NgModule({
    declarations: [
        AboutUsComponent,
        ContactUsComponent,
        CookiesPolicyComponent,
        CopyrightPolicyComponent,
        DisclaimerComponent,
        FactsAboutArmeniaComponent,
        FaqComponent,
        IncomeTaxDeductibleArtComponent,
        PrivacyPolicyComponent,
        ReturnRefundPolicyComponent,
        TaxDeductibleComponent,
        TermsConditionsComponent,
        StaticPageComponent
    ],
    imports: [
        CommonModule,
        StaticPagesRoutingModule,
        SharedModule
    ]
})
export class StaticPagesModule {
}
