import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ArtCenterService} from '../../shared-module/services/art-center.service';

@Component({
    selector: 'app-art-center-item',
    templateUrl: './art-center-item.component.html',
    styleUrls: ['./art-center-item.component.scss']
})
export class ArtCenterItemComponent implements OnInit {
    private artCenterItemId: string;
    params: any = {};
    artCenterItem: any = {};
    selectedSlide: any;
    selectedSlideCards: any;
    gallery: any;
    slide: number;
    selectedCard: any;

    constructor(
        private route: ActivatedRoute,
        private artCenterService: ArtCenterService,
        ) {
    }

    ngOnInit() {
        this.route.params
            .subscribe((params) => {
                this.artCenterItemId = params.artCenterId;
                this.getArtCenterData();
            });
    }

    getArtCenterData(){
        this.artCenterService
            .getArtCenterItem(this.artCenterItemId)
            .subscribe((artCenterData) => {
                this.artCenterItem = artCenterData;
                this.gallery = artCenterData.artCenterPictures;
                this.selectCard(artCenterData.artCenterPictures[0].artCenterPictureId);
                this.selectSlide(1);
                document.querySelector('mat-drawer-content').scrollTo(0, 0);
            });
    }

    selectCard(cardId) {
        const data = this.gallery.find(item => item.artCenterPictureId === cardId);
        this.selectedCard = data.picture;
    }

    selectSlide(slide) {
        this.selectedSlide = slide;
        const gallery = this.gallery;
        this.selectedSlideCards = gallery;
        if (slide === 1) {
            this.selectedSlideCards = gallery.slice(1, 4);
        }
        if (slide === 2) {
            this.selectedSlideCards = gallery.slice(4, 7);
        }
    }
}
