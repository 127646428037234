import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ArtworkListRoutingModule} from './artwork-list-routing.module';
import {ArtworkListComponent} from './artwork-list.component';
import {SharedModule} from '../shared-module/shared.module';
import {ArtworkListFiltersComponent} from './components/artwork-list-filters/artwork-list-filters.component';
import {SoldArtworksListComponent} from './sold-artworks-list/sold-artworks-list.component';

@NgModule({
    declarations: [
        ArtworkListComponent,
        ArtworkListFiltersComponent,
        SoldArtworksListComponent
    ],
    imports: [
        CommonModule,
        ArtworkListRoutingModule,
        SharedModule
    ]
})
export class ArtworkListModule {
}
