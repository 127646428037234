import {Component, Input} from '@angular/core';
import {IArtwork, IUser} from '../../interfaces';

@Component({
    selector: 'app-artwork-item',
    templateUrl: './artwork-item.component.html',
    styleUrls: ['./artwork-item.component.scss']
})
export class ArtworkItemComponent {
    @Input() artwork: IArtwork;
    @Input() user: IUser;

    constructor() {
    }
}
