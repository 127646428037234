import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PartnerRoutingModule} from './partner-routing.module';
import {OurPartnersComponent} from './our-partners/our-partners.component';
import {SharedModule} from '../shared-module/shared.module';

@NgModule({
    declarations: [OurPartnersComponent],
    imports: [
        CommonModule,
        SharedModule,
        PartnerRoutingModule
    ]
})
export class PartnerModule {
}
