import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class ValidatorService {

    constructor() {
    }

    email(control: AbstractControl): { [key: string]: boolean } | null {
        const email = control.value;
        if (!email) {
            return null;
        }
        // tslint:disable-next-line:max-line-length
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase()) ? null : {invalid: true};
    }

    phone(control: AbstractControl): { [key: string]: boolean } | null {
        const phone = control.value;
        if (!phone) {
            return null;
        }

        // tslint:disable-next-line:max-line-length
        return new RegExp('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')
            .test(`+${String(phone)}`) ? null : {invalid: true};
    }

    onlyAlpha(control: AbstractControl): { [key: string]: boolean } | null {
        const value = control.value;
        if (!value) {
            return null;
        }

        // tslint:disable-next-line:max-line-length
        return new RegExp('^[a-zA-Z ]+$')
            .test(value) ? null : {invalid: true};
    }

    password(control: AbstractControl): { [key: string]: boolean } | null {
        const password = control.value;
        if (!password) {
            return null;
        }

        if (!new RegExp('^(?=.*[a-z])').test(password)) {
            return {missing_lowercase: true};
        }

        if (!new RegExp('^(?=.*[A-Z])').test(password)) {
            return {missing_uppercase: true};
        }

        if (!new RegExp('^(?=.*[0-9])').test(password)) {
            return {missing_numeric: true};
        }

        const re = /[\"\ \!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^_\`\{\|\}\~\"]/;
        if (!re.test(password)) {
            return {missing_non_alphanumeric: true};
        }

        return null;
    }

    confirmPassword(group: FormGroup): any {
        const password = group.get('password').value;
        const confirmPassword = group.get('confirmPassword').value;
        if (!password) {
            return null;
        }

        if (!confirmPassword) {
            return null;
        }

        if (password === confirmPassword) {
            return null;
        }
        group.get('confirmPassword').setErrors({confirm_password_mismatch: true});
        return {confirm_password_mismatch: true};
    }

    integerNumber(control: AbstractControl): { [key: string]: boolean } | null {
        const value = control.value;
        if (!value) {
            return null;
        }

        return Number.isInteger(value) ? null : {invalid: true};
    }

    minArtistAge(control: AbstractControl): { [key: string]: boolean } | null {
        const value = control.value;
        if (!value) {
            return null;
        }

        const age = moment().diff(value.valueOf(), 'years');
        if (age >= 18) {
            return null;
        }

        return {min_artist_age: true};
    }

    maxShippingWeight(group: FormGroup): any {
        const width = group.get('width').value;
        const height = group.get('height').value;
        const depth = group.get('depth').value;
        const weight = group.get('depth').value;

        if (!width || !height || !depth || !weight) {
            return null;
        }

        const dimensionalWeight = (width * height * depth) / 5000;

        if (weight && dimensionalWeight && weight <= 300 && dimensionalWeight <= 300) {
            group.setErrors(null);
            return null;
        }
        group.setErrors({max_shipping_size: true});
        return {max_shipping_size: true};
    }
}
