import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DonationMadeService} from '../../shared-module/services/donation-made.service';
import {IDonationMade} from '../../shared-module/interfaces/donation-made';
import * as FileSaver from 'file-saver';
@Component({
    selector: 'app-donation-made-item',
    templateUrl: './donation-made-item.component.html',
    styleUrls: ['./donation-made-item.component.scss']
})
export class DonationMadeItemComponent implements OnInit {
    private donationMadeId: string;
    params: any = {};
    donationMade: IDonationMade;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private donationMadeService: DonationMadeService) {
    }

    ngOnInit() {
        this.route.params
            .subscribe((params) => {
                this.donationMadeId = params.donationMadeId;
                this.getDonationMade();
            });
    }

    getDonationMade(){
        this.donationMadeService
            .getDonationMade(this.donationMadeId)
            .subscribe((donationMade) => {
                this.donationMade = donationMade;
                document.querySelector('mat-drawer-content').scrollTo(0, 0);
            });
    }

    generatePDF(){
        const pdfUrl = './assets/pdf/AAP_PDF.pdf';
        const pdfName = 'AAP_DONATION';
        FileSaver.saveAs(pdfUrl, pdfName);
    }
}
