import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GiftCardRoutingModule} from './gift-card-routing.module';
import {GiftCardComponent} from './gift-card.component';
import {SharedModule} from '../shared-module/shared.module';
import { BuyGiftCardComponent } from './buy-gift-card/buy-gift-card.component';
import { GiftCardCheckoutComponent } from './gift-card-checkout/gift-card-checkout.component';
import { BuyGiftCardCompleteComponent } from './buy-gift-card-complete/buy-gift-card-complete.component';
import { BuyGiftCardReviewComponent } from './buy-gift-card-review/buy-gift-card-review.component';
import { GiftCardUnauthorizedComponent } from './gift-card-unauthorized/gift-card-unauthorized.component';

@NgModule({
    declarations: [GiftCardComponent, BuyGiftCardComponent, GiftCardCheckoutComponent, BuyGiftCardCompleteComponent, BuyGiftCardReviewComponent, GiftCardUnauthorizedComponent],
    imports: [
        CommonModule,
        GiftCardRoutingModule,
        SharedModule
    ]
})
export class GiftCardModule {
}
