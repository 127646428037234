import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-remove-offer',
  templateUrl: './remove-offer.component.html',
  styleUrls: ['./remove-offer.component.scss']
})
export class RemoveOfferComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<RemoveOfferComponent>) { }

  ngOnInit() {
  }

    onClose(): void {
        this.dialogRef.close();
    }

}
