import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IGiftCard} from '../../shared-module/interfaces';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-buy-gift-card-complete',
  templateUrl: './buy-gift-card-complete.component.html',
  styleUrls: ['./buy-gift-card-complete.component.scss']
})
export class BuyGiftCardCompleteComponent implements OnInit, AfterViewInit {
    giftCard: IGiftCard;

    constructor(private route: ActivatedRoute) {
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    ngOnInit(): void {
        this.giftCard = this.route.snapshot.data.giftCard;
    }

}
