import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
    selector: 'app-chip-autocomplete',
    templateUrl: './chip-autocomplete.component.html',
    styleUrls: ['./chip-autocomplete.component.scss']
})
export class ChipAutocompleteComponent implements OnInit, OnChanges {
    @Input() values: any[];
    @Input() selectedValues: any[];
    @Input() key: string;
    @Input() heading: string;
    @Input() mode: string;
    @Input() prefix: string;
    @Input() validate: boolean;
    @Input() max: number;
    @Input() parentFormSubmitted: boolean;
    @Output() valueChange = new EventEmitter<any[]>();
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    valueControl: FormControl;
    filteredValues: any[];

    @ViewChild('valueInput') valueInput: ElementRef<HTMLInputElement>;
    @ViewChild('valueAuto') matValueAutocomplete: MatAutocomplete;

    constructor() {
    }

    ngOnInit() {
        this.valueControl = new FormControl('');
        if (this.parentFormSubmitted) {
            this.valueControl.markAsTouched({onlySelf: true});
        }
        this.filter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.parentFormSubmitted && this.valueControl) {
            this.valueControl.markAsTouched({onlySelf: true});
        }
    }

    filter() {
        this.filteredValues = [];
        this.values.forEach((valueItem) => {
            let exists = false;
            this.selectedValues.forEach((selectedValue) => {
                if (valueItem[this.key] === selectedValue[this.key]) {
                    exists = true;
                }
            });

            if (!exists) {
                this.filteredValues.push(valueItem);
            }
        });
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.selectedValues.push(event.option.value);
        this.valueInput.nativeElement.value = '';
        this.valueControl.setValue(null);
        this.filter();
        this.valueInput.nativeElement.blur();
        this.valueChange.emit(this.selectedValues);
        return;
    }

    remove(index: number): void {
        this.selectedValues.splice(index, 1);
        this.filter();
        this.valueInput.nativeElement.blur();
        this.valueChange.emit(this.selectedValues);
        return;
    }
}
