import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';

import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {IndexComponent} from './index/index.component';
import {NotFoundPageComponent} from './not-found-page/not-found-page.component';
import {SharedModule} from '../shared-module/shared.module';
import {LoaderModule} from '../loader/loader.module';

@NgModule({
    declarations: [
        HomeComponent,
        IndexComponent,
        NotFoundPageComponent,
    ],
    imports: [
        MatDialogModule,
        HomeRoutingModule,
        SharedModule,
        LoaderModule
    ],
    exports: [],
    entryComponents: []
})
export class HomeModule {
}
