import {Component, OnInit} from '@angular/core';
import {IOrder, IUser} from '../../shared-module/interfaces';
import {OrderService} from '../../shared-module/services/order.service';
import {ActivatedRoute} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
    order: IOrder;
    user: IUser;

    constructor(private orderService: OrderService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.user = this.route.snapshot.data.user;
        this.route.params.pipe(
            switchMap(params => this.orderService.getOrder(params.orderId))
        ).subscribe((order) => {
            this.order = order;
        });
    }
}
