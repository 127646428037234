import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ArtistService} from '../../services';
import {IArtist} from '../../interfaces';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-update-artist-profile',
    templateUrl: './update-artist-profile.component.html',
    styleUrls: ['./update-artist-profile.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ],
})
export class UpdateArtistProfileComponent implements OnInit {
    @Input() artist: IArtist;
    @Output() artistChanged = new EventEmitter<void>();
    updateArtistProfileForm: FormGroup;
    pseudonym: FormControl;
    dateOfBirth: FormControl;
    about: FormControl;
    fromTheArtist: FormControl;
    parentFormSubmitted = false;
    selectedSpecializations: any[] = [];
    specializations: any[] = [];

    constructor(private artistService: ArtistService,
                private fb: FormBuilder) {
    }

    ngOnInit() {
        this.createFormControls();
        this.getSpecializations();
    }

    private getSpecializations(): void {
        this.artistService
            .getSpecializations()
            .subscribe((specializations) => {
                this.specializations = specializations;
            })
    }

    chipAutocompleteChanged(event: any[]): void {
        this.selectedSpecializations = event;
    }

    updateArtistProfile() {
        this.parentFormSubmitted = true;
        if (!this.updateArtistProfileForm.valid) {
            return;
        }

        const data: IArtist = {
            specializations: this.selectedSpecializations.map(specialization => specialization.specializationId),
            pseudonym: this.pseudonym.value,
            dateOfBirth: this.dateOfBirth.value.valueOf(),
            about: this.about.value,
            fromTheArtist: this.fromTheArtist.value
        };

        this.artistService
            .updateArtistProfile(data)
            .subscribe(() => {
                this.artistChanged.emit();
                this.parentFormSubmitted = false;
            });
    }

    private createFormControls() {
        this.selectedSpecializations = this.artist.specializations || [];
        this.pseudonym = new FormControl(this.artist.pseudonym || '', [
            Validators.minLength(2),
            Validators.maxLength(40),
        ]);
        this.dateOfBirth = new FormControl(this.artist.dateOfBirth ? moment(Number(this.artist.dateOfBirth)) : null, [
            Validators.required
        ]);
        this.about = new FormControl(this.artist.about || '', [
            Validators.required,
            Validators.minLength(20)
        ]);
        this.fromTheArtist = new FormControl(this.artist.fromTheArtist || '', [
            Validators.required,
            Validators.minLength(20)
        ]);
        this.createFormGroups();
    }

    private createFormGroups(): void {
        this.updateArtistProfileForm = this.fb.group({
            pseudonym: this.pseudonym,
            dateOfBirth: this.dateOfBirth,
            about: this.about,
            fromTheArtist: this.fromTheArtist,
        });
    }
}
