import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ArtistArtworkListComponent} from './artist-artwork-list.component';
import {ProfileResolverService} from '../shared-module/resolvers';

const routes: Routes = [
    {
        path: 'artist-artwork-list/:artistId',
        component: ArtistArtworkListComponent,
        resolve: {
            user: ProfileResolverService
        }
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArtistArtworkListRoutingModule { }
