import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DonationService} from '../../shared-module/services/donation.service';
import {ActivatedRoute} from '@angular/router';
import {IDonation, IUser} from '../../shared-module/interfaces';
import {SharedService, UserService} from '../../shared-module/services';

@Component({
    selector: 'app-donation-confirmed',
    templateUrl: './donation-confirmed.component.html',
    styleUrls: ['./donation-confirmed.component.scss']
})
export class DonationConfirmedComponent implements OnInit, AfterViewInit {
    donation: IDonation;
    user: IUser;

    constructor(private donationService: DonationService,
                private userService: UserService,
                private sharedService: SharedService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.getProfile();
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    private getProfile(): void {
        const {donationId} = this.route.snapshot.params;
        if (!this.sharedService.getToken()) {
            this.user = null;
            this.getDonation(donationId);
            return;
        }

        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
                this.getDonation(donationId);
            }, () => {
                this.user = null;
                this.getDonation(donationId);
            });
    }

    private getDonation(donationId: string): void {
        if (this.user) {
            this.donationService
                .getDonation(donationId)
                .subscribe(donation => this.donation = donation);
        } else {
            this.donationService
                .getGuestDonation(donationId)
                .subscribe(donation => this.donation = donation);
        }
    }

}
