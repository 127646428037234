import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../services';

@Component({
    selector: 'app-page-loader',
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent implements OnInit {
    private count = 0;
    loading = false;

    constructor(private sharedService: SharedService) {
    }

    ngOnInit() {
        this.sharedService
            .onLoaderChange()
            .subscribe((loading) => {
                if (loading) {
                    setTimeout(() => {
                        this.count++;
                        this.checkLoading();
                    }, 0);
                } else {
                    setTimeout(() => {
                        if (this.count !== 0) {
                            this.count--;
                        }
                        this.checkLoading();
                    }, 1000);
                }
            });
    }

    checkLoading() {
        this.loading = Boolean(this.count > 0);
    }

}
