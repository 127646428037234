import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared-module/shared.module';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {VerifyUserComponent} from './verify-user/verify-user.component';
import {SocialRedirectComponent} from './social-redirect/social-redirect.component';

@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        VerifyUserComponent,
        SocialRedirectComponent
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        SharedModule
    ],
    entryComponents: [
    ]
})
export class AuthModule {
}
