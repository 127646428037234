import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EditDonationComponent} from '../../shared-module/dialogs/edit-donation/edit-donation.component';
import {IDonation} from '../../shared-module/interfaces';
import {DonationService} from '../../shared-module/services/donation.service';
import {ActivatedRoute} from '@angular/router';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'app-donations-history',
    templateUrl: './donations-history.component.html',
    styleUrls: ['./donations-history.component.scss']
})
export class DonationsHistoryComponent implements OnInit {
    donations: IDonation[] = [];
    count = 0;
    params: any = {};

    constructor(private donationService: DonationService,
                private dialog: MatDialog,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams.pipe(
            tap(params => this.params = params),
        ).subscribe(() => this.getDonations());
    }

    private getDonations(): void {
        this.donationService
            .getDonations(this.params)
            .subscribe(({results, count}) => {
                this.donations = results;
                this.count = count;
            });

    }

    editDonation(donationId: string): void {
        this.dialog.open(EditDonationComponent, {
            width: '718px',
            maxHeight: '448px',
            data: {donationId}
        });
    }

    cancelDonation(donationId: string): void {
    }

}
