import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-copyright-policy',
    templateUrl: './copyright-policy.component.html',
    styleUrls: ['./copyright-policy.component.scss']
})
export class CopyrightPolicyComponent implements OnInit, AfterViewInit {

    constructor(private title: Title) {
    }

    ngOnInit() {
        this.title.setTitle('Copyright Policy')
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

}
