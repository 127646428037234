import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ArtCenterService} from '../shared-module/services/art-center.service';
import { Title, Meta } from "@angular/platform-browser";
@Component({
    selector: 'app-art-center',
    templateUrl: './art-center.component.html',
    styleUrls: ['./art-center.component.scss']
})
export class ArtCenterComponent implements OnInit, AfterViewInit {
    params: any = {};
    artCenterList: any = [];
    count = 0;
    constructor(
        private artCenterService: ArtCenterService,
        private route: ActivatedRoute,
        private title: Title,
        ) {
    };

    ngOnInit(): void {
        this.title.setTitle("Mouradick Art Center");
        this.route.queryParams
            .subscribe((params) => {
                this.params = {...params};
                this.getArtCenterList(); 
        });
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    getArtCenterList() {
        this.artCenterList = [];
        this.artCenterService
            .getArtCenterList(this.params)
            .subscribe(({results, count}) => {
                this.artCenterList = results;
                this.count = count;
        });    
    }
}
