import {Component, OnInit, Inject, AfterViewInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SharedService} from '../../services';

@Component({
    selector: 'app-artwork-zoom',
    templateUrl: './artwork-zoom.component.html',
    styleUrls: ['./artwork-zoom.component.scss']
})
export class ArtworkZoomComponent implements OnInit, AfterViewInit {
    pictureUrl: any;

    constructor(private dialogRef: MatDialogRef<ArtworkZoomComponent>,
                private sharedService: SharedService,
                @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit() {
        this.sharedService.emitLoaderChange(true);
        this.sharedService.emitLoaderChange(true);
        this.pictureUrl = this.data.pictureUrl;
    }

    ngAfterViewInit(): void {
        document.querySelector('.ngxImageZoomThumbnail').addEventListener('load', (e) => {
            this.sharedService.emitLoaderChange(false);
        });
        document.querySelector('.ngxImageZoomFull').addEventListener('load', (e) => {
            this.sharedService.emitLoaderChange(false);
        });
    }

    close(): void {
        this.dialogRef.close();
    }

}
