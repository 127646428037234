import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {SharedService, UserService} from '../services';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {
    constructor(private sharedService: SharedService,
                private userService: UserService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const token: string = this.sharedService.getToken();
            let redirectUrl = '/home';

            if (state.url.indexOf('gift-card') > -1) {
                redirectUrl = '/gift-card/checkout';
            }

            if (!token) {
                return resolve(true);
            }

            this.userService
                .getProfile()
                .subscribe((user) => {
                    setTimeout(() => {
                        this.router.navigate([redirectUrl]).then();
                    }, 0);
                    return resolve(false);
                }, () => {
                    return resolve(true);
                });
        });
    }
}
