import {Component, OnInit} from '@angular/core';
import {IUser} from '../shared-module/interfaces';
import {Router} from '@angular/router';
import {ArtistReviewService, UserService, AuthService, SharedService} from '../shared-module/services';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    user: IUser;
    userProfileMenu = [
        {name: 'Profile', url: '/user/profile', params: {}},
        {name: 'Address book', url: '/user/address-book', params: {}},
        {name: 'Payment options', url: '/user/payment-options', params: {}},
        {name: 'Gift cards', url: '/user/gift-cards/orders', params: {offset: 0, limit: 20}},
        {name: 'Order history', url: '/user/orders-history', params: {offset: 0, limit: 20}},
        {name: 'Placed-offers', url: '/user/offers-history', params: {offset: 0, limit: 20}},
        {name: 'Wish list', url: '/user/wish-list', params: {offset: 0, limit: 20}},
        {name: 'Favorite artists', url: '/user/favorite-artists', params: {offset: 0, limit: 20}},
        {name: 'Donations', url: '/user/donations', params: {offset: 0, limit: 20}}
    ];
    public dropdownMenuState = false;

    constructor(private artistReviewService: ArtistReviewService,
                private router: Router,
                private userService: UserService,
                private authService: AuthService,
                private sharedService: SharedService) {
    }

    ngOnInit() {
        if (this.router.isActive('cart', true)) {
            setTimeout(() => {
                this.router.navigate(['/cart/shopping-cart']).then();
            }, 0);
        }

        const token = this.sharedService.getToken();
        if (token) {
            this.getProfile();
        }
    }

    getProfile() {
        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
            }, (err) => {
                if (err.status === 401) {
                    this.sharedService.removeToken();
                    this.user = null;
                }
                console.log(err);
            });
    }

    logout() {
        this.authService
            .logout()
            .subscribe(() => {
                this.user = null;
                this.sharedService.removeToken();
                this.router.navigate(['/home'])
                    .then(() => window.location.reload());
            });
    }

    openDropdown() {
        this.dropdownMenuState = !this.dropdownMenuState;
    }
}
