import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, SharedService, ValidatorService} from '../../shared-module/services';
import {ActivatedRoute} from '@angular/router';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    step = 1;
    private redirectUrl: string;
    errMessage: string;
    resendVerifyEmail = false;
    passwordShow = false;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private authService: AuthService,
                private validatorService: ValidatorService,
                private sharedService: SharedService,
                private title: Title) {
        this.createFormGroups();
    }

    ngOnInit() {
        this.title.setTitle('Register')
        const email: string = this.route.snapshot.queryParams.email;
        if (email) {
            this.registerForm.get('email').setValue(email);
        }
        this.redirectUrl = this.sharedService.getRedirectUrl();
    }

    resendVerification() {
        if (!this.registerForm.get('email').value) {
            return;
        }
        this.authService
            .resendVerifyEmail(this.registerForm.get('email').value)
            .subscribe(() => {
            });
    }

    inputOnlyLetters(key: string, value: string) {
        value = value.replace(/[^a-zA-Z]/, '');
        this.registerForm.get(key).setValue(value);
    }

    register(): void {
        if (!this.registerForm.valid) {
            return;
        }

        const registerData: any = this.registerForm.getRawValue();

        this.errMessage = '';

        this.authService
            .register(registerData)
            .subscribe(() => {
                this.step = 2;
            });
    }

    private createFormGroups(): void {
        this.registerForm = this.fb.group({
            firstName: ['', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(40),
                this.validatorService.onlyAlpha
            ]],
            lastName: ['', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(40),
                this.validatorService.onlyAlpha
            ]],
            email: ['', [
                Validators.required,
                this.validatorService.email
            ]],
            password: ['', [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(24),
                this.validatorService.password
            ]],
            confirmPassword: ['', [
                Validators.required
            ]]
        }, {validator: this.validatorService.confirmPassword});
    }

}
