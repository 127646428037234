import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {

    constructor(private apiService: ApiService) {
    }

    checkPromoCode(params: any): Observable<{ valid: boolean; id: string, discount: number }> {
        return this.apiService.get<{ valid: boolean }>('promo-codes/check', {params}, true);
    }
}
