import {Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import {NavService, CategoryService, SharedService, UserService, CartItemService, ArtworkService} from './shared-module/services';
import {IArtwork, ICategory, IUser} from './shared-module/interfaces';
import {MatDrawer} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
    title = 'aap-front';
    @ViewChild('appDrawer') appDrawer: MatDrawer;
    @ViewChild('subcategoryDrawer') subcategoryDrawer: MatDrawer;
    categories: ICategory[] = [];
    category: ICategory;
    user: IUser;
    artworks: IArtwork[] = [];
    count = 0;
    total = 0;
    searchForm: FormGroup;
    document: any;
    _cartItemsCount = 0;
    routerLinkIsActive(path: string) {
        return this.router.isActive(path, true);
    }

    constructor(private navService: NavService,
                private categoryService: CategoryService,
                private sharedService: SharedService,
                private userService: UserService,
                private router: Router,
                private fb: FormBuilder,
                private cartItemService: CartItemService,
                private artworkService: ArtworkService) {
        this.searchForm = this.fb.group({
            searchText: ['', Validators.required]
        });

        this.cartItemService.execChange.subscribe((value) => {
            this._cartItemsCount =  value;
        });
    }

    ngOnInit(): void {
        this.getProfile();
    }

    ngAfterViewInit() {
        this.navService.appDrawer = this.appDrawer;
        this.getCategories();
    }

    getCategories() {
        this.categoryService
            .getNestedCategories()
            .subscribe(categories => this.categories = categories);
    }

    openSubcategories(category) {
        this.category = category;
        this.subcategoryDrawer.open().then();
    }

    search() {
        if (this.searchForm.invalid) {
            return;
        }

        this.appDrawer.close()
        this.router.navigate(['/search'], {queryParams: this.searchForm.getRawValue()});
    }

    private getCartItems(): void {
        if (!this.user) {
            const localCartItems = this.cartItemService.getLocalCartItems();
            this.cartItemService.getCartItemsCount(localCartItems.length);
            if (localCartItems.length) {
                Promise
                    .all(localCartItems.map(artworkId => this.getArtwork(artworkId)))
                    .then((artworks) => {
                        this.artworks = artworks;
                        this.count = artworks.length;
                        this.total = this.artworks.reduce((sum, artwork) => sum + artwork.completedPrice, 0);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            return;
        }
        this.cartItemService
            .getCartItems()
            .subscribe(({results, count, total}) => {
                this.cartItemService.getCartItemsCount(results.length);
                this.artworks = results;
                this.count = count;
                this.total = total;
            });
    }

    private getArtwork(artworkId: string): Promise<IArtwork> {
        return new Promise<IArtwork>((resolve, reject) => {
            this.artworkService
                .getArtwork(artworkId)
                .subscribe((artwork) => {
                    resolve(artwork);
                }, (err) => {
                    reject(err);
                });
        });
    }

    getProfile() {
        if (!this.sharedService.getToken()) {
            this.user = null;
            this.getCartItems();
            return;
        }
        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
                this.getCartItems();
            });
    }
}
