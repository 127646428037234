import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {LoaderComponent} from "./loader-component/loader.component";


@Injectable()
export class LoaderService {
    private readonly overlay: Overlay;
    private overlayRef: OverlayRef;
    private _isLoading = false;

    constructor(overlay: Overlay,) {
        this.overlay = overlay;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    private get positionStrategy(): GlobalPositionStrategy {
        return this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();
    }

    showLoader(param?): void {
        if (this.isLoading) {
            return;
        }

        this._isLoading = true;
        this.overlayRef = this.overlayRef || this.overlay.create({
            positionStrategy: this.positionStrategy,
            hasBackdrop: true,
            backdropClass: !!param ? 'cdk-overlay-custom-backdrop' : 'cdk-overlay-dark-backdrop'
        });

        this.overlayRef.attach(new ComponentPortal(LoaderComponent));
    }

    hideLoader(): void {
        if (!this.isLoading) {
            return;
        }

        this._isLoading = false;
        this.overlayRef.detach();
    }
}
