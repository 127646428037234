import {Component, Input, OnInit} from '@angular/core';
import {IAddressBookItem} from '../../shared-module/interfaces';
import {AddressBookService} from '../../shared-module/services';

@Component({
    selector: 'app-address-book',
    templateUrl: './address-book.component.html',
    styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit {
    addresses: IAddressBookItem[] = [];
    addNewAddress = false;

    constructor(private addressBookService: AddressBookService) {
    }

    ngOnInit() {
        this.getAddresses();
    }

    private getAddresses() {
        this.addressBookService
            .getAddressBookItems()
            .subscribe(({results}) => {
                this.addresses = results;
                if (!this.addresses.length) {
                    this.addAddressBookItem();
                }
            });
    }

    addressBookItemChanged() {
        this.addNewAddress = false;
        this.getAddresses();
    }

    addAddressBookItem() {
        this.addNewAddress = true;
        document.getElementById('add-new-address').scrollIntoView();
    }

    deleteAddressBookItem(addressBookItem: IAddressBookItem) {
        this.addressBookService
            .deleteAddressBookItem(addressBookItem.addressBookItemId)
            .subscribe(() => {
                this.addressBookItemChanged();
            });
    }

    updateAddressBookItem(index: number) {
        this.addresses.forEach((item, i) => this.addresses[i].active = false);
        this.addresses[index].active = true;
    }

    setDefaultAddress(addressBookItemId: string) {
        const values: IAddressBookItem = {};
        values.defaultAddress = true;
        this.addressBookService
            .updateAddressBookItem(addressBookItemId, values)
            .subscribe(() => {
                this.addressBookItemChanged();
            });
    }
}
