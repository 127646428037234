import {Component} from '@angular/core';
import {AudioService} from '../../shared-module/services/audio.service';
import {StreamState} from '../../shared-module/interfaces/stream-state';

@Component({
    selector: 'app-music-room',
    templateUrl: './music-room.component.html',
    styleUrls: ['./music-room.component.scss']
})
export class MusicRoomComponent {

    musicList: Array<any> = [];
    state: StreamState;
    currentFile = {};

    constructor(private audioService: AudioService) {
        // get media files
        audioService.getFiles().subscribe(files => {
            this.musicList = files;
        });

        // listen to stream state
        this.audioService.getState()
            .subscribe(state => {
                this.state = state;
            });

        this.currentFile = this.audioService.nowPlaying()

    }

    playStream(file) {
        this.audioService.playStream(file)
            .subscribe(events => {
                // listening for fun here
            });
    }

    openFile(fileId) {
        this.playStream(fileId);
    }

}
