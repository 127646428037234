import {Component, OnInit} from '@angular/core';
import {FavoriteService} from '../../../shared-module/services';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ICustomer, IFavoriteList} from '../../../shared-module/interfaces';
import {switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-wish-list-invite',
    templateUrl: './wish-list-invite.component.html',
    styleUrls: ['./wish-list-invite.component.scss']
})
export class WishListInviteComponent implements OnInit {
    inviteForm: FormGroup;
    favoriteList: IFavoriteList;
    invites: ICustomer[] = [];
    addNewInvite = false;
    permissions: FormControl;

    constructor(private favoriteService: FavoriteService,
                private route: ActivatedRoute,
                private router: Router,
                private fb: FormBuilder) {
        this.permissions = new FormControl('', [Validators.required]);
        this.createFormGroup();
    }

    ngOnInit() {
        this.route.params.pipe(
            switchMap((params) => this.favoriteService.getFavoriteList(params.favoriteListId))
        ).subscribe((favoriteList) => {
            this.favoriteList = favoriteList;
            if (!this.favoriteList.canInvite) {
                this.router.navigate(['/user/wish-list', this.favoriteList.favoriteListId]);
            }
            this.getInvites();
        });
    }

    private getInvites(): void {
        this.favoriteService
            .getFavoriteListInvites(this.favoriteList.favoriteListId)
            .subscribe((invites) => {
                this.invites = invites;
                if (!this.invites.length) {
                    this.addNewInvite = true;
                }
            });
    }

    initInviteUpdate(invite: ICustomer): void {
        invite.active = true;
        if (invite.canInvite) {
            this.permissions.setValue(4);
            return;
        }

        if (invite.canDeleteArtwork) {
            this.permissions.setValue(3);
            return;
        }

        if (invite.canAddArtwork) {
            this.permissions.setValue(2);
            return;
        }

        if (invite.canView) {
            this.permissions.setValue(1);
            return;
        }
    }

    updateInvite(invite: ICustomer): void {
        const data: ICustomer = {
            canInvite: false,
            canDeleteArtwork: false,
            canAddArtwork: false,
            canView: false
        };

        if (this.permissions.value === 1) {
            data.canView = true;
        }

        if (this.permissions.value === 2) {
            data.canView = true;
            data.canAddArtwork = true;
        }

        if (this.permissions.value === 3) {
            data.canView = true;
            data.canAddArtwork = true;
            data.canDeleteArtwork = true;
        }

        if (this.permissions.value === 4) {
            data.canView = true;
            data.canAddArtwork = true;
            data.canDeleteArtwork = true;
            data.canInvite = true;
        }

        this.favoriteService
            .updateFavoriteListInvite(invite.customerFavoriteListId, data)
            .subscribe(() => {
                this.getInvites();
                this.permissions.setValue(null);
            });
    }

    sendInvite(): void {
        if (this.inviteForm.invalid) {
            return;
        }

        if (!this.favoriteList.canInvite) {
            return;
        }

        const data: ICustomer = this.inviteForm.getRawValue();
        if (data.permissions === 1) {
            data.canView = true;
        }

        if (data.permissions === 2) {
            data.canView = true;
            data.canAddArtwork = true;
        }

        if (data.permissions === 3) {
            data.canView = true;
            data.canAddArtwork = true;
            data.canDeleteArtwork = true;
        }

        if (data.permissions === 4) {
            data.canView = true;
            data.canAddArtwork = true;
            data.canDeleteArtwork = true;
            data.canInvite = true;
        }
        this.favoriteService
            .addFavoriteListInvite(this.favoriteList.favoriteListId, data)
            .subscribe(() => {
                this.getInvites();
                this.inviteForm.reset();
                this.addNewInvite = false;
            });
    }

    deleteInvite(invite: ICustomer): void {
        this.favoriteService
            .deleteFavoriteListInvite(invite.customerFavoriteListId)
            .subscribe(() => this.getInvites());
    }

    private createFormGroup(): void {
        this.inviteForm = this.fb.group({
            email: ['', Validators.required],
            permissions: [[], [Validators.required]]
        });
    }

}
