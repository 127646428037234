import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IArtwork} from '../../shared-module/interfaces';
import {ArtworkService} from '../../shared-module/services';
import {ActivatedRoute} from '@angular/router';
import {switchMap} from 'rxjs/operators';


@Component({
    selector: 'app-sold-artworks-list',
    templateUrl: './sold-artworks-list.component.html',
    styleUrls: ['./sold-artworks-list.component.scss']
})
export class SoldArtworksListComponent implements OnInit {
    soldArtworks: IArtwork[] = [];
    count = 0;

    constructor(private artworkService: ArtworkService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.queryParams
            .pipe(switchMap(params => this.artworkService.getSoldArtworks(params)))
            .subscribe(({results, count}) => {
                this.soldArtworks = results;
                this.count = count;
            });
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }
}
