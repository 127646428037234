import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared-module/services';
import { Title, Meta } from "@angular/platform-browser";

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit, AfterViewInit {

    constructor(private sharedService: SharedService,
                private title: Title,
                private meta: Meta) {
    }

    ngOnInit() {
        this.title.setTitle("Our Mission");
        this.meta.updateTag({ 
            name: 'description',
            content: 'We are a charity project aiming to exhibit, promote and sell Armenian art. A matching amount of what artists receive goes to charity in Armenia.'
        }); 
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }
}
