import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-background-section',
    templateUrl: './background-section.component.html',
    styleUrls: ['./background-section.component.scss']
})
export class BackgroundSectionComponent {
    @Input() bgImage: string;
    @Input() headingText: string;
    @Input() subheadingText: string;
    @Input() sectionHeight: string;
    @Input() showScrollDown: boolean;
    @Input() positionTop: string;
    @Input() positionBottom: string;
    format = 'd MMMM yyyy';
    currentPage = 0;

    constructor() {}

    scroll(): void {
        const element: HTMLElement = document.getElementById('content');
        element.scrollIntoView();
    }

    changePage(): void {
       this.currentPage += 1;
    }

    changePrevPage(): void {
        this.currentPage -= 1;
    }

}
