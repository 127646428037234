import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PictureService, UserService, ValidatorService} from '../../services';
import {IUser} from '../../interfaces';

@Component({
    selector: 'app-update-profile',
    templateUrl: './update-profile.component.html',
    styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {
    @Input() user: IUser;
    @Output() userChanged = new EventEmitter<void>();
    profilePicture: any = {};
    updateProfileForm: FormGroup;
    parentFormSubmitted = false;

    constructor(private userService: UserService,
                private fb: FormBuilder,
                private pictureService: PictureService,
                private validatorService: ValidatorService) {
        this.createFormGroup();
    }

    ngOnInit() {
        this.updateProfileForm.patchValue(this.user);
        if (this.user.pictureMedium) {
            this.profilePicture.medium = this.user.pictureMedium;
        }
    }

    updateProfile(): void {
        this.parentFormSubmitted = true;
        if (!this.updateProfileForm.valid) {
            return;
        }

        const values: IUser = this.updateProfileForm.getRawValue();

        if (this.user.roleName !== 'artist') {
            delete values.gender;
        }

        this.uploadProfilePicture()
            .then((picture) => {
                if (picture) {
                    values.pictureThumbnail = picture.thumbnail;
                    values.pictureSmall = picture.small;
                    values.pictureMedium = picture.medium;
                    values.pictureLarge = picture.large;
                }

                return this.saveProfile(values);
            })
            .then(() => {
                this.userChanged.emit();
                this.parentFormSubmitted = false;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private saveProfile(user: IUser): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.userService
                .updateProfile(user)
                .subscribe(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
        });
    }

    private uploadProfilePicture(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.profilePicture.file) {
                return this.pictureService
                    .uploadPicture([this.profilePicture.file])
                    .subscribe((res) => {
                        resolve(res[0]);
                    }, (err) => {
                        reject(err);
                    });
            }

            resolve({
                thumbnail: this.user.pictureThumbnail,
                small: this.user.pictureSmall,
                medium: this.user.pictureMedium,
                large: this.user.pictureLarge
            });
        });
    }

    inputPhone() {
        this.updateProfileForm.get('phone').setValue(this.updateProfileForm.get('phone').value.replace(/[^\d]/g, ''));
    }

    private createFormGroup(): void {
        this.updateProfileForm = this.fb.group({
            email: ['', [
                Validators.required,
                Validators.email
            ]],
            firstName: ['', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(40),
                this.validatorService.onlyAlpha
            ]],
            lastName: ['', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(40),
                this.validatorService.onlyAlpha
            ]],
            gender: [''],
            phone: ['', [
                Validators.required,
                this.validatorService.phone
            ]],
            address: ['', [
                Validators.required
            ]],
            city: ['', [
                Validators.required
            ]],
            country: ['', [
                Validators.required
            ]],
            state: ['', [
                Validators.required
            ]],
            weightUnit: ['', [
                Validators.required
            ]],
            dimensionUnit: ['', [
                Validators.required
            ]]
        });
    }
}
