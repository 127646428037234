import {Pipe, PipeTransform} from '@angular/core';
import messages from '../../../assets/messages';

@Pipe({
    name: 'text'
})
export class TextPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (args && messages[value]) {
            return messages[value].split('{args}').join(args);
        }
        return messages[value] || value;
    }
}
