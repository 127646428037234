import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IMedium} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class MediumService {

    constructor(private apiService: ApiService) {
    }

    getMediums(params: any): Observable<IApiReceiveResponse<IMedium>> {
        return this.apiService.get<IApiReceiveResponse<IMedium>>('mediums/receive/all', {params}, true);
    }
}
