import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IArtist} from '../interfaces';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {SharedService} from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class ArtistService {

    constructor(private apiService: ApiService,
                private sharedService: SharedService) {
    }

    searchArtists(params: any): Observable<IApiReceiveResponse<IArtist>> {
        return this.apiService.get<IApiReceiveResponse<IArtist>>('artists/search/receive/all', {params}, true).pipe(
            map((data) => {
                data.results = data.results.map((artist) => {
                    artist.specializations = artist.artistSpecializations.map(item => item.specialization);
                    delete artist.artistSpecializations;
                    return artist;
                });

                return data;
            })
        );
    }

    getSimilarArtists(params: any): Observable<IApiReceiveResponse<IArtist>> {
        return this.apiService.get<IApiReceiveResponse<IArtist>>('artists/similar/receive/all', {params}, true).pipe(
            map((data) => {
                data.results = data.results.map((artist) => {
                    artist.specializations = artist.artistSpecializations.map(item => item.specialization);
                    delete artist.artistSpecializations;
                    return artist;
                });

                return data;
            })
        );
    }

    getArtists(params?: any): Observable<IApiReceiveResponse<IArtist>> {
        return this.apiService.get<IApiReceiveResponse<IArtist>>('artists/receive/all', {params}, true);
    }

    getArtist(): Observable<IArtist> {
        if (!this.sharedService.getToken()) {
            return of({specializations: [], educations: [], exhibitions: []});
        }
        return this.apiService.get<any>(`artists/receive`, {}, true).pipe(
            map((artist) => {
                if (artist) {
                    artist.specializations = artist.artistSpecializations.map(item => item.specialization);
                    delete artist.artistSpecializations;
                } else {
                    artist = {specializations: [], educations: [], exhibitions: []};
                }
                return artist;
            })
        );
    }

    getArtistById(artistId: string): Observable<IArtist> {
        return this.apiService.get<IArtist>(`artists/receive/${artistId}`, {}, true)
            .pipe(
                map((artist) => {
                    artist.specializations = artist.artistSpecializations.map(item => item.specialization);
                    delete artist.artistSpecializations;
                    return artist;
                })
            );
    }

    getSpecializations(): Observable<any[]> {
        return this.apiService.get<any[]>('artists/specializations/receive/all', {}, true);
    }

    addArtistProfile(data: IArtist): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('artists/add/profile', data, {}, true, true);
    }

    updateArtistProfile(data: IArtist): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artists/update/profile`, data, {}, true, true);
    }
}
