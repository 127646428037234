import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
    @Input() count: number;
    pages: number[] = [];
    params: any;
    activeIndex: number;

    constructor(private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.params = {...params};
            this.params.offset = +this.params.offset;
            this.params.limit = +this.params.limit;
            this.pages = [];
            this.activeIndex = this.params.offset / this.params.limit;
            for (let i = 0; i < Math.ceil(this.count / this.params.limit); i++) {
                this.pages.push(i);
            }
        });
    }

    changePage(index: number): void {
        this.params.offset = index * this.params.limit;
        this.navigate();
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    private navigate(): void {
        this.router.navigate([window.location.pathname], {queryParams: this.params});
    }

}
