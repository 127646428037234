import {AfterViewInit, Component, OnInit} from '@angular/core';

import {IPartner} from '../../shared-module/interfaces';
import {PartnerService} from '../../shared-module/services/partner.service';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-our-partners',
    templateUrl: './our-partners.component.html',
    styleUrls: ['./our-partners.component.scss']
})
export class OurPartnersComponent implements OnInit, AfterViewInit {
    partners: IPartner[] = [];
    count = 0;

    constructor(private partnerService: PartnerService,
        private title: Title) {
    }

    ngOnInit(): void {
        this.title.setTitle('Our Partners');
        this.getPartners();
    }

    private getPartners(): void {
        this.partnerService
            .getPartners({})
            .subscribe(({results, count}) => {
                this.partners = results;
                this.count = count;
            });
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

}
