import { Observable } from 'rxjs';

export const fromMethodCall = (obj: object, methodName: string): Observable<any[]> => {
  const oldFn = obj[methodName];
  const isOwnProperty = obj.hasOwnProperty(methodName);

  return new Observable<any[]>((observer) => {
    obj[methodName] = (...args) => {
      observer.next(args);
      return oldFn.apply(obj, args);
    };

    return () => isOwnProperty ? obj[methodName] = oldFn : delete obj[methodName];
  });
};
