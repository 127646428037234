import {Component, OnInit} from '@angular/core';
import {DonationService} from '../../shared-module/services/donation.service';
import {IDonation} from '../../shared-module/interfaces';
import {ActivatedRoute} from '@angular/router';
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-donation-details',
    templateUrl: './donation-details.component.html',
    styleUrls: ['./donation-details.component.scss']
})
export class DonationDetailsComponent implements OnInit {
    donation: IDonation;

    constructor(private donationService: DonationService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.params.pipe(
            switchMap(params => this.donationService.getDonation(params.donationId))
        ).subscribe(donation => this.donation = donation);
    }
}
