import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IOffer} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class OfferService {

    constructor(private apiService: ApiService) {
    }

    getCustomerOffers(): Observable<IApiReceiveResponse<IOffer>> {
        return this.apiService.get<IApiReceiveResponse<IOffer>>('offers/customer/receive/all', {}, true);
    }

    sendOffer(artworkId: string, offer: IOffer): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`offers/send-customer-offer/${artworkId}`, offer, {}, true, true);
    }
}
