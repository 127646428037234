import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {IApiReceiveResponse, IBanner} from '../../interfaces';
import {BannerService} from '../../services';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-promo-codes-banner',
    templateUrl: './promo-codes-banner.component.html',
    styleUrls: ['./promo-codes-banner.component.scss']
})
export class PromoCodesBannerComponent implements OnInit, OnDestroy {
    @Output() initiated = new EventEmitter<{
        showSingleBannerPromoCode?: boolean,
        showMultipleBannerPromoCodes?: boolean
    }>();
    @Input() showOnlyFixedAtTopBanner: boolean;
    @ViewChild('notFixedBanner') notFixedBanner: ElementRef<HTMLDivElement>;
    topOffset: number;
    bannerPromoCodes: IBanner[];
    showSingleBannerPromoCode: boolean;
    bannerExpireDate: Date;
    private readonly ngUnsubscribe$ = new Subject();

    constructor(private readonly bannerService: BannerService) {
    }

    ngOnInit(): void {
        if (!this.showOnlyFixedAtTopBanner) {
            document.addEventListener('scroll', () => {
                this.topOffset = this.notFixedBanner?.nativeElement?.getBoundingClientRect()?.y;
            }, true);
        }
        this.bannerService.getBanners().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((data: IApiReceiveResponse<IBanner>) => {
            this.bannerPromoCodes = data && data.results;
            if (this.bannerPromoCodes && this.bannerPromoCodes.length) {
                this.showSingleBannerPromoCode = this.bannerPromoCodes.length === 1;
                this.bannerExpireDate = this.bannerPromoCodes.find(bannerPromoCode => bannerPromoCode.expireDate)?.expireDate;
                this.initiated.emit({
                    showSingleBannerPromoCode: this.showSingleBannerPromoCode,
                    showMultipleBannerPromoCodes: !this.showSingleBannerPromoCode
                });
            } else {
                this.initiated.emit({});
            }
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
