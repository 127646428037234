import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, ValidatorService} from '../../shared-module/services';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: FormGroup;
    email: string;
    resetPasswordCode: string;
    errMessage: string;
    step = 1;

    constructor(private fb: FormBuilder,
                private authService: AuthService,
                private route: ActivatedRoute,
                private validatorService: ValidatorService,
                private router: Router) {
        this.createFormGroup();
    }

    ngOnInit() {
        this.email = this.route.snapshot.params.email;
        this.resetPasswordCode = this.route.snapshot.params.resetPasswordCode;

        if (!this.email || !this.resetPasswordCode) {
            this.router.navigate(['/auth/login']).then();
        }
    }

    resetPassword(): void {
        if (!this.resetPasswordForm.valid) {
            return;
        }

        const data = {
            email: this.email,
            resetPasswordCode: this.resetPasswordCode,
            ...this.resetPasswordForm.getRawValue()
        };

        this.errMessage = '';

        this.authService
            .resetPassword(data)
            .subscribe(() => {
                this.step = 2;
            });
    }

    private createFormGroup(): void {
        this.resetPasswordForm = this.fb.group({
            password: ['', [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(24),
                this.validatorService.password
            ]],
            confirmPassword: ['', [
                Validators.required
            ]]
        }, {validator: this.validatorService.confirmPassword});
    }

}
