import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IArtwork} from '../interfaces';
import {ApiService} from './api.service';

const LOCAL_CART_ITEMS = 'LOCAL_CART_ITEMS';

@Injectable({
    providedIn: 'root'
})
export class CartItemService {

    execChange: Subject<any> = new Subject<any>();

    constructor(private apiService: ApiService) {
    }

    getLocalCartItems(): string[] {
        const localCartItems = localStorage.getItem(LOCAL_CART_ITEMS);
        return localCartItems ? JSON.parse(localCartItems) : [];
    }

    checkLocalCart(artworkId: string): boolean {
        const localCartItems: string = localStorage.getItem(LOCAL_CART_ITEMS);
        const localCartItemsArray: string[] = localCartItems ? JSON.parse(localCartItems) : [];

        if (!localCartItemsArray.length) {
            return false;
        }

        return Boolean(localCartItemsArray.indexOf(artworkId) > -1);
    }

    addLocalCartItem(artworkId: string): void {
        const localCartItems: string = localStorage.getItem(LOCAL_CART_ITEMS);
        const localCartItemsArray: string[] = localCartItems ? JSON.parse(localCartItems) : [];
        if (localCartItemsArray.length) {
            if (localCartItemsArray.indexOf(artworkId) < 0) {
                localCartItemsArray.push(artworkId);
            }
        } else {
            localCartItemsArray.push(artworkId);
        }
        localStorage.setItem(LOCAL_CART_ITEMS, JSON.stringify(localCartItemsArray));
    }

    deleteLocalCartItem(artworkId: string): void {
        const localCartItems: string = localStorage.getItem(LOCAL_CART_ITEMS);
        const localCartItemsArray: string[] = localCartItems ? JSON.parse(localCartItems) : [];
        localCartItemsArray.splice(localCartItemsArray.indexOf(artworkId), 1);
        localStorage.setItem(LOCAL_CART_ITEMS, JSON.stringify(localCartItemsArray));
    }

    saveLocalCartItems(): Promise<any> {
        const artworks = this.getLocalCartItems();

        if (artworks.length) {
            return Promise.all(artworks.map(artworkId => this.saveLocalCartItem(artworkId)));
        }

        return Promise.resolve();
    }

    private saveLocalCartItem(artworkId: string): Promise<void> {
        return new Promise((resolve) => {
            this.addCartItem(artworkId)
                .subscribe(() => {
                    this.deleteLocalCartItem(artworkId);
                    resolve();
                }, () => {
                    this.deleteLocalCartItem(artworkId);
                    resolve();
                });
        });
    }

    getCartItems(params: any = {}): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('cart-items/receive/all', {params}, true);
    }

    getCartItemsCount(data) {
        this.execChange.next(data);
    }

    updateCartItemsCount(artworkId){
        this.getCartItems(artworkId)
            .subscribe(({results, count, total}) => {
                this.getCartItemsCount(results.length);  
        });
    }

    updateLocalCartItem(artworkId){
        const localCartItems = localStorage.getItem(LOCAL_CART_ITEMS);
        const localCartItemsArray: string[] = localCartItems ? JSON.parse(localCartItems) : [];
        this.getCartItemsCount(localCartItemsArray.length);  
    }

    addCartItem(artworkId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`cart-items/add/${artworkId}`, {}, {}, true);
    }

    deleteCartItem(cartItemId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`cart-items/delete/${cartItemId}`, {}, true, true);
    }
}
