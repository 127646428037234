import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billings-history',
  templateUrl: './billings-history.component.html',
  styleUrls: ['./billings-history.component.scss']
})
export class BillingsHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
