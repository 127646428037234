import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ICategory} from '../interfaces';
import {CategoryService} from '../services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CategoriesResolverService implements Resolve<ICategory[]> {

    constructor(private categoryService: CategoryService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICategory[]> {
        return this.categoryService.getCategories({}).pipe(map(data => data.results));
    }
}
