import {Component, OnInit, HostListener, Inject} from '@angular/core';
import {trigger, state, transition, style, animate} from '@angular/animations';
import {DOCUMENT} from '@angular/common';
import { Title, Meta } from "@angular/platform-browser";
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [
        trigger('fade',
            [
                state('void', style({opacity: 0})),
                transition(':enter', [animate(300)]),
                transition(':leave', [animate(500)]),
            ]
        )]
})
export class HomeComponent implements OnInit {

    constructor(@Inject(DOCUMENT) document,
        private title: Title,
        private meta: Meta) {
    }

    ngOnInit() {
        this.title.setTitle('Armenian Artists Project');
        this.meta.updateTag({ 
            name: 'description',
            content: 'Armenian Artists Project charitable online art gallery is the best place to buy original Armenian art and at the same time support charity projects in Armenia.'
        });
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        if (window.pageYOffset > 550) {
            const element = document.getElementById('navbar');
            element.classList.add('sticky');
        } else {
            const element = document.getElementById('navbar');
            element.classList.remove('sticky');
        }
    }

}
