import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IDonation, IOrder} from '../../shared-module/interfaces';
import {OrderService} from '../../shared-module/services/order.service';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {GiftCardService} from '../../shared-module/services/gift-card.service';
import {combineLatest} from 'rxjs';

@Component({
    selector: 'app-order-confirmation',
    templateUrl: './order-confirmation.component.html',
    styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit, AfterViewInit {
    order: IOrder;
    giftCardBalance = 0;
    donation: IDonation;

    constructor(private orderService: OrderService,
                private giftCardService: GiftCardService,
                private route: ActivatedRoute) {
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    ngOnInit() {
        combineLatest(
            this.route.params,
            this.route.queryParams,
        ).pipe(
            map(([params, queryParams]) => ({...params, ...queryParams})),
            switchMap(params => this.orderService.getOrder(params.orderId, params.donationId && {donationId: params.donationId}))
        ).subscribe((order) => {
            this.order = order;
            this.donation = order.donation;
            this.getGiftCardBalance();
        });
    }

    private getGiftCardBalance(): void {
        this.giftCardService
            .getGiftCardBalance()
            .subscribe(({balance}) => {
                this.giftCardBalance = balance;
            });
    }
}
