import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {IArtwork, IColor} from '../../interfaces';
import {FormControl} from '@angular/forms';
import {trigger, state, style, transition, animate} from '@angular/animations';

@Component({
    selector: 'app-artwork-room',
    templateUrl: './artwork-room.component.html',
    styleUrls: ['./artwork-room.component.scss'],
    animations: [
        trigger('myanimation', [
            state('smaller', style({
                transform: 'translateY(100px)'
            })),
            state('larger', style({
                transform: 'translateY(0px)'
            })),
            transition('smaller <=> larger', animate('300ms ease-in'))
        ])
    ]
})
export class ArtworkRoomComponent implements OnInit, AfterViewInit {
    @Input() artwork: IArtwork;
    @Input() colors: IColor[];
    inputControl: FormControl;
    step = 1;
    selectedRoom: number;
    private imageSrc: any;
    color: FormControl;
    wallHeight = 274;
    roomBlockHeight = 470;

    @HostListener('window:resize')
    onResize() {
        setTimeout(() => {
            this.roomBlockHeight = document.querySelector('.room-block').clientHeight;
        }, 2000)

    }

    constructor() {
    }

    ngOnInit() {
        this.inputControl = new FormControl('');
        this.color = new FormControl(this.colors[0]);
        this.selectedRoom = 1;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.roomBlockHeight = document.querySelector('.room-block').clientHeight;
        }, 1000);
    }

    changeStep(step) {
        this.step = step;
    }

    fileChangeEvent(file) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            this.imageSrc = fileReader.result;
        };
        fileReader.readAsDataURL(file.target.files[0]);
    }

    changeRoom(room: number) {
        this.selectedRoom = room;
    }
}
