import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SharedService} from '../../../shared-module/services';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-buy-now-unauthorized-dialog',
    templateUrl: './buy-now-unauthorized-dialog.component.html',
    styleUrls: ['./buy-now-unauthorized-dialog.component.scss']
})
export class BuyNowUnauthorizedDialogComponent implements OnInit {
    artworkId: string;

    constructor(private dialogRef: MatDialogRef<BuyNowUnauthorizedDialogComponent>,
                private sharedService: SharedService,
                private router: Router,
                @Inject(MAT_DIALOG_DATA) private data: { artworkId: string }) {
    }

    ngOnInit(): void {
        this.artworkId = this.data.artworkId;
    }

    close() {
        this.dialogRef.close();
    }

    openRegisterPage() {
        this.sharedService.setRedirectUrl(`/artwork/${this.artworkId}`);
        this.router.navigate(['/auth/register']).then(() => this.close());
    }

    openLoginPage() {
        this.sharedService.setRedirectUrl(`/artwork/${this.artworkId}`);
        this.router.navigate(['/auth/login']).then(() => this.close());
    }
}
