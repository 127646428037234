import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IArtist, ICategory, IColor, IMaterial, IMedium, IStyle, ISubject, IUser} from '../../../shared-module/interfaces';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../../../shared-module/services';
import {MatDrawer} from '@angular/material/sidenav';

@Component({
    selector: 'app-artwork-list-filters',
    templateUrl: './artwork-list-filters.component.html',
    styleUrls: ['./artwork-list-filters.component.scss']
})
export class ArtworkListFiltersComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() drawer: MatDrawer;
    @Input() tabletMediaMatcher: MediaQueryList;
    @Input() sidenavActive: boolean;
    @Input() categories: ICategory[];
    @Input() styles: IStyle[];
    @Input() subjects: ISubject[];
    @Input() mediums: IMedium[];
    @Input() materials: IMaterial[];
    @Input() colors: IColor[];
    @Input() artists: IArtist[];
    @Input() user: IUser;
    selectedStyles: IStyle[] = [];
    selectedMediums: IMedium[] = [];
    selectedSubjects: ISubject[] = [];
    selectedMaterials: IMaterial[] = [];
    selectedColors: IColor[] = [];
    selectedArtists: IArtist[] = [];
    orientations: any[] = [];
    selectedOrientations: any[] = [];
    sizes: any[] = [];
    selectedSizes: any[] = [];
    categoryId: FormControl;
    sortBy: FormControl;
    widthFrom: FormControl;
    widthTo: FormControl;
    heightFrom: FormControl;
    heightTo: FormControl;
    depthFrom: FormControl;
    depthTo: FormControl;
    weightFrom: FormControl;
    weightTo: FormControl;
    priceRange: FormControl;
    priceFrom: FormControl;
    priceTo: FormControl;
    params: any = {};
    element: HTMLElement;
    listener: any;
    containerHeight: number;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private sharedService: SharedService) {
        this.createFormControls();
    }

    ngOnInit() {
        this.containerHeight = window.innerHeight;
        this.sizes = this.sharedService.getSizes();
        this.orientations = this.sharedService.getOrientations();
        this.route.queryParams
            .subscribe((params) => {
                this.params = {...params};
                if (params.styles) {
                    this.selectedStyles = this.styles.filter(style => params.styles.indexOf(style.styleId) > -1);
                } else {
                    this.selectedStyles = [];
                }

                if (params.subjects) {
                    this.selectedSubjects = this.subjects.filter(subject => params.subjects.indexOf(subject.subjectId) > -1);
                } else {
                    this.selectedSubjects = [];
                }

                if (params.mediums) {
                    this.selectedMediums = this.mediums.filter(medium => params.mediums.indexOf(medium.mediumId) > -1);
                } else {
                    this.selectedMediums = [];
                }

                if (params.materials) {
                    this.selectedMaterials = this.materials.filter(material => params.materials.indexOf(material.materialId) > -1);
                } else {
                    this.selectedMaterials = [];
                }


                if (params.colors) {
                    this.selectedColors = this.colors.filter(color => params.colors.indexOf(color.colorId) > -1);
                } else {
                    this.selectedColors = [];
                }

                if (params.artists) {
                    this.selectedArtists = this.artists.filter(artist => params.artists.indexOf(artist.artistId) > -1);
                } else {
                    this.selectedArtists = [];
                }

                if (params.orientations) {
                    this.selectedOrientations = this.orientations.filter(orientation => params.orientations.indexOf(orientation.key) > -1);
                } else {
                    this.selectedOrientations = [];
                }

                if (params.sizes) {
                    this.selectedSizes = this.sizes.filter(size => params.sizes.indexOf(size.key) > -1);
                } else {
                    this.selectedSizes = [];
                }

                if (params.categoryId) {
                    this.categoryId.setValue(params.categoryId);
                }

                if (params.sortBy) {
                    this.sortBy.setValue(params.sortBy);
                } else {
                    this.sortBy.setValue('');
                }

                if (params.widthFrom) {
                    this.widthFrom.setValue(params.widthFrom);
                } else {
                    this.widthFrom.setValue('');
                }

                if (params.widthTo) {
                    this.widthTo.setValue(params.widthTo);
                } else {
                    this.widthTo.setValue('');
                }

                if (params.heightFrom) {
                    this.heightFrom.setValue(params.heightFrom);
                } else {
                    this.heightFrom.setValue('');
                }

                if (params.heightTo) {
                    this.heightTo.setValue(params.heightTo);
                } else {
                    this.heightTo.setValue('');
                }

                if (params.depthFrom) {
                    this.depthFrom.setValue(params.depthFrom);
                } else {
                    this.depthFrom.setValue('');
                }

                if (params.depthTo) {
                    this.depthTo.setValue(params.depthTo);
                } else {
                    this.depthTo.setValue('');
                }

                if (params.weightFrom) {
                    this.weightFrom.setValue(params.weightFrom);
                } else {
                    this.weightFrom.setValue('');
                }

                if (params.weightTo) {
                    this.weightTo.setValue(params.weightTo);
                } else {
                    this.weightTo.setValue('');
                }

                if (params.priceFrom) {
                    this.priceFrom.setValue(params.priceFrom);
                } else {
                    this.priceFrom.setValue('');
                }

                if (params.priceTo) {
                    this.priceTo.setValue(params.priceTo);
                } else {
                    this.priceTo.setValue('');
                }

                this.priceRange.setValue(`${params.priceFrom || 0}${params.priceTo ? '-' : ''}${params.priceTo || ''}`);
            });
    }

    ngAfterViewInit(): void {
        this.element = document.querySelector('mat-drawer-content');
        this.listener = () => {
            const container = document.querySelector('mat-drawer-content');
            const filters = document.querySelector('.category-filter');
            filters.setAttribute('style', `margin-top: ${container.scrollTop > 440 ? container.scrollTop - 440 : 0}px; height: ${this.containerHeight}px`);
        };

        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    chipAutocompleteChanged(event: any[], type: string) {
        if (type === 'style') {
            this.selectedStyles = event;
            if (event.length) {
                this.params.styles = event.map(style => style.styleId).join(',');
            } else {
                delete this.params.styles;
            }
        }

        if (type === 'subject') {
            this.selectedSubjects = event;
            if (event.length) {
                this.params.subjects = event.map(subject => subject.subjectId).join(',');
            } else {
                delete this.params.subjects;
            }
        }

        if (type === 'medium') {
            this.selectedMediums = event;
            if (event.length) {
                this.params.mediums = event.map(medium => medium.mediumId).join(',');
            } else {
                delete this.params.mediums;
            }
        }

        if (type === 'material') {
            this.selectedMaterials = event;
            if (event.length) {
                this.params.materials = event.map(material => material.materialId).join(',');
            } else {
                delete this.params.materials;
            }
        }

        if (type === 'color') {
            this.selectedColors = event;
            if (event.length) {
                this.params.colors = event.map(color => color.colorId).join(',');
            } else {
                delete this.params.colors;
            }
        }

        if (type === 'artist') {
            this.selectedArtists = event;
            if (event.length) {
                this.params.artists = event.map(artist => artist.artistId).join(',');
            } else {
                delete this.params.artists;
            }
        }

        if (type === 'size') {
            this.selectedSizes = event;
            if (event.length) {
                this.params.sizes = event.map(size => size.key).join(',');
            } else {
                delete this.params.sizes;
            }
        }

        if (type === 'orientation') {
            this.selectedOrientations = event;
            if (event.length) {
                this.params.orientations = event.map(orientation => orientation.key).join(',');
            } else {
                delete this.params.orientations;
            }
        }
    }

    applyFilterOnNavigation(e: KeyboardEvent) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            this.applyFilters();
        }
    }

    filterChanged(key: string, navigate: boolean) {
        const value = this[key].value;
        if (value || value === 0) {
            this.params[key] = value;
        } else {
            delete this.params[key];
        }
    }

    applyFilters() {
        this.params.offset = 0;
        this.params.limit = 20;
        this.drawer.toggle();
        this.params.dimensionUnit = this.user && this.user.dimensionUnit ? this.user.dimensionUnit : 'inch'
        this.router.navigate(['/artwork-list'], {queryParams: this.params}).then();
    }

    priceSelected(priceFrom?: number, priceTo?: number) {
        if (priceFrom) {
            this.params.priceFrom = priceFrom;
            this.priceFrom.setValue(priceFrom);
        } else {
            delete this.params.priceFrom;
            this.priceFrom.setValue(null);
        }

        if (priceTo) {
            this.params.priceTo = priceTo;
            this.priceTo.setValue(priceTo);
        } else {
            delete this.params.priceTo;
            this.priceTo.setValue(null);
        }

        this.priceRange.setValue(`${priceFrom || 0}${priceTo ? '-' : ''}${priceTo || ''}`);
    }

    clearFilters() {
        this.params.offset = 0;
        this.params.limit = 20;
        this.router.navigate(['/artwork-list'], {
            queryParams: {
                categoryId: this.categoryId.value,
                offset: this.params.offset,
                limit: this.params.limit
            }
        }).then();
    }

    private createFormControls() {
        this.categoryId = new FormControl('');
        this.sortBy = new FormControl('');
        this.widthFrom = new FormControl('');
        this.widthTo = new FormControl('');
        this.heightFrom = new FormControl('');
        this.heightTo = new FormControl('');
        this.depthFrom = new FormControl('');
        this.depthTo = new FormControl('');
        this.weightFrom = new FormControl('');
        this.weightTo = new FormControl('');
        this.priceRange = new FormControl('');
        this.priceFrom = new FormControl('');
        this.priceTo = new FormControl('');
        this.categoryId = new FormControl('');
    }

    ngOnDestroy(): void {
        this.element.removeEventListener('scroll', this.listener);
    }
}
