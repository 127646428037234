import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared-module/services';
import { Title } from "@angular/platform-browser";
@Component({
    selector: 'app-disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit, AfterViewInit {

    constructor(private sharedService: SharedService,
        private title: Title) {
    }

    ngOnInit() {
        this.title.setTitle('Disclaimer')
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

}
