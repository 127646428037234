import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, ICustomer, IFavoriteArtist, IFavoriteList} from '../interfaces';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FavoriteService {

    constructor(private apiService: ApiService) {
    }

    getFavoriteArtists(): Observable<IApiReceiveResponse<IFavoriteArtist>> {
        return this.apiService.get<IApiReceiveResponse<IFavoriteArtist>>('favorites/artist/receive/all', {}, true);
    }

    addFavoriteArtist(artistId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`favorites/artist/add/${artistId}`, {}, {}, true, true);
    }

    deleteFavoriteArtist(artistId: string, customerId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`favorites/artist/delete/${artistId}/${customerId}`, {}, true, true);
    }

    getFavoriteLists(params: any): Observable<IApiReceiveResponse<IFavoriteList>> {
        return this.apiService.get<IApiReceiveResponse<IFavoriteList>>(`favorites/list/receive/all`, {params}, true)
            .pipe(map((data) => {
                data.results = data.results.map((favoriteList) => {
                    favoriteList = {
                        ...favoriteList,
                        ...favoriteList.customerFavoriteLists[0]
                    };

                    delete favoriteList.customerFavoriteLists;

                    favoriteList = {
                        ...favoriteList,
                        ...favoriteList.favoriteArtworks[0]
                    };

                    favoriteList.artworksCount = favoriteList.favoriteArtworks.length;

                    delete favoriteList.favoriteArtworks;

                    return favoriteList;
                });

                return data;
            }));
    }

    getFavoriteListsForArtwork(artworkId): Observable<IFavoriteList[]> {
        return this.apiService.get<IFavoriteList[]>(`favorites/artwork/list/receive/${artworkId}`, {}, true)
            .pipe(
                map((favoriteLists) => {
                    favoriteLists = favoriteLists.map((favoriteList) => {
                        favoriteList = {
                            ...favoriteList,
                            ...favoriteList.customerFavoriteLists[0]
                        };

                        delete favoriteList.customerFavoriteLists;

                        favoriteList = {
                            ...favoriteList,
                            ...favoriteList.favoriteArtworks[0]
                        };

                        delete favoriteList.favoriteArtworks;

                        return favoriteList;
                    });
                    return favoriteLists;
                })
            );
    }

    getFavoriteList(favoriteListId: string): Observable<IFavoriteList> {
        return this.apiService.get<IFavoriteList>(`favorites/list/receive/${favoriteListId}`, {}, true)
            .pipe(map((favoriteList) => {
                favoriteList = {
                    ...favoriteList,
                    ...favoriteList.customerFavoriteLists[0]
                };

                delete favoriteList.customerFavoriteLists;
                return favoriteList;
            }));
    }

    getFavoriteListAndArtworks(favoriteListId: string): Observable<IFavoriteList> {
        return this.apiService.get<IFavoriteList>(`favorites/list/artworks/receive/${favoriteListId}`, {}, true)
            .pipe(map((favoriteList) => {
                favoriteList = {
                    ...favoriteList,
                    ...favoriteList.customerFavoriteLists[0]
                };

                delete favoriteList.customerFavoriteLists;

                favoriteList.artworks = favoriteList.favoriteArtworks.map((favoriteArtwork) => {
                    favoriteArtwork = {
                        ...favoriteArtwork,
                        ...favoriteArtwork.artwork
                    };

                    delete favoriteArtwork.artwork;

                    return favoriteArtwork;
                });


                delete favoriteList.favoriteArtworks;
                return favoriteList;
            }));
    }

    addFavoriteList(data: IFavoriteList): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('favorites/list/add', data, {}, true, true);
    }

    updateFavoriteList(favoriteListId: string, data: IFavoriteList): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`favorites/list/update/${favoriteListId}`, data, {}, true, true);
    }

    deleteFavoriteList(favoriteListId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`favorites/list/delete/${favoriteListId}`,{}, true, true);
    }

    addFavoriteArtwork(favoriteListId: string, artworkId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('favorites/artwork/add', {favoriteListId, artworkId}, {}, true, true);
    }

    deleteFavoriteArtwork(favoriteArtworkId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`favorites/artwork/delete/${favoriteArtworkId}`, {}, true, true);
    }

    getFavoriteListInvites(favoriteListId: string): Observable<ICustomer[]> {
        return this.apiService.get<ICustomer[]>(`favorites/invites/receive/${favoriteListId}`, {}, true)
            .pipe(
                map((invites) => {
                    invites = invites.map((invite) => {
                        if (invite.customer) {
                            invite = {
                                ...invite,
                                ...invite.customer
                            };

                            delete invite.customer;
                        }

                        return invite;
                    });
                    return invites;
                })
            );
    }

    addFavoriteListInvite(favoriteListId: string, invite: ICustomer): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`favorites/invites/add/${favoriteListId}`, invite, {}, true, true);
    }

    updateFavoriteListInvite(customerFavoriteListId: string, data: any): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`favorites/invites/update/${customerFavoriteListId}`, data, {}, true, true);
    }

    deleteFavoriteListInvite(customerFavoriteListId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`favorites/invites/delete/${customerFavoriteListId}`, {}, true, true);
    }
}
