import {Component, OnInit} from '@angular/core';
import {FavoriteService} from '../../shared-module/services';
import {IFavoriteList} from '../../shared-module/interfaces';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-wish-list',
    templateUrl: './wish-list.component.html',
    styleUrls: ['./wish-list.component.scss']
})
export class WishListComponent implements OnInit {
    favoriteLists: IFavoriteList[] = [];
    count = 0;
    params: any = {};

    constructor(private favoriteService: FavoriteService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.params = {...params};
            this.getFavoriteLists();
        });
    }

    private getFavoriteLists(): void {
        this.favoriteService
            .getFavoriteLists(this.params)
            .subscribe(({results, count}) => {
                this.favoriteLists = results;
                this.count = count;
            });
    }

    deleteFavoriteList(favoriteListId: string): void {
        this.favoriteService
            .deleteFavoriteList(favoriteListId)
            .subscribe(() => this.getFavoriteLists());
    }

}
