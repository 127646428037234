import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IAddressBookItem, IGiftCard, IPaymentMethod} from '../../shared-module/interfaces';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AddressBookService, SharedService, ValidatorService} from '../../shared-module/services';
import {PaymentMethodService} from '../../shared-module/services/payment-method.service';
import {SelectAddressBookItemComponent} from '../../shared-module/dialogs/select-address-book-item/select-address-book-item.component';
import {SelectPaymentMethodComponent} from '../../shared-module/dialogs/select-payment-method/select-payment-method.component';
import faker from 'faker';
import {GiftCardService} from '../../shared-module/services/gift-card.service';
import {environment} from '../../../environments/environment';

// @ts-ignore
const stripe = window.Stripe(environment.stripePublicKey);

@Component({
    selector: 'app-gift-card-checkout',
    templateUrl: './gift-card-checkout.component.html',
    styleUrls: ['./gift-card-checkout.component.scss']
})
export class GiftCardCheckoutComponent implements OnInit, AfterViewInit {
    giftCard: IGiftCard;
    paymentMethods: IPaymentMethod[] = [];
    addresses: IAddressBookItem[] = [];
    paymentMethodForm: FormGroup;
    selectedPaymentMethod: IPaymentMethod;
    selectedBillingAddress: IAddressBookItem;
    addNewBillingAddress = false;
    paymentMethodType: FormControl;
    addNewPaymentMethod = false;
    cardElement: any;
    hasReview: boolean;
    testCards: string[] = [
        '4242424242424242',
        '4000056655665556',
        '5555555555554444',
        '2223003122003222',
        '5200828282828210',
        '5105105105105100',
        '378282246310005',
        '371449635398431',
        '6011111111111117',
        '6011000990139424',
    ];
    billingAddressControl = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        phone: new FormControl('', [Validators.required, this.validatorService.phone]),
        secondPhone: new FormControl('', this.validatorService.phone),
        address: new FormControl('', Validators.required),
        secondAddress: new FormControl(''),
        city: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required),
        postalCode: new FormControl('', Validators.required),
    });

    constructor(private router: Router,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private sharedService: SharedService,
                private route: ActivatedRoute,
                private validatorService: ValidatorService,
                private addressBookService: AddressBookService,
                private paymentMethodService: PaymentMethodService,
                private giftCardService: GiftCardService) {
        this.createFormGroup();
        this.paymentMethodType = new FormControl('credit_card', [Validators.required]);
    }

    ngOnInit() {
        this.getAddresses();
        this.getPaymentMethods();

        const giftCardId = this.route.snapshot.params.giftCardId;
        if (giftCardId) {
            const giftCard: IGiftCard = this.route.snapshot.data.giftCard;
            this.giftCard = {
                amount: giftCard.amount,
                deliveryMethod: giftCard.deliveryMethod,
                name: giftCard.name,
                picture: giftCard.picture,
                giftCardPictureId: giftCard.giftCardPictureId
            };

            if (giftCard.recipientEmail) {
                this.giftCard.recipientEmail = giftCard.recipientEmail;
                this.giftCard.senderName = giftCard.senderName;
                this.giftCard.recipientName = giftCard.recipientName;
                this.giftCard.message = giftCard.message;
            }
            return;
        }
        this.giftCard = this.sharedService.getGiftCard();
        if (!this.giftCard) {
            this.router.navigate(['/gift-card/buy']).then();
        }
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    initNewPaymentMethod() {
        const elements = stripe.elements();
        this.addNewPaymentMethod = true;
        this.cardElement = elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    backgroundColor: '#fff',
                    color: '#000',
                    fontFamily: 'Lato, san-serif',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textTransform: 'uppercase'
                }
            }
        });
        this.cardElement.mount('#card-element');
        this.cardElement.on('change', (event) => {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else if (!event.complete) {
                displayError.textContent = 'Card information is required';
            } else {
                displayError.textContent = '';
            }
        });
    }

    private getPaymentMethods(): void {
        this.paymentMethodService
            .getPaymentMethods()
            .subscribe(({results}) => {
                this.paymentMethods = results;
                if (this.paymentMethods.length) {
                    const defaultPaymentMethod = this.paymentMethods.find(item => item.defaultPaymentMethod);
                    if (defaultPaymentMethod) {
                        this.selectedPaymentMethod = defaultPaymentMethod;
                    }
                } else {
                    this.initNewPaymentMethod();
                }
            });
    }

    private getAddresses(): void {
        this.addressBookService
            .getAddresses()
            .subscribe(({results, count}) => {
                this.addresses = results;
                if (this.addresses.length) {
                    const defaultAddress = this.addresses.find(item => item.defaultAddress);
                    if (defaultAddress) {
                        this.selectedBillingAddress = defaultAddress;
                    } else {
                        this.addNewBillingAddress = true;
                    }
                } else {
                    this.addNewBillingAddress = true;
                }
            });
    }

    selectAddress() {
        this.dialog.open(SelectAddressBookItemComponent, {
            width: '718px',
            maxHeight: '448px',
            data: {type: 'billing'}
        }).afterClosed().subscribe((addressBookItem) => {
            if (addressBookItem) {
                this.addNewBillingAddress = false;
                this.selectedBillingAddress = addressBookItem;
            }
        });
    }

    selectPaymentMethod() {
        this.dialog.open(SelectPaymentMethodComponent, {
            width: '718px',
            maxHeight: '448px'
        }).afterClosed().subscribe((paymentMethod) => {
            if (paymentMethod) {
                this.selectedPaymentMethod = paymentMethod;
                this.addNewPaymentMethod = false;
            }
        });
    }

    async onSubmitStripe() {
        const {error} = await stripe.createToken(this.cardElement);

        if (error) {
            console.log('Something is wrong:');
            return;
        }
    }

    addGiftCardOrder() {
        if (this.addNewBillingAddress && this.billingAddressControl.invalid) {
            this.billingAddressControl.markAllAsTouched();
            this.onSubmitStripe().then();
            return;
        }
        if (this.paymentMethodType.value === 'paypal') {
            this.giftCard.paymentMethodType = this.paymentMethodType.value;

            this.saveGiftCardOrder()
                .then((giftCardId) => {
                    this.router.navigate(['/gift-card/review', giftCardId]);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (this.paymentMethodType.value === 'credit_card') {
            if (this.addNewPaymentMethod && (this.paymentMethodForm.invalid || !this.cardElement._complete)) {
                return;
            }

            if (!this.addNewPaymentMethod && !this.selectedPaymentMethod) {
                return;
            }

            this.giftCard.paymentMethodType = this.paymentMethodType.value;

            const paymentMethod: IPaymentMethod = {
                cardHolderName: this.paymentMethodForm.get('cardHolderName').value,
                defaultPaymentMethod: this.paymentMethodForm.get('defaultPaymentMethod').value,
                visiblePaymentMethod: this.paymentMethodForm.get('visiblePaymentMethod').value
            };
            if (!paymentMethod.visiblePaymentMethod) {
                paymentMethod.defaultPaymentMethod = false;
            }

            const billingAddress: IAddressBookItem = {
               ...this.billingAddressControl.value,
                defaultAddress: this.paymentMethodForm.get('defaultAddress').value,
                visibleAddress: this.paymentMethodForm.get('visibleAddress').value
            };

            const promises: any[] = [];

            if (this.addNewPaymentMethod) {
                if (this.addNewBillingAddress) {
                    promises.push(this.saveAddressBookItem(billingAddress));
                } else {
                    promises.push(Promise.resolve(this.selectedBillingAddress.addressBookItemId));
                }
            } else {
                promises.push(Promise.resolve(this.selectedPaymentMethod.addressBookItem.addressBookItemId));
            }
            this.hasReview = true;
            Promise.all(promises)
                .then(([billingAddressId]) => {
                    paymentMethod.addressBookItemId = billingAddressId;

                    if (this.addNewPaymentMethod) {
                        return this.addPaymentToken(paymentMethod, billingAddress);
                    } else {
                        return Promise.resolve(this.selectedPaymentMethod.paymentMethodId);
                    }
                })
                .then((paymentMethodId) => {
                    this.giftCard.paymentMethodId = paymentMethodId;
                    return this.saveGiftCardOrder();
                })
                .then((giftCardId) => {
                    this.router.navigate(['/gift-card/review', giftCardId]);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    private saveGiftCardOrder(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.giftCardService
                .addGiftCardOrder(this.giftCard)
                .subscribe(({id}) => {
                    this.sharedService.removeGiftCard();
                    resolve(id);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private addPaymentToken(paymentMethod: IPaymentMethod, billingAddress: IAddressBookItem): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.sharedService.emitLoaderChange(true);
            const addressBookItem = this.addresses.find(address => address.addressBookItemId === paymentMethod.addressBookItemId) || billingAddress;
            stripe.createToken(this.cardElement, {
                name: paymentMethod.cardHolderName,
                address_line1: addressBookItem.address,
                address_line2: addressBookItem.secondAddress,
                address_city: addressBookItem.city,
                address_state: addressBookItem.state,
                address_zip: addressBookItem.postalCode,
                address_country: addressBookItem.country
            }).then(({token}) => {
                this.sharedService.emitLoaderChange(false);
                paymentMethod.paymentToken = token.id;
                paymentMethod.cardId = token.card.id;
                paymentMethod.cardBrand = token.card.brand;
                paymentMethod.cardLast4 = token.card.last4;
                paymentMethod.cardExpireMonth = token.card.exp_month;
                paymentMethod.cardExpireYear = token.card.exp_year;
                paymentMethod.cardFunding = token.card.funding;
                this.savePaymentMethod(paymentMethod)
                    .then((id => resolve(id)))
                    .catch(err => reject(err));
            }).catch((err) => {
                this.sharedService.emitLoaderChange(false);
                reject(err);
            });
        });
    }

    private savePaymentMethod(paymentMethod: IPaymentMethod): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.paymentMethodService
                .addPaymentMethod(paymentMethod)
                .subscribe(({id}) => {
                    resolve(id);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private saveAddressBookItem(addressBookItem: IAddressBookItem): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.addressBookService
                .addAddressBookItem(addressBookItem)
                .subscribe(({id}) => {
                    resolve(id);
                }, (err) => {
                    reject(err);
                });
        });
    }

    private createFormGroup(): void {
        this.paymentMethodForm = this.fb.group({
            cardHolderName: ['', Validators.required],
            defaultPaymentMethod: [true],
            visiblePaymentMethod: [true],
            defaultAddress: [false],
            visibleAddress: [true]
        });

        if (!environment.production) {
            this.paymentMethodForm.patchValue({
                cardHolderName: `${faker.name.firstName().toUpperCase()} ${faker.name.lastName().toUpperCase()}`,
                firstName: faker.name.firstName(),
                lastName: faker.name.lastName(),
                phone: faker.phone.phoneNumber()
                    .replace(/-/g, '')
                    .replace(/ /g, '')
                    .replace(/\(/g, '')
                    .replace(/\)/g, '')
                    .replace(/x/g, ''),
                address: faker.address.streetAddress(),
                city: faker.address.city(),
                state: faker.address.state(),
                country: faker.address.country(),
                postalCode: faker.address.zipCode()
            });
        }
    }
}
