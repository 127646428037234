import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ArtistArtworkListRoutingModule} from './artist-artwork-list-routing.module';
import {ArtistArtworkListComponent} from './artist-artwork-list.component';
import {SharedModule} from '../shared-module/shared.module';

@NgModule({
    declarations: [ArtistArtworkListComponent],
    imports: [
        CommonModule,
        ArtistArtworkListRoutingModule,
        SharedModule
    ]
})
export class ArtistArtworkListModule {
}
