import {AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, NgControl, Validators} from '@angular/forms';

import {propagateControlTouched} from '../../../../../common/ng-control-helpers';
import {createValueAccessor, provideValueAccessor} from '../../../../../common/value-accessor-helpers';
import {ValidatorService} from '../../services';

@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss'],
  providers: [
    provideValueAccessor(BillingAddressComponent, 'valueAccessor')
  ]
})
export class BillingAddressComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() isRequired: boolean;
  @Input() isHidden: boolean;
  @Input() formGroup: FormGroup;

  constructor(
    injector: Injector,
    private validatorService: ValidatorService,
  ) {
    this.injector = injector;
  }

  readonly mainForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.required, this.validatorService.phone]),
    secondPhone: new FormControl('', this.validatorService.phone),
    address: new FormControl('', Validators.required),
    secondAddress: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
  });

  private readonly defaultFormValue = this.mainForm.value;

  private readonly injector: Injector;
  private ngControl: NgControl | null;

  valueAccessor = createValueAccessor(
    (fn) => this.mainForm.valueChanges.subscribe(fn),
    (value: any) => this.mainForm.patchValue(value ?? this.defaultFormValue),
    undefined,
    (isDisabled) => isDisabled ? this.mainForm.disable() : this.mainForm.enable()
  );

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
  }

  ngAfterViewInit() {
    const control = this.ngControl?.control;

    if (!control) {
        throw new Error('app-billing-address requires formControl on it');
    }

    propagateControlTouched(control, this.mainForm, true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.isHidden.currentValue) {
      return;
    }

    this.formGroup.reset(this.defaultFormValue);
    this.formGroup.updateValueAndValidity();
  }
}
