import {Component, OnInit} from '@angular/core';
import {IArtwork, ICompany} from '../../shared-module/interfaces';
import {ArtworkService, UserService} from '../../shared-module/services';
import {ActivatedRoute} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-sold-artworks',
    templateUrl: './sold-artworks.component.html',
    styleUrls: ['./sold-artworks.component.scss']
})
export class SoldArtworksComponent implements OnInit {
    artworks: IArtwork[] = [];
    count = 0;
    params: any = {};
    company: ICompany;

    constructor(private artworkService: ArtworkService,
                private userService: UserService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams.pipe(
            tap(params => this.params = params),
            switchMap(() => this.userService.getCompany()),
            tap(company => this.company = company),
            switchMap(() =>  this.artworkService.getArtistSoldArtworks(this.params))
        ).subscribe(({results, count}) => {
            this.artworks = results.map((artwork) => {
                artwork.insurancePrice = (artwork.soldPrice / 100) * this.company.insurancePercent;
                return artwork;
            });
            this.count = count;
        });
    }
}
