import {Component, Input, OnInit} from '@angular/core';
import {IArtwork} from '../../interfaces';
import {environment} from '../../../../environments/environment';
import {SharedService} from '../../services';

@Component({
    selector: 'app-social-share',
    templateUrl: './social-share.component.html',
    styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {
    @Input() artwork: IArtwork;
    socialIcons = false;

    get twitterUrl(): string {
        return `https://twitter.com/intent/tweet?url=${environment.apiUrl}/share/artwork/${this.artwork.artworkId}`;
    }

    get facebookUrl(): string {
        return `https://www.facebook.com/sharer/sharer.php?/share/artwork/${this.artwork.artworkId}`;
    }

    get linkedinUrl() {
        return `https://www.linkedin.com/sharing/share-offsite/?url=${environment.apiUrl}/share/artwork/${this.artwork.artworkId}`;
    }

    get pinterestUrl() {
        return ` http://pinterest.com/pin/create/button/?url=${environment.apiUrl}/share/artwork/${this.artwork.artworkId}`;
    }

    get mailUrl() {
        return `mailto:?subject=${this.artwork.name}&body=${this.artwork.name + ' ' + this.artwork.description + ' ' + window.location.href}`;
    }

    constructor(private sharedService: SharedService) {
    }

    ngOnInit() {
    }

    openFacebookShareDialog() {
        const dialog = window.open(this.facebookUrl, 'Facebook', 'height=450,width=550,resizable=1,scrollbars=yes');
        dialog.moveTo((window.innerWidth - 550) / 2, (window.innerHeight - 450) / 2);
    }

    openTwitterShareDialog() {
        const dialog = window.open(this.twitterUrl, 'Twitter', 'height=450,width=550,resizable=1,scrollbars=yes');
        dialog.moveTo((window.innerWidth - 550) / 2, (window.innerHeight - 450) / 2);
    }

    openLinkedinShareDialog() {
        const dialog = window.open(this.linkedinUrl, 'Linkedin', 'height=450,width=550,resizable=1,scrollbars=yes');
        dialog.moveTo((window.innerWidth - 550) / 2, (window.innerHeight - 450) / 2);
    }

    openPinterestShareDialog() {
        const dialog = window.open(this.pinterestUrl, 'Pinterest', 'height=450,width=550,resizable=1,scrollbars=yes');
        dialog.moveTo((window.innerWidth - 550) / 2, (window.innerHeight - 450) / 2);
    }

    copyUrl(): void {
        this.sharedService.copy(`${environment.apiUrl}/share/artwork/${this.artwork.artworkId}`);
    }

    showSocialIcons() {
        this.socialIcons = !this.socialIcons;
    }

}
