import {Component, OnInit} from '@angular/core';
import {IUser} from '../../shared-module/interfaces';
import {GiftCardService} from '../../shared-module/services/gift-card.service';
import {switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {RedeemGiftCardComponent} from '../../shared-module/dialogs/redeem-gift-card/redeem-gift-card.component';

@Component({
    selector: 'app-gift-cards',
    templateUrl: './gift-cards.component.html',
    styleUrls: ['./gift-cards.component.scss']
})
export class GiftCardsComponent implements OnInit {
    user: IUser;
    balance: number;
    count = 0;
    params: any = {};

    constructor(private giftCardService: GiftCardService,
                private dialog: MatDialog,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.user = this.route.snapshot.data.user;
        this.route.queryParams.pipe(
            tap(params => this.params = {...params}),
            switchMap(() => this.giftCardService.getGiftCardBalance())
        ).subscribe(({balance}) => {
            this.balance = balance;
        });
    }

    navigate() {
        this.params.updated = new Date().getTime();
        this.router.navigate([this.router.url.split('?')[0]], {queryParams: this.params}).then(() => {
        });
    }

    openRedeemGiftCardDialog() {
        this.dialog.open(RedeemGiftCardComponent, {
            width: '484px',
            maxHeight: '448px'
        }).afterClosed().subscribe((refresh) => {
            if (refresh) {
               this.navigate();
            }
        });
    }
}
