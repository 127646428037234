import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiService} from './api.service';

import {FounderModel, IApiReceiveResponse, ITeamMember} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class TeamMemberService {
    constructor(private apiService: ApiService) {
    }

    getTeamMembers(params: any): Observable<IApiReceiveResponse<ITeamMember>> {
        return this.apiService.get<IApiReceiveResponse<ITeamMember>>('team-members/receive/all', {params}, true);
    }

    getFounder(): Observable<FounderModel> {
        return this.apiService.get<FounderModel>('founder-info/receive', {}, true);
    }
}
