// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiUrl: 'https://api.armenianartistsproject.org',
    clientUrl: 'https://armenianartistsproject.org',
    socketUrl: '',
    stripePublicKey: 'pk_live_51H3HOwI49P2KtXf4kw4TakzY1MsBGZ9b8mcAHPZsZHyZLcqsi2PlAhEymRxSSMQJAKyYvSB62codQbXuzlPVno1r00E68cnkIZ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
