import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DonationMadeService} from '../../shared-module/services/donation-made.service';
import * as FileSaver from 'file-saver';
import { Title, Meta } from "@angular/platform-browser";
@Component({
    selector: 'app-donation-made',
    templateUrl: './donation-made.component.html',
    styleUrls: ['./donation-made.component.scss']
})
export class DonationMadeComponent implements OnInit, AfterViewInit {
    params: any = {};
    donationMades: any = [];
    count = 0;
    constructor(private router: Router,
        private route: ActivatedRoute,
        private donationMadeService: DonationMadeService,
        private title: Title,
        private meta: Meta) {
    };

    ngOnInit(): void {
        this.title.setTitle("Donations Made");
        this.meta.updateTag({ 
            name: 'description',
            content: 'A matching amount of what artists receive is allotted for charity in Armenia - to help rebuild schools, health centers, housing, water projects.'
        }); 
        this.route.queryParams
            .subscribe((params) => {
                this.params = {...params};
                this.getDonationMades();
        });
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    getDonationMades() {
        this.donationMades = [];
        this.donationMadeService
            .getDonationMades(this.params)
            .subscribe(({results, count}) => {
                this.donationMades = results;
                this.count = count;
        });    
    }

    generatePDF(){
        const pdfUrl = './assets/pdf/AAP_PDF.pdf';
        const pdfName = 'AAP_DONATION';
        FileSaver.saveAs(pdfUrl, pdfName);
    }

}
