import {Component, OnInit} from '@angular/core';
import {IUser} from '../shared-module/interfaces';
import {UserService, AuthService, SharedService} from '../shared-module/services';
import {Router} from '@angular/router';

@Component({
    selector: 'app-artist',
    templateUrl: './artist.component.html',
    styleUrls: ['./artist.component.scss']
})
export class ArtistComponent implements OnInit {
    user: IUser;
    userProfileMenu = [
        {name: 'Profile', url: 'profile-preview', params: {}},
        {name: 'Artwork', url: 'artwork-list', params: {offset: 0, limit: 20}},
        {name: 'Sold artwork', url: 'sold-artworks', params: {offset: 0, limit: 20}}
    ];
    public dropdownMenuState = false;

    constructor(private router: Router,
                private userService: UserService,
                private authService: AuthService,
                private sharedService: SharedService) {
    }

    ngOnInit() {
        if (this.router.isActive('cart', true)) {
            setTimeout(() => {
                this.router.navigate(['/cart/shopping-cart']).then();
            }, 0);
        }

        const token = this.sharedService.getToken();
        if (token) {
            this.getProfile();
        }
    }

    getProfile() {
        this.userService
            .getProfile()
            .subscribe((user) => {
                this.user = user;
            });
    }

    logout() {
        this.authService
            .logout()
            .subscribe(() => {
                this.user = null;
                this.sharedService.removeToken();
                this.router.navigate(['/home'])
                    .then(() => window.location.reload());
            });
    }

    openDropdown() {
        this.dropdownMenuState = !this.dropdownMenuState;
    }

}
