import {Component, OnInit} from '@angular/core';
import {FilteredLinkService} from '../shared-module/services/filtered-link-banner.service';
import {ActivatedRoute} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';
import {IArtwork} from '../shared-module/interfaces';

@Component({
    selector: 'app-filtered-link',
    templateUrl: './filtered-link.component.html',
    styleUrls: ['./filtered-link.component.scss']
})
export class FilteredLinkComponent implements OnInit {
    artworks: IArtwork[] = [];
    params: any = {};
    title: string = '';
    count = 0;

    constructor(private filteredLinkService: FilteredLinkService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        
        this.route.queryParams.subscribe((params) => {
            const filterParam = params['filterParam']; // Accessing filterParam from queryParams
        
            if (filterParam && filterParam.startsWith('under')) {
                // Extract the number after "under"
                const size = parseInt(filterParam.match(/\d+/)[0], 10);
                this.title = `Under $${size}`;
            } else {
                switch (filterParam) {
                    case 'largeWorks':
                        this.title = "Large Works";
                        break;
                    case 'womensArt':
                        this.title = "Women's Art";
                        break;
                    case 'oversizedArt':
                        this.title = "Oversized Art";
                        break;
                    case 'armenia':
                        this.title = "Armenia";
                        break;
                    case 'churches':
                        this.title = "Churches";
                        break;
                }
            }
            });

        this.route.queryParams.pipe(
            tap(params => this.params = params),
            switchMap(params => this.filteredLinkService.filterArtworks(params))
        ).subscribe(({results, count}) => {
            this.artworks = results;
            this.count = count;
        });
    }
}
