import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment';
import { StreamState } from '../interfaces/stream-state';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AudioService {
    private stop$ = new Subject();
    private audioObj = new Audio();
    audioEvents = [
        'ended', 'error', 'play', 'playing', 'nowPlaying', 'pause', 'timeupdate', 'canplay', 'loadedmetadata', 'loadstart'
    ];

    private currentFile: any = {};

    private state: StreamState = {
        playing: false,
        paused: false,
        nowPlaying: 0,
        readableCurrentTime: '',
        readableDuration: '',
        duration: undefined,
        currentTime: undefined,
        canplay: false,
        error: false,
    };

    files: any = [
        {
            id: 1,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885878/aap/Komitas_-_Song_1_nae33t.mp3',
            name: 'Yes Aghjik em',
            artist: 'Komitas', 
            duration: '03:27'
        },
        {
            id: 2,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885885/aap/Komitas_-_Song_4_dn67im.mp3',
            name: 'Gootany hats em beroom',
            artist: 'Komitas',
            duration: '06:13'
        },
        {
            id: 3,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885878/aap/Komitas_-_Song_2_jezi5g.mp3',
            name: 'Ervoom em',
            artist: 'Komitas',
            duration: '04:09'
        },
        {
            id: 4,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885883/aap/Komitas_-_Song_3_pvsxpv.mp3',
            name: 'Toon ari',
            artist: 'Komitas',
            duration: '04:12'
        },
        {
            id: 5,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885902/aap/Komitas_-_Song_7_izvpcf.mp3',
            name: 'Joor Kooga vereen saren',
            artist: 'Komitas',
            duration: '02:41'
        },
        {
            id: 6,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885888/aap/Komitas_-_Song_5_g9m1ra.mp3',
            name: 'Es Geesher Lousniak Geesher',
            artist: 'Komitas',
            duration: '04:33'
        },
        {
            id: 7,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885900/aap/Children_Number_12_r9wkwj.mp3',
            name: 'Melody',
            artist: 'Komitas',
            duration: '04:35'
        },
        {
            id: 8,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885900/aap/Dance_Number_4_amqgcv.mp3',
            name: 'Ounabee',
            artist: 'Komitas',
            duration: '04:22'
        },
        {
            id: 9,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885897/aap/Children_Number_5_rxdaf5.mp3',
            name: 'Yar jan u Marjan',
            artist: 'Komitas',
            duration: '02:53'
        },
        {
            id: 10,
            url: 'https://res.cloudinary.com/interlur/video/upload/v1600885901/aap/Hov_Arek_Sarer_Djan_tbnjad.mp3',
            name: 'Hov Arek Sarer Jan',
            artist: 'Komitas',
            duration: '06:22'
        },
    ];

    private stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.state);
    private fileChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.currentFile);

    private streamObservable(url) {
        return new Observable(observer => {
            // Play audio
            this.audioObj.src = url;
            this.audioObj.load();
            this.audioObj.play();
            this.audioObj.volume = 0.5;

            const handler = (event: Event) => {
                this.updateStateEvents(event);
                observer.next(event);
            };

            this.addEvents(this.audioObj, this.audioEvents, handler);
            return () => {
                // Stop Playing
                this.audioObj.pause();
                this.audioObj.currentTime = 0;
                // remove event listeners
                this.removeEvents(this.audioObj, this.audioEvents, handler);
                // reset state
                this.resetState();
                this.next()
            };
        });
    }

    private addEvents(obj, events, handler) {
        events.forEach(event => {
            obj.addEventListener(event, handler);
        });
    }

    private removeEvents(obj, events, handler) {
        events.forEach(event => {
            obj.removeEventListener(event, handler);
        });
    }

    playStream(fileId) {
        this.currentFile = this.files.find(x => x.id === fileId)
        return this.streamObservable(this.currentFile.url);
    }

    play() {
        this.audioObj.play();
    }

    pause() {
        this.audioObj.pause();
        this.state.paused = true;
    }

    stop() {
        this.audioObj.pause()
        this.state.paused = false;
        this.audioObj.currentTime = 0

    }

    next() {
        this.stop();
        let file = this.files.find(x => x.id === (this.currentFile.id + 1) );

        if (this.currentFile.id === this.files.length) {
            file = this.files.find(x => x.id === 1 );
        }

        this.playStream(file.id)
            .subscribe(events => {
            });
    }

    previous() {
        this.stop();
        const file = this.files.find(x => x.id === this.currentFile.id - 1 );
        this.playStream(file.id)
            .subscribe(events => {
                // listening for fun here
            });
    }

    seekTo(seconds) {
        this.audioObj.currentTime = seconds;
    }

    formatTime(time: number, format: string = 'HH:mm:ss') {
        const momentTime = time * 1000;
        return moment.utc(momentTime).format(format);
    }

    private updateStateEvents(event: Event): void {
        switch (event.type) {
            case 'canplay':
                this.state.duration = this.audioObj.duration;
                this.state.readableDuration = this.formatTime(this.state.duration);
                this.state.canplay = true;
                break;
            case 'playing':
                this.state.playing = true;
                break;
            case 'pause':
                this.state.playing = false;
                break;
            case 'timeupdate':
                this.state.currentTime = this.audioObj.currentTime;
                this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
                this.state.nowPlaying = this.currentFile.id;

                if (this.state.currentTime === this.state.duration) {
                    this.next();
                }

                if(this.state.playing === false) {
                    // this.next()
                }
                break;
            case 'error':
                this.resetState();
                this.state.error = true;
                break;
        }
        this.stateChange.next(this.state);
    }

    private resetState() {
        this.state = {
            playing: false,
            paused: false,
            nowPlaying: 0,
            readableCurrentTime: '',
            readableDuration: '',
            duration: undefined,
            currentTime: undefined,
            canplay: false,
            error: false,
        };
    }

    nowPlaying() {
        return this.fileChange.asObservable();
    }

    getState(): Observable<StreamState> {
        return this.stateChange.asObservable();
    }

    getFiles() {
        return of(this.files);
    }
}
