import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DonationService} from '../../shared-module/services/donation.service';
import {IDonation} from '../../shared-module/interfaces';
import { Title } from "@angular/platform-browser";
@Component({
    selector: 'app-donation-angels',
    templateUrl: './donation-angels.component.html',
    styleUrls: ['./donation-angels.component.scss']
})
export class DonationAngelsComponent implements OnInit, AfterViewInit {
    donations: IDonation[] = [];

    constructor(private donationService: DonationService,
        private title: Title) {
    }

    ngOnInit(): void {
        this.title.setTitle('Donor Angels')
        this.getDonationAngels();
    }

    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
    }

    private getDonationAngels(): void {
        this.donationService
            .getDonationAngels({})
            .subscribe(({results}) => this.donations = results);
    }

}
