import {AfterViewInit, Component, OnDestroy} from '@angular/core';

@Component({
    selector: 'app--gift-card',
    templateUrl: './gift-card.component.html',
    styleUrls: ['./gift-card.component.scss']
})
export class GiftCardComponent implements AfterViewInit, OnDestroy {
    element: HTMLElement;
    listener: any;

    constructor() {
    }


    ngAfterViewInit(): void {
        document.querySelector('mat-drawer-content').scrollTo(0, 0);
        this.element = document.querySelector('mat-drawer-content');
        this.listener = () => {
            const container: HTMLElement = document.querySelector('mat-drawer-content');
            const filters: HTMLElement = document.querySelector('#background-right');
            filters.style.top = `${container.scrollTop}px`;
        };
        this.element.addEventListener('scroll', this.listener);
    }

    ngOnDestroy(): void {
        this.element.removeEventListener('scroll', this.listener);
    }

}
